/* Authentication */
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_ERROR = 'AUTHENTICATE_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const DECODE_UUID = 'DECODE_UUID';
/* Dynamic App Navigation */
export const GET_APP_NAVIGATION_DATA = 'GET_APP_NAVIGATION_DATA';
/* External Apps */
export const GET_EXTERNAL_NAVIGATION_DATA = 'GET_EXTERNAL_NAVIGATION_DATA';
/* People */
export const PEOPLE_ADD_FIELD = 'PEOPLE_ADD_FIELD';
export const PEOPLE_SEARCH = 'PEOPLE_SEARCH';
export const PEOPLE_SEARCH_CLEAR = 'PEOPLE_SEARCH_CLEAR';
/* Person */
export const LOAD_PERSON = 'LOAD_PERSON';
export const LOAD_PERSON_THERMOMETER = 'LOAD_PERSON_THERMOMETER';
export const LOAD_PERSON_LEADER_THERMOMETER = 'LOAD_PERSON_LEADER_THERMOMETER';
export const LOAD_PERSON_SCORE_CYCLES = 'LOAD_PERSON_SCORE_CYCLES';
export const LOAD_PERSON_CYCLE_ORDERS = 'LOAD_PERSON_CYCLE_ORDERS';
export const CLEAR_PERSON_CYCLE_ORDERS = 'CLEAR_PERSON_CYCLE_ORDERS';
export const LOAD_PERSON_MONTHLY_PAYMENT = 'LOAD_PERSON_MONTHLY_PAYMENT';
/* Product Kit */
export const LOAD_LIST_PRODUCT_KIT = 'LOAD_LIST_PRODUCT_KIT';
export const LOAD_PRODUCT_KIT = 'LOAD_PRODUCT_KIT';
/* Leader Kit */
export const LOAD_KIT_MATERIAL_CYCLE = 'LOAD_KIT_MATERIAL_CYCLE';
export const LOAD_STATUS_MATERIAL_CYCLE = 'LOAD_STATUS_MATERIAL_CYCLE';
export const CHANGE_FLAG = 'CHANGE_FLAG';
export const LOAD_DETAILS_MATERIAL_CYCLE = 'LOAD_DETAILS_MATERIAL_CYCLE';
/* Growth Plan */
export const GROWTH_PLAN_CYCLE_RANGES = 'GROWTH_PLAN_CYCLE_RANGES';
export const GROWTH_PLAN_SELECT_CYCLE_RANGE = 'GROWTH_PLAN_SELECT_CYCLE_RANGE';
/*Elegibility*/
export const LOAD_ELEGIBILITY = 'LOAD_ELEGIBILITY';
/* Report */
export const SET_FIELD = 'SET_FIELD';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_TRACKING = 'GET_TRACKING';
export const GET_EXTRACT = 'GET_EXTRACT';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const RESET_FIELDS = 'RESET_FIELDS';
export const RESET_LISTS = 'RESET_LISTS';
/*MySucess*/
export const LOAD_MY_SUCESS = 'LOAD_MY_SUCESS';
/*MySucessDetail*/
export const LOAD_MY_SUCESS_DETAIL = 'LOAD_MY_SUCESS_DETAIL';
/*model*/
export const LOAD_MEDAL = 'LOAD_MEDAL';
/* Account */
export const GET_ACCOUNT = 'GET_ACCOUNT';
/* Consultant Digtal Store */
export const GET_DIGITAL_STORE_DETAILS = 'GET_DIGITAL_STORE_DETAILS';
/* Indicators */
export const RESET_INDICATORS = 'RESET_INDICATORS';
export const GET_INDICATORS = 'GET_INDICATORS';
export const GET_INDICATORS_DETAILS = 'GET_INDICATORS_DETAILS';
export const CLEAN_INDICATORS_DETAILS = 'CLEAN_INDICATORS_DETAILS';
/* Bonus Achievement */
export const GET_BONUS_ACHIEVEMENT = 'GET_BONUS_ACHIEVEMENT';
/* Remuneration */
export const SET_FIELD_REMUNERATION = 'SET_FIELD_REMUNERATION';
export const GET_REMUNERATION_PARAMETERS = 'GET_REMUNERATION_PARAMETERS';
export const POST_SIMULATION_CURRENT = 'POST_SIMULATION_CURRENT';
export const GET_SIMULATION_CURRENT = 'GET_SIMULATION_CURRENT';
export const POST_SIMULATION = 'POST_SIMULATION';
export const GET_SIMULATION = 'GET_SIMULATION';
export const CLEAN_SIMULATION_RESULT = 'CLEAN_SIMULATION_RESULT';
export const CLEAN_SIMULATION_VALUES = 'CLEAN_SIMULATION_VALUES';
