import React from 'react';
import {PropTypes} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

const CyclesScore = (props) => {
  const handleOnTouchTap = (person, cycle) => (event) => {
    props.onTouchTap(event, person, cycle);
  };

  return (
    <div>
      <table className="growth-plan-report-table-container-second">
        <thead>
        <tr className="growth-plan-report-table-no-border">
          <td>&nbsp;</td>
          {props.totalCycles.length > 0 && props.totalCycles.map(item => {
            return <td key={`star_${item}`}>
              <div>{props.getCyclesStar(item, props.person)}</div>
            </td>;
          })}
        </tr>
        <tr>
          <th>&nbsp;</th>
          {props.totalCycles.length > 0 && props.totalCycles.map(cycle =>
            <th key={cycle}>
              <Link onTouchTap={handleOnTouchTap(props.person, cycle)}>
                <FormattedMessage
                  id="geral.ciclo"
                  defaultMessage="Ciclo {cycle}"
                  description="Ciclo #"
                  values={{cycle: cycle.toString().substr(cycle.toString().length - 2)}}
                />
              </Link>
            </th>
          )}
        </tr>
        </thead>
        <tbody>
        <tr className="growth-plan-report-table-second-tr">
          <td className="growth-plan-report-table-second-td">
            <FormattedMessage
              id="consultora.canal.venda.direta"
              defaultMessage="Venda Direta"
              description="Venda Direta"
            />
          </td>
          {props.totalCycles.length > 0 && props.totalCycles.map(item => {
            let cycle = props.getCyclesScore('directSaleScore', item, props.person);
            return <td key={`direct_${item}`}>
              <div className="growth-plan-report-table-padding">
                {cycle ?
                  <FormattedMessage
                    id="consultora.canal.pontos"
                    defaultMessage="{points} pts."
                    description="{points} pts."
                    values={{
                      points: props.numberFormatter(cycle.vl_score)
                    }}
                  />
                  : ' - '}
              </div>
              <div>{cycle ? props.currencyFormatter(cycle.vl_value) : ' - '}</div>
            </td>;
          })}
        </tr>
        <tr className="growth-plan-report-table-border"><td><div /></td></tr>
        <tr className="growth-plan-report-table-second-tr">
          <td className="growth-plan-report-table-second-td">
            <FormattedMessage
              id="consultora.canal.rede.natura"
              defaultMessage="Rede Natura"
              description="Rede Natura"
            />
          </td>
          {props.totalCycles.length > 0 && props.totalCycles.map(item => {
            let cycle = props.getCyclesScore('networkNaturaScore', item, props.person);
            return <td key={`network_${item}`}>
              <div className="growth-plan-report-table-padding">
                {cycle ?
                  <FormattedMessage
                    id="consultora.canal.pontos"
                    defaultMessage="{points} pts."
                    description="{points} pts."
                    values={{
                      points: props.numberFormatter(cycle.vl_score)
                    }}
                  />
                  : ' - '}
              </div>
              <div>{cycle ? props.currencyFormatter(cycle.vl_value) : ' - '}</div>
            </td>;
          })}
        </tr>
        <tr className="growth-plan-report-table-border"><td><div/></td></tr>
        <tr className="growth-plan-report-table-second-tr">
          <td className="growth-plan-report-table-second-td">
            <FormattedMessage
              id="consultora.canal.bot.bov"
              defaultMessage="Bot/Bov"
              description="Bot/Bov"
            />
          </td>
          {props.totalCycles.length > 0 && props.totalCycles.map(item => {
            let cycle = props.getCyclesScore('botBovScore', item, props.person);
            return <td key={`direct_${item}`}>
              <div className="growth-plan-report-table-padding">
                {cycle ?
                  <FormattedMessage
                    id="consultora.canal.pontos"
                    defaultMessage="{points} pts."
                    description="{points} pts."
                    values={{
                      points: props.numberFormatter(cycle.vl_score)
                    }}
                  />
                  : ' - '}
              </div>
              <div>{cycle ? props.currencyFormatter(cycle.vl_value) : ' - '}</div>
            </td>;
          })}
        </tr>
        <tr className="growth-plan-report-table-border"><td><div/></td></tr>
        <tr className="growth-plan-report-table-second-tr">
          <td className="growth-plan-report-table-second-td">
            <FormattedMessage
              id="consultora.canal.distribuidor"
              defaultMessage="Distribuidor"
              description="Distribuidor"
            />
          </td>
          {props.totalCycles.length > 0 && props.totalCycles.map(item => {
            let cycle = props.getCyclesScore('distributorScore', item, props.person);
            return <td key={`direct_${item}`}>
              <div className="growth-plan-report-table-padding">
                {cycle ?
                  <FormattedMessage
                    id="consultora.canal.pontos"
                    defaultMessage="{points} pts."
                    description="{points} pts."
                    values={{
                      points: props.numberFormatter(cycle.vl_score)
                    }}
                  />
                  : ' - '}
              </div>
              <div>{cycle ? props.currencyFormatter(cycle.vl_value) : ' - '}</div>
            </td>;
          })}
        </tr>
        <tr className="growth-plan-report-table-border"><td><div/></td></tr>
        <tr className="growth-plan-report-table-second-tr">
          <td className="growth-plan-report-table-second-td">
            <FormattedMessage
              id="consultora.canal.total"
              defaultMessage="Total"
              description="Total"
            />
          </td>
          {props.totalCycles.length > 0 && props.totalCycles.map(item => {
            let cycle = props.getCyclesScore('totalScore', item, props.person);
            return <td key={`direct_${item}`}>
              <div className="growth-plan-report-table-padding">
                {cycle ?
                  <FormattedMessage
                    id="consultora.canal.pontos"
                    defaultMessage="{points} pts."
                    description="{points} pts."
                    values={{
                      points: props.numberFormatter(cycle.vl_score)
                    }}
                  />
                  : ' - '}
              </div>
              <div>{cycle ? props.currencyFormatter(cycle.vl_value) : ' - '}</div>
            </td>;
          })}
        </tr>
        </tbody>
      </table>
    </div>
  );
};

CyclesScore.propTypes = {
  person: PropTypes.object.isRequired,
  totalCycles: PropTypes.array.isRequired,
  getCyclesStar: PropTypes.func.isRequired,
  getCyclesScore: PropTypes.func.isRequired,
  numberFormatter: PropTypes.func.isRequired,
  currencyFormatter: PropTypes.func.isRequired,
  onTouchTap: PropTypes.func.isRequired
};

export default CyclesScore;
