import * as types from '../actions/actionTypes';
const initialState = {
  digitalStore: [],
};
export default function consultantReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.GET_DIGITAL_STORE_DETAILS}_FULFILLED`:
      return { ...state, digitalStore: payload };
    default:
      return state;
  }
}