import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Header from './common/Header';
import BackgroundLogin from './login/BackgroundLogin';
import Navigation from './common/Navigation';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { IntlProvider, addLocaleData } from 'react-intl';
import { ensureIntl } from '../ensureIntl';
import ReduxBlockUi from 'react-block-ui/redux';
import CircularProgress from 'material-ui/CircularProgress';
import 'react-block-ui/style.css';

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: '#7080a0',
    primary2Color: '#333333',
    primary3Color: '#bbbbbb',
    accent1Color: '#333333'
  }
});

const messages = require('../l10n/pt-BR.json');
const localeData = require('react-intl/locale-data/pt');

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'pt-BR',
      messages: messages,
      navDrawerOpen: false,
      navigationData: { menus: [{}] }
    };

    addLocaleData(localeData);
  }

  componentDidMount = () => {
    this.handleNavigation();
  };

  componentDidUpdate(prevProps){
    const { person: prevPerson} = prevProps;
    const { person } = this.props;

    if(!(Object.keys(person).length === 0) && person.commercial_structure && (JSON.stringify(person) !== JSON.stringify(prevPerson))){
      this.handleNavigation();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      this.setState({ navDrawerOpen: nextProps.width === '1600' });
    }
  }

  handleNavigation = () => {
    const { navigation, customNavigation } = this.props;

    let extractMenu = {};
    let reportMenu = {};

    //Filtering navigations depending on person roles
    const appNavigation = this.handleFilterNavigation(navigation);
    const externalNavigation = this.handleFilterNavigation(customNavigation);

    // Setting up menus
    if (appNavigation && appNavigation.length > 0)
      extractMenu = { header: "Extratos", submenus: appNavigation};

    if (externalNavigation && externalNavigation.length > 0)
      reportMenu = { header: "Relatórios", submenus: externalNavigation};

    const menuData = { menus: [extractMenu, reportMenu] };
    this.setState({ navigationData: menuData });
  }

  handleFilterNavigation = (navigation) => {
    const { person } = this.props;

    const personLevel = (person.commercial_structure && person.commercial_structure.no_group === "") ? "GN" : "LIDER";

    const filteredNavigation = navigation.filter((item) => {return item.roles.find(role => role === personLevel)});

    return filteredNavigation;
  }

  handleLangChange = event => {
    const lang = event.target.value;
    ensureIntl(lang).then(({ messages, localeData }) => {
      addLocaleData(localeData);
      this.setState({ lang, messages });
    });
  };

  handleChangeRequestNavDrawer = () => {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    });
  };

  render() {
    const { lang, messages, navDrawerOpen, navigationData } = this.state;
    let { usuario, person, location } = this.props;
    const checkHomePage = (location.pathname === '/home') ? true : false;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <IntlProvider locale={lang} messages={messages}>
          <ReduxBlockUi
            tag="div"
            block={[/_PENDING/]}
            unblock={[/_FULFILLED/, /_REJECTED/]}
            loader={<CircularProgress thickness={4} size={80} />}
          >
            {usuario.isAuthenticated ? (
              <div>
                <Header
                  usuario={usuario}
                  handleChangeRequestNavDrawer={
                    this.handleChangeRequestNavDrawer
                  }
                  displayMenuIconButton={!(checkHomePage)}
                />
                <Navigation
                  navData={navigationData}
                  navDrawerOpen={navDrawerOpen}
                  user={usuario}
                  person={person}
                  onRequestChange={this.handleChangeRequestNavDrawer}
                />
                <div className="all-container">{this.props.children}</div>
              </div>
            ) : (
              <div>
                <BackgroundLogin />
                {this.props.children}
              </div>
            )}
          </ReduxBlockUi>
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  navigation: PropTypes.array,
  customNavigation: PropTypes.array,
  location: PropTypes.object,
  width: PropTypes.number
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    navigation: state.dynamicNavigation.navigation,
    customNavigation: state.externalApps.customNavigation,
    person: state.person,
  };
}

export default connect(mapStateToProps)(App);
