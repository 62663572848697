import request from 'superagent';
import { indicatorsApi as indicatorApi } from '../utils/indicatorsApi';
import { indicators as indicatorEnum } from '../utils/indicators';
import { countries as country } from '../utils/countries';
import { structureLevels as structureLevel } from '../utils/structureLevels';

const SERVICE_PERFORMANCE_API = process.env.REACT_APP_SERVICE_PERFORMANCE_API;
const TOKEN_AWS = process.env.REACT_APP_TOKEN_AWS;
const TOKEN_AWS_AUTH = process.env.REACT_APP_TOKEN_AWS_AUTH;
const API_KEY = process.env.REACT_APP_API_KEY;

class indicatorsApi {
  static getToken = () => {
    return new Promise((resolve, reject) => {
      request
        .post(TOKEN_AWS)
        .set('Authorization', TOKEN_AWS_AUTH)
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .send({ grant_type: 'client_credentials' })
        .then(res => {
          if (res.status >= 200 && res.status <= 207) {
            resolve(res.body);
          }
        })
        .catch(err => {
          reject(Object.assign({}, err.response));
        });
    });
  };

  static getIndicators = (indicators, person, token) => {
    const personStructure = (person.commercial_structure.nm_group) ? person.commercial_structure.nm_group : person.commercial_structure.nm_sector;
    const personStructureLevel = (person.commercial_structure.nm_group) ? structureLevel.GROUP : structureLevel.SECTOR;
    return new Promise((resolve) => {
      request
        .get(`${SERVICE_PERFORMANCE_API}/external/achievement/index`)
        .set('Accept', 'application/json')
        .set('Authorization', token)
        .set('business-model-id', person.id_commercial_model)
        .set('company', 1)
        .set('country', country[person.id_country])
        .set('cycle', indicators.cycle)
        .set('structure-code', personStructure)
        .set('structure-level-id', personStructureLevel)
        .set('x-api-key', API_KEY)
        .then(res => {
          if (res.status >= 200 && res.status <= 207) {
            resolve(res.body);
          }
        })
        .catch(() => {
          const emptyIndex = Object.values(indicatorEnum).map((indicator) => {
            return {
              indexCode: indicator,
              indexValue: null,
              goal: null,
              achieved: null
            };
          });
          resolve(emptyIndex);
        });
    });
  };

  static getIndicatorsDetails = (indicators, person, type, token) => {
    const personStructure = (person.commercial_structure.nm_group) ? person.commercial_structure.nm_group : person.commercial_structure.nm_sector;
    const personStructureLevel = (person.commercial_structure.nm_group) ? structureLevel.GROUP : structureLevel.SECTOR;
    return new Promise((resolve, reject) => {
      request
        .get(`${SERVICE_PERFORMANCE_API}/external/${indicatorApi[type]}`)
        .set('Accept', 'application/json')
        .set('Authorization', token)
        .set('business-model-id', person.id_commercial_model)
        .set('company', 1)
        .set('country', country[person.id_country])
        .set('cycle', indicators.cycle)
        .set('structure-code', personStructure)
        .set('structure-level-id', personStructureLevel)
        .set('x-api-key', API_KEY)
        .then((res) => {
          if (res.status >= 200 && res.status <= 207) {
            resolve(res.body);
          }
        })
        .catch(err => {
          reject(Object.assign({}, err.response));
        });
    });
  };
}

export default indicatorsApi;
