import React from "react";
import { PropTypes } from "prop-types";
import Drawer from "material-ui/Drawer";
import { spacing, typography } from "material-ui/styles";
import { blue600 } from "material-ui/styles/colors";
import MenuItem from "material-ui/MenuItem";
import { Link } from "react-router";

const Navigation = props => {
  let { navDrawerOpen, user, person } = props;

  const styles = {
    logo: {
      cursor: "pointer",
      fontSize: 22,
      color: typography.textFullWhite,
      lineHeight: `${spacing.desktopKeylineIncrement}px`,
      fontWeight: typography.fontWeightLight,
      backgroundColor: blue600,
      paddingLeft: 40,
      height: 95
    },
    menuItem: {
      fontSize: 14
    }
  };

  return (
    <Drawer
      docked={false}
      open={navDrawerOpen}
      onRequestChange={props.onRequestChange}
      width={500}
    >
      <div className="navigation-header">
        <img src={require("../../img/logo-horizontal.png")} alt="Natura Logo" />
      </div>
      <div>
        {props.navData.menus.map((menu, index) => (
          <div key={index}>
            {menu.header && <h3 className="navigation-title">{menu.header}</h3>}
            {menu.submenus &&
              menu.submenus.length > 0 &&
              menu.submenus.map((submenu, subindex) => (
                <MenuItem
                  key={subindex}
                  style={styles.menuItem}
                  primaryText={submenu.label}
                  leftIcon={submenu.icon || null}
                  containerElement={
                    submenu.target === "_blank" ? (
                      <Link
                        to={`${submenu.link}?access_token=${user.accessToken}&personId=${user.userCode}`}
                        target={submenu.target}
                      />
                    ) : submenu.type === "external" ? (
                      <Link to={`${submenu.link}`} />
                    ) : (
                      <Link
                        to={`${submenu.link}/${person.id_consult}`}
                      />
                    )
                  }
                  className="natigation-header-links"
                  onTouchTap={props.onRequestChange}
                />
              ))}
          </div>
        ))}
      </div>
    </Drawer>
  );
};

Navigation.propTypes = {
  navDrawerOpen: PropTypes.bool.isRequired,
  navData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  onRequestChange: PropTypes.func.isRequired
};

export default Navigation;
