import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { indicators as indicator } from '../../../utils/indicators';
import _ from 'lodash';
import TableBalance from './Tables/IndicatorsTableDetails/TableBalance';
import TableActivitySB from './Tables/IndicatorsTableDetails/TableActivitySB';
import TableTotalPoints from './Tables/IndicatorsTableDetails/TableTotalPoints';
import TableActivity from './Tables/IndicatorsTableDetails/TableActivity';
import TableRegistration from './Tables/IndicatorsTableDetails/TableRegistration';
import TableActive from './Tables/IndicatorsTableDetails/TableActive';
import TableChannel from './Tables/IndicatorsTableDetails/TableChannel';
import TableActiveSilverPlus from './Tables/IndicatorsTableDetails/TableActiveSilverPlus';

class IndicatorsPanelDetails extends React.Component {
  createCustomToolBar = props => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ width: '50%', marginLeft: '15px', height: '100%' }}>
          {props.components.searchPanel}
        </div>
        <div style={{ marginRight: '15px', height: '100%' }}>
          {props.components.exportCSVBtn}
        </div>
      </div>
    );
  };

  selectTable(type, data) {
    const options = {
      toolBar: this.createCustomToolBar,
      exportCSVText: 'Exportar para Excel',
      noAutoBOM: false,
      noDataText: (
        <FormattedMessage
          id="table.empty"
          defaultMessage="Não há registros encontrados"
          description="Não há registros encontrados"
        />
      )
    };

    switch (type) {
      case 'Ativas Prata Mais':
        return <TableActiveSilverPlus indicatorsDetails={data} type={type} options={options} />;
      case 'Saldo':
        return <TableBalance indicatorsDetails={data} type={type} options={options} />;
      case 'Atividade Semente + Bronze':
        return <TableActivitySB indicatorsDetails={data} type={type} options={options} />;
      case 'Volume de pontos':
        return <TableTotalPoints indicatorsDetails={this.flattenIndexes(data)} type={type} options={options} />;
      case 'Atividade':
        return <TableActivity indicatorsDetails={this.flattenActivity(data)} type={type} options={options} />;
      case 'Cadastro':
        return <TableRegistration indicatorsDetails={this.flattenActivity(data)} type={type} options={options} />;
      case 'Ativas Total':
        return <TableActive indicatorsDetails={data} type={type} options={options} />;
      case 'Penetração de Canal':
        return <TableChannel indicatorsDetails={this.flattenIndexes(data)} type={type} options={options} />;
      default:
        return '';
    }
  }

  checkEmptyData = data => {
    return (_.isNil(data)) ? "-" : data;
  }

  flattenIndexes(data) {
    const flattenData = [];
    data.forEach(row => {
      let consultant = { ...row };
      row.indexes.forEach(index => { // eslint-disable-next-line
        switch (index.indexCode) {
          case indicator.INATIVIDADE:
            consultant.situation = this.checkEmptyData(index.indexValue);
            break;
          case indicator.VOLUME_DE_PONTOS:
            consultant.cyclePoints = this.checkEmptyData(index.indexValue);
            break;
          case indicator.VOLUME_DIGITAL:
            consultant.networkPoints = this.checkEmptyData(index.indexValue);
            break;
          case indicator.TOTAL_DE_PONTOS:
            consultant.totalPoints = this.checkEmptyData(index.indexValue);
            break;
          case indicator.FATURAMENTO_REDE:
            consultant.networkValue = this.checkEmptyData(index.indexValue);
            break;
          case indicator.ESPACO_DIGITAL_ABERTO:
            consultant.digitalSpaceStatus = (index.indexValue === 1) ? index.indexValue : 0;
            break;
        }
      });
      flattenData.push(consultant);
    });
    return flattenData;
  }

  flattenActivity(data) {
    const flattenData = [];
    data.forEach(row => {
      let consultant = { ...row };
      row.indexes.forEach(index => { if (index.indexCode === 2) consultant.situation = index.indexValue });
      flattenData.push(consultant);
    });
    return flattenData;
  }

  render() {
    const { type, indicatorsDetails } = this.props;
    return <div>{this.selectTable(type, indicatorsDetails)}</div>;
  }
}

IndicatorsPanelDetails.propTypes = {
  indicatorsDetails: PropTypes.array,
  type: PropTypes.string
};

export default IndicatorsPanelDetails;
