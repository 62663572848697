import firebaseApp from 'firebase/app';
import 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_WEBFV_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_WEBFV_FIREBASE_DOMAIN}.firebaseapp.com'`,
  databaseURL: `https://${process.env.REACT_APP_WEBFV_FIREBASE_DOMAIN}.firebaseio.com'`,
  projectId: `${process.env.REACT_APP_WEBFV_FIREBASE_DOMAIN}`,
  storageBucket: `${process.env.REACT_APP_WEBFV_FIREBASE_DOMAIN}.appspot.com'`,
  messagingSenderId: process.env.REACT_APP_WEBFV_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_WEBFV_FIREBASE_APP_ID
};

export class WebFvFirebaseService {
  constructor(dependencies) {
    if (!WebFvFirebaseService.instance) {
      this.initService(dependencies);

      WebFvFirebaseService.instance = this;
    }
    return WebFvFirebaseService.instance;
  }

  initService(
    dependencies = { firebase: firebaseApp, config: firebaseConfig }
  ) {
    const { config, firebase } = dependencies;
    let webFvFirebase = null;
    if(!firebase.app.length){
      webFvFirebase = firebase.initializeApp(config);
    }
    else{
      webFvFirebase = firebase.initializeApp(config, "webfv");
    }
    // Initialize, fetch and activate Remote Config data
    this.remoteConfig = webFvFirebase.remoteConfig();
  }

  async getAllRemoteConfigData() {
    const activate = await this.remoteConfig.fetchAndActivate();
    const data = await this.remoteConfig.getAll(activate);
    const dataString = Object.values(data).map(value =>
      JSON.parse(value._value)
    );
    return dataString;
  }
}

export default new WebFvFirebaseService();
