import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import ReportPageBase from '../common/ReportPageBase';
import MySucess from './widgets/MySucess';
import * as mySucessActions from '../../actions/mySucessActions';
import * as growthPlanActions from '../../actions/growthPlanActions';
import * as personActions from '../../actions/personActions';
import * as consultantActions from '../../actions/consultantActions';


class MySucessReport extends React.Component {
  constructor(props) {
    super(props);
      if (!this.props.params.code || !parseInt(this.props.params.code))
      this.props.router.push('/');
  }

  componentDidMount() {
    let {usuario} = this.props;
    this.props.personActions.getPerson(this.props.params.code, usuario)
      .then(_ => {
        // let result = _.value.dc_roles;
          this.props.personActions.getPerson(this.props.params.code, usuario);
          this.props.mySucessActions.getMySucess(this.props.params.code, usuario);
          this.props.consultantActions.getDigitalStoreDetails(usuario, _.value);
        });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  render() {
    let { person, sucess, consultant = {}, intl } = this.props;
    const digitalStore = consultant.digitalStore || {}
    return (
      <div>
        <ReportPageBase
          person={person}
          onNewService={this.handleNewService}
          commercialStatus={digitalStore.commercialStatus}
          intl={intl}
        >
                   <div>
              <MySucess
                sucess={sucess}
                {...this.props}
              />
            </div>

        </ReportPageBase>
      </div>
    );
  }
}

MySucessReport.propTypes = {
  intl: intlShape,
  sucess: PropTypes.object.isRequired,
  mySucessActions: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  growthPlan: PropTypes.object.isRequired,
  growthPlanActions: PropTypes.object.isRequired,
  consultantActions: PropTypes.object.isRequired,
  params: PropTypes.object,
  consultant: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    growthPlan: state.growthPlan,
    person: state.person,
    sucess: state.sucess,
    consultant: state.consultant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    growthPlanActions: bindActionCreators(growthPlanActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    mySucessActions: bindActionCreators(mySucessActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MySucessReport));
