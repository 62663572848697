import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { statusIndicator } from '../../../../../utils/statusIndicator';

class TableActivity extends React.Component {
  statusFormatter = cell => {
    return statusIndicator[cell];
  };

  render() {
    const { type, indicatorsDetails, options } = this.props;

    return (
      <div style={{ marginTop: '40px' }}>
        {indicatorsDetails && indicatorsDetails.length > 0 && (
          <BootstrapTable
            tableContainerClass="indicators-table-container-class"
            headerContainerClass="indicators-table-header-container-class"
            bodyContainerClass="indicators-table-body-container-class"
            data={indicatorsDetails}
            pagination
            striped
            hover
            search
            exportCSV
            csvFileName={`Relatório de ${type}.csv`}
            options={options}
            searchPlaceholder="Buscar por código ou nome"
          >
            <TableHeaderColumn
              dataField="cnCode"
              width="10%"
              dataSort={true}
              csvHeader={'Código da Consultora'}
              isKey
            >
              <FormattedMessage
                id="indicators.panel.details.cn.code"
                defaultMessage="Código da Consultora"
                description="Código da Consultora"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="cnName"
              width="30%"
              dataSort={true}
              csvHeader={'Nome da Consultora'}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.name"
                defaultMessage="Nome da Consultora"
                description="Nome da Consultora"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              width="15%"
              dataField="situation"
              csvHeader={'Status da Consultora'}
              csvFormat={this.statusFormatter}
              dataFormat={this.statusFormatter}
              formatExtraData={statusIndicator}
              filter={{
                type: 'SelectFilter',
                options: statusIndicator,
                placeholder: 'Todos'
              }}
              dataSort={true}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.status"
                defaultMessage="Status da Consultora"
                description="Status da Consultora"
              />
            </TableHeaderColumn>
          </BootstrapTable>
        )}
      </div>
    );
  }
}

TableActivity.propTypes = {
  indicatorsDetails: PropTypes.array,
  type: PropTypes.string,
  options: PropTypes.object,
};

export default TableActivity;
