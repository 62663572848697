import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import Divider from 'material-ui/Divider';
import moment from 'moment';

const AcountDetails = (props) => {
  let { account } = props;

  const formatBoolean = (value) => {
    if (value === true) {
      return (
        <FormattedMessage
          id="accountDetails.campo_formatado_sim"
          defaultMessage="S"
          description="S" />
      )
    } else if (value === false) {
      return (
        <FormattedMessage
          id="accountDetails.campo_formatado_nao"
          defaultMessage="N"
          description="N" />
      )
    } else {
      return '-';
    }
  }

  const formatMachineStatus = (status) => {
    const { intl } = props;
    if (status === 1 || status === 2)
      return intl.formatMessage({ id: 'status.maquininha.1' });
    else if (status === 3)
      return intl.formatMessage({ id: 'status.maquininha.2' });
    else if (status === 5)
      return intl.formatMessage({ id: 'status.maquininha.3' });
    else if (status === 4 || status === 7 || status === 8 || status === 9)
      return intl.formatMessage({ id: 'status.maquininha.4' });
    else if (status === 6)
      return intl.formatMessage({ id: 'status.maquininha.5' });
    else if (status === 10)
      return intl.formatMessage({ id: 'status.maquininha.6' });
  };

  const formatAccountStatus = (status) => {
    const { intl } = props;
    if (status === 1)
      return intl.formatMessage({ id: 'status.conta.1' });
    else if (status === 2)
      return intl.formatMessage({ id: 'status.conta.2' });
    else if (status === 3)
      return intl.formatMessage({ id: 'status.conta.3' });
    else if (status === 4)
      return intl.formatMessage({ id: 'status.conta.4' });
    else if (status === 5)
      return intl.formatMessage({ id: 'status.conta.5' });
    else if (status === 6)
      return intl.formatMessage({ id: 'status.conta.6' });
  };

  const formatDate = (date) => {
    if (date) return moment(date).format("DD/MM/YY HH:mm");
    else return '-';
  };

  return (
    <div>
      <h3 className="paper-header">
        <FormattedMessage
          id="accountDetails.titulo_pagina"
          defaultMessage="Menu Conta Digital"
          description="Menu Conta Digital" />
      </h3>
      <Divider />
      <div className="report-page-base-paper-body">
        <div className="report-page-base-paper-body-custom">
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.termo_abertura_conta"
                defaultMessage="Termo de Abertura de Conta: "
                description="Termo de Abertura de Conta: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatBoolean(account.accountOpeningTerm)}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.status_conta"
                defaultMessage="Status da Conta: "
                description="Status da Conta: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatAccountStatus(account.accountStatusDescription)}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.data_hora_criacao_conta"
                defaultMessage="Data e Hora da Criação da Conta: "
                description="Data e Hora da Criação da Conta: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatDate(account.accountOpeningAt)}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.termo_compartilhamento_dados"
                defaultMessage="Termo para Compartilhamento de Dados:"
                description="Termo para Compartilhamento de Dados: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatBoolean(account.accountShareDataTerm)}</span>
          </div>
        </div>
        <div className="report-page-base-paper-body-padding">
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.maquininha_solicitada"
                defaultMessage="Maquininha Solicitada: "
                description="Maquininha Solicitada: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatBoolean(account.requestedMachine)}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.data_hora_solicitacao_maquininha"
                defaultMessage="Data e Hora de Solicitação da Maquininha: "
                description="Data e Hora de Solicitação da Maquininha: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatDate(account.requestedMachineAt)}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.status_solicitacao_maquininha"
                defaultMessage="Status de Solicitação da Maquininha: "
                description="Status de Solicitação da Maquininha: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatMachineStatus(account.requestedMachineStatus)}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="accountDetails.microcredito_ativo"
                defaultMessage="Possui Microcrédito Ativo: "
                description="Possui Microcrédito Ativo: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{formatBoolean(account.microcreditActive)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

AcountDetails.propTypes = {
  intl: intlShape,
  account: PropTypes.object.isRequired
};

export default AcountDetails;
