import * as types from '../actions/actionTypes';
import _ from 'lodash/fp';

const initialState = {
  parameters: {},
  simulationCurrentValue: undefined,
  simulationValue: null,
  registrationsIndex: null,
  activitySBIndex: null,
  balanceIndex: null,
  bonusAccumulatedIndex: null,
  activityPersonsIndex: null,
  scores: null,
  scoresIndex: null,
  scoresNetwork: null
};

export default function indicatorsReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.SET_FIELD_REMUNERATION:
      return _.merge(state, { [payload.field]: payload.value });
    case types.GET_REMUNERATION_PARAMETERS:
      return { ...state, parameters: payload };
    case types.POST_SIMULATION_CURRENT:
      return { ...state, simulationCurrentValue: payload };
    case types.POST_SIMULATION:
      return { ...state, simulationValue: payload };
    case types.CLEAN_SIMULATION_RESULT:
      return { ...state, simulationValue: payload };
    case types.CLEAN_SIMULATION_VALUES:
      return {
        ...state,
        registrationsIndex: payload,
        activitySBIndex: payload,
        balanceIndex: payload,
        activityPersonsIndex: payload,
        scores: payload,
        scoresIndex: payload,
        scoresNetwork: payload
      };
    default:
      return state;
  }
}
