import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AccountDetails from './widgets/AccountDetails';
import Paper from 'material-ui/Paper';
import * as accountActions from '../../actions/accountActions';
import * as personActions from '../../actions/personActions';
import { injectIntl, intlShape } from 'react-intl';
import ReportPageBase from '../common/ReportPageBase';
import * as consultantActions from '../../actions/consultantActions';

class AccountReport extends React.Component {

  componentDidMount() {
    let { usuario } = this.props;
    this.props.personActions.getPerson(this.props.params.code, usuario).then((result) => {
      this.props.accountActions.getAccount(usuario, result.value.id_consult);
      this.props.consultantActions.getDigitalStoreDetails(usuario, result.value);
    });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  render() {
    let { account: { detailsAccount }, intl, person, consultant = {} } = this.props;
    let digitalStore = consultant.digitalStore || {}
    return (
      <ReportPageBase
      person={person}
      onNewService={this.handleNewService}
      commercialStatus={digitalStore.commercialStatus}
      intl={intl}
      >
      <div>
        <Paper className="account-report-paper">
          <AccountDetails account={detailsAccount} intl={ intl } />
        </Paper>
      </div>
         </ReportPageBase>
    )
  }
}

AccountReport.propTypes = {
  intl: intlShape,
  account: PropTypes.object,
  accountActions: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  params: PropTypes.object,
  usuario: PropTypes.object.isRequired,
  consultant: PropTypes.object.isRequired,
  consultantActions: PropTypes.object.isRequired,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    person: state.person,
    usuario: state.usuario,
    account: state.account,
    consultant: state.consultant
  };
}

function mapDispatchToProps(dispatch) {
  return {
    accountActions: bindActionCreators(accountActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AccountReport));
