import * as types from './actionTypes';
import personApi from '../api/personApi';

export function getPerson(code, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PERSON, payload: personApi.getPerson(code, usuario) });
  };
}

export function getThermometer(person, selectedCycleRange, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PERSON_THERMOMETER, payload: personApi.getThermometer(person, selectedCycleRange, usuario) });
  };
}

export function getLeaderThermometer(person, selectedCycleRange, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PERSON_LEADER_THERMOMETER, payload: personApi.getLeaderThermometer(person, selectedCycleRange, usuario) });
  };
}

export function getScoreCycles(code, selectedCycleRange, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PERSON_SCORE_CYCLES, payload: personApi.getScoreCycles(code, selectedCycleRange, usuario) });
  };
}

export function getCycleOrders(person, orders, usuario, cycle) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PERSON_CYCLE_ORDERS, payload: personApi.getCycleOrders(person, orders, usuario, cycle) });
  };
}

export function getCycleStatus(person, cycle, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PERSON_CYCLE_ORDERS, payload: personApi.getCycleStatus(person, cycle, usuario) });
  };
}

export function clearCycleOrders() {
  return function (dispatch) {
    return dispatch({ type: types.CLEAR_PERSON_CYCLE_ORDERS });
  };
}

export function getMonthlyPayments(person, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PERSON_MONTHLY_PAYMENT, payload: personApi.getMonthlyPayments(person, usuario) });
  };
}
