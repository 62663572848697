import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';

const styles = {
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    margin: '20px'
  },
  currentValue: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  customContentStyle: {
    width: '80%',
    maxWidth: 'none'
  }
};

class RemunerationTable extends Component {
  state = {
    fixedHeader: true,
    fixedFooter: false,
    stripedRows: false,
    showRowHover: true,
    selectable: false,
    multiSelectable: false,
    enableSelectAll: false,
    deselectOnClickaway: false,
    showCheckboxes: false
  };

  loadingData = data => {
    switch (data) {
      case null:
        return 'R$ -';
      case undefined:
        return <CircularProgress size={20}/>;
      default:
        return `R$ ${parseFloat(data.toFixed(2))}`;
    }
  };

  render() {
    const { handlers, simulationValue, tableDataRemuneration } = this.props;
    return (
      <div>
        <Table
          height={this.state.height}
          fixedHeader={this.state.fixedHeader}
          fixedFooter={this.state.fixedFooter}
          selectable={this.state.selectable}
          multiSelectable={this.state.multiSelectable}
        >
          <TableHeader
            displaySelectAll={this.state.showCheckboxes}
            adjustForCheckbox={this.state.showCheckboxes}
            enableSelectAll={this.state.enableSelectAll}
          >
            <TableRow>
              <TableHeaderColumn></TableHeaderColumn>
              <TableHeaderColumn>Real</TableHeaderColumn>
              <TableHeaderColumn>Acelerador</TableHeaderColumn>
              <TableHeaderColumn>Atingimento</TableHeaderColumn>
              <TableHeaderColumn>Real Simulado</TableHeaderColumn>
              <TableHeaderColumn>Atingimento Simulado</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={this.state.showCheckboxes}
            deselectOnClickaway={this.state.deselectOnClickaway}
            showRowHover={this.state.showRowHover}
            stripedRows={this.state.stripedRows}
          >
            {tableDataRemuneration &&
              tableDataRemuneration.map(
                (row, index) =>
                  row.enable && (
                    <TableRow key={index}>
                      <TableRowColumn> {row.indicador}</TableRowColumn>
                      <TableRowColumn>{row.real}</TableRowColumn>
                      <TableRowColumn>{row.meta}</TableRowColumn>
                      <TableRowColumn>{row.atingimento}</TableRowColumn>
                      <TableRowColumn>
                        <TextField
                          name={row.realSimuladoLabel}
                          value={row.realSimulado}
                          onChange={handlers.setFieldRemuneration}
                        />
                      </TableRowColumn>
                      <TableRowColumn>
                        {row.atingimentoSimulado}
                      </TableRowColumn>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
        <div style={styles.grid}>
          <div style={styles.item}>
            <span style={styles.currentValue}>
              {this.loadingData(simulationValue)}
            </span>
          </div>
          <div>
            <RaisedButton
              backgroundColor="#7080A0"
              labelColor="#FFFFFF"
              label=" Simular Remuneração"
              onClick={() => handlers.simulation('simulation')}
            />
          </div>
        </div>
      </div>
    );
  }
}

RemunerationTable.propTypes = {
  tableDataRemuneration: PropTypes.array,
  simulationValue: PropTypes.any,
  handlers: PropTypes.any
};

export default RemunerationTable;
