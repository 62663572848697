import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router';
import AppBar from 'material-ui/AppBar';
import IconButton from 'material-ui/IconButton';
import Input from 'material-ui/svg-icons/action/input';
import Menu from 'material-ui/svg-icons/navigation/menu';
import {white} from 'material-ui/styles/colors';
import { FormattedMessage } from 'react-intl';

class Header extends React.Component {
  render() {
    const { usuario, handleChangeRequestNavDrawer } = this.props;

    return (
      <div>
        <AppBar
          className="appBar"
          title={
            <div className="appBar-title">
              <div>
                <img className="header-logo-img" src={require('../../img/logo-horizontal.png')} alt="Natura Logo"  />
              </div>
              <h2>
                <FormattedMessage
                  id="header.titulo"
                  defaultMessage="Central de Relacionamento"
                  description="Titulo Central de Relacionamento"
                />
              </h2>
            </div>
          }
          showMenuIconButton={this.props.displayMenuIconButton}
          iconElementLeft={
            <IconButton className="appBar-icon-menu" onClick={handleChangeRequestNavDrawer}>
             <Menu color={white}/>
            </IconButton>
          }
          iconElementRight={
            <div className="appBar-info-user-container">
              <div>
                <span className="appBar-info-user-custom">
                  <FormattedMessage
                    id="header.atendente"
                    defaultMessage="Atendente"
                    description="Atendente"
                  />
                </span>
                <span>{usuario.userName}</span>
              </div>
              <div className="appBar-info-user-body">
                {usuario.isUserDefault ? '' :
                  <Link to="/signOut">
                    <div>
                      <FormattedMessage
                        id="header.sair"
                        defaultMessage="Sair"
                        description="Sair"
                      />
                    </div>
                    <Input color={white}/>
                  </Link>
                }
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

Header.propTypes = {
  styles: PropTypes.object,
  usuario: PropTypes.object.isRequired,
  displayMenuIconButton: PropTypes.bool.isRequired,
  handleChangeRequestNavDrawer: PropTypes.func.isRequired
};

export default Header;
