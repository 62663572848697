import * as types from '../actions/actionTypes';

const initialState = {
  criteria: [],
  training: []
};

function processPayload(payload) {
  return Object.assign({}, payload, {
    criteria: payload.criteria,
    training: payload.training,
  })
}

export default function elegibilityReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.LOAD_ELEGIBILITY}_FULFILLED`:
      return processPayload(payload);
    default:
      return state;
  }
}
