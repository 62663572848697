import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedNumber, injectIntl, intlShape } from 'react-intl';
import ReportPageBase from '../common/ReportPageBase';

import Alert from '../global/Alert';

// import DeductionMetrics from './widgets/DeductionMetrics';
import PaymentsHistory from './widgets/PaymetsHistory';

import * as personActions from '../../actions/personActions';

class MonthlyPaymentReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 0
    };

    if (!this.props.params.code || !parseInt(this.props.params.code))
      this.props.router.push('/');
  }

  componentDidMount() {
    this.props.personActions.getPerson(this.props.params.code, this.props.usuario)
      .then(response => {
        let person = {...this.props.person, ...response.action.payload};

        this.props.personActions.getMonthlyPayments(person, this.props.usuario);
      });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  numberFormatter = (number) => {
    return <FormattedNumber value={number || 0} />;
  };

  currencyFormatter = (number) => { // eslint-disable-next-line react/style-prop-object
    return <FormattedNumber value={number || 0} style="currency" currency="BRL" />;
  };

  render() {
    let { person } = this.props;

    return (
      <ReportPageBase
        person={person}
        onNewService={this.handleNewService}
      >
        <div>
          {/*{person.monthlyPayment && person.monthlyPayment.sd_deduction_metrics &&*/}
            {/*<DeductionMetrics*/}
              {/*person={person}*/}
              {/*numberFormatter={this.numberFormatter}*/}
              {/*currencyFormatter={this.currencyFormatter}*/}
            {/*/>*/}
          {/*}*/}
          {/*{person.monthlyPayment && person.monthlyPayment.sd_payments_history &&*/}
            {/*<PaymentsHistory*/}
              {/*person={person}*/}
              {/*numberFormatter={this.numberFormatter}*/}
              {/*currencyFormatter={this.currencyFormatter}*/}
            {/*/>*/}
          {/*}*/}

          <PaymentsHistory
            person={person}
            numberFormatter={this.numberFormatter}
            currencyFormatter={this.currencyFormatter}
          />

          <Alert ref={alert => this.alert = alert}/>
        </div>
      </ReportPageBase>
    );
  }
}

MonthlyPaymentReport.propTypes = {
  intl: intlShape,
  usuario: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  params: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    person: state.person
  };
}

function mapDispatchToProps(dispatch) {
  return {
    personActions: bindActionCreators(personActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MonthlyPaymentReport));
