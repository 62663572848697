import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';
import App from './components/App';
import HomePage from './components/home/HomePage';
import GrowthPlanReport from './components/reports/GrowthPlanReport';
import ProductKitReportPage from './components/reports/ProductKitReportPage';
import LeaderKitReportPage from './components/reports/LeaderKitReportPage';
import MonthlyPaymentReport from './components/reports/MonthlyPaymentReport';
import SignIn from './components/login/SignIn';
import SignOut from './components/login/SignOut';
import UnauthorizedPage from './components/common/UnauthorizedPage';
import {UserIsAuthenticated, UserIsUser} from './auth';
import ElegibilityReport from "./components/reports/ElegibilityReport";
import LeaderReport from "./components/reports/LeaderReport";
import LeaderReportWithNoTracking from "./components/reports/LeaderReportWithNoTracking";
import MySucessReport from "./components/reports/MySucessReport";
import MySucessDetailReport from "./components/reports/MySucessDetailReport";
import MedalReport from "./components/reports/MedalReport";
import AccountReport from "./components/reports/AccountReport";
import ConsultantReport from "./components/reports/ConsultantReport";
import IndicatorsReport from "./components/reports/IndicatorsReport";
//  PoC
import ExternalReport from './components/reports/ExternalReport';

export const getRoutes = (store) => {
  const state = store.getState();
  const customNavigation = state.externalApps.customNavigation;

  return (
  <div>
    <Route path="/" component={App}>
      <IndexRoute component={SignIn} />
      <Route path="/signIn" component={SignIn} />
      <Route path="/signOut" component={SignOut} />
      <Route path="/home" component={UserIsAuthenticated(UserIsUser(HomePage))} />
      <Route path="/unauthorized" component={UserIsAuthenticated(UnauthorizedPage)} />
      <Route path="/extratos">
        <IndexRoute component={GrowthPlanReport} />
        <Route path="/extratos/plano-de-crescimento/:code" component={UserIsAuthenticated(UserIsUser(GrowthPlanReport))} />
        <Route path="/extratos/kitdesejo/:code" component={UserIsAuthenticated(UserIsUser(ProductKitReportPage))} />
        <Route path="/extratos/kitlider/:code" component={UserIsAuthenticated(UserIsUser(LeaderKitReportPage))} />
        <Route path="/extratos/rede-natura/:code" local={UserIsAuthenticated(UserIsUser(MonthlyPaymentReport))} />
        <Route path="/extratos/elegibilidade/:code" component={UserIsAuthenticated(UserIsUser(ElegibilityReport))} />
        <Route path="/extratos/leader/:code" component={UserIsAuthenticated(UserIsUser(LeaderReport))} />
        <Route path="/extratos/leader2/:code" component={UserIsAuthenticated(UserIsUser(LeaderReportWithNoTracking))} />
        <Route path="/extratos/elegibilidade/:code" component={UserIsAuthenticated(UserIsUser(ElegibilityReport))} />
        <Route path="/extratos/meusucesso/:code" component={UserIsAuthenticated(UserIsUser(MySucessReport))} />
        <Route path="/extratos/medalha/:code" component={UserIsAuthenticated(UserIsUser(MedalReport))} />
        <Route path="/extratos/conta/:code" component={UserIsAuthenticated(UserIsUser(AccountReport))} />
        <Route path="/extratos/meusucessodetalhe/:code/:periodo" component={UserIsAuthenticated(UserIsUser(MySucessDetailReport))} />
        <Route path="/extratos/espaco-digital/:code" component={UserIsAuthenticated(UserIsUser(ConsultantReport))} />
        <Route path="/extratos/indicadores/:code" component={UserIsAuthenticated(UserIsUser(IndicatorsReport))} />
      </Route>
      {
        customNavigation && customNavigation.length > 0 &&
        customNavigation.map((menu, index) => <Route key={index} path={menu.link} component={UserIsAuthenticated(UserIsUser(ExternalReport))} />)
      }
      </Route>
      <Redirect from='*' to='/home' />
    </div>
    )
};

export default getRoutes;
