import * as types from '../actions/actionTypes';
import _ from 'lodash/fp';

const initialState = {
  cycle: '',
  indicators: {},
  indicatorsDetails: [],
  token: {}
};

export default function indicatorsReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.SET_FIELD:
      return _.merge(state, { [payload.field]: payload.value });
    case types.GET_TOKEN:
      return { ...state, token: payload };
    case types.RESET_INDICATORS:
      return { ...state, indicators: payload };
    case types.GET_INDICATORS:
      return { ...state, indicators: payload };
    case types.GET_INDICATORS_DETAILS:
      return { ...state, indicatorsDetails: payload };
    case types.CLEAN_INDICATORS_DETAILS:
      return { ...state, indicatorsDetails: payload };
    default:
      return state;
  }
}
