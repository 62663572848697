// eslint-disable-next-line
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Divider from 'material-ui/Divider';

const ProductKitDetail = ({productKitDetail}) => {
  return (
    <div>
      <div className="product-kit-detail-tr"/>
      <h4 className="product-kit-detail-page-title paper-header">{productKitDetail.cd_product +" - "+productKitDetail.no_product}</h4>
      <div className="product-kit-detail-box-container">
        <div className="product-kit-detail-box-header">
          <img style={{width:"414px"}} src={`http://www.natura.com.br/sites/default/files/products/${productKitDetail.cd_product}-0.jpg`} />
        </div>
        <div className="product-kit-detail-box-header-body">
          <h4 className="product-kit-detail-page-subtitle paper-header">
            <FormattedMessage
              id="extratos.kit.desejo.composicao"
              defaultMessage="Composição"
              description="Kit Desejo Composição"
            />
          </h4>
          <Divider />
         <div className="product-kit-detail-box-body">
           {productKitDetail.sd_products.map((product) => (
             <div className="product-kit-detail-box-ball-container" key={"product_item"+product.id_product}>
               <div className="product-kit-detail-box-ball"/><span>{product.no_product}</span>
             </div>
           ))}
         </div>
        </div>
      </div>
    </div>
  );
};

ProductKitDetail.propTypes = {
  productKitDetail: PropTypes.object.isRequired
};

export default ProductKitDetail;
