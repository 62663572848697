/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Paper, Divider } from 'material-ui';
import { Table, TableBody, TableHeaderColumn, TableRow, TableRowColumn, TableHeader } from 'material-ui/Table';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { white } from 'material-ui/styles/colors';
import { indicators } from '../../../../utils/indicators';
import _ from 'lodash';

class VolumePointsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterExtract: [],
      valueFilterExtract: 0,
      selectedItem: {
        volume: {},
        activity: {},
        bonus: {},
        taxation: {},
        manualAdjust: {},
        additionalProperties: {},
        silverPlus: {},
        active: {}
      },
    }
    this.styleCards = {
      boxShadow: "rgba(0, 0, 0, 0.12) 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px !important",
      border: "1px solid rgba(0, 0, 0, 0.12)"
    }
    this.styleCardsTitle = {
      fontSize: "18px !important"
    }
    this.styleLineColunms = { textAlign: 'right' }
  }
  componentDidMount = () => {
    const { tableData } = this.props;
    if (tableData) {

      this.setState({ selectedItem: tableData })
    }

  }

  handleTypeManulAdjust = (type) => {
    switch (type) {
      case "1":
        return "Campanhas"
        // break;
      case "2":
        return "Projetos"
        // break;
      case "3":
        return "Ajustes"
      default:
        return "Ajustes"
        // break;
    }
  }

  handleTotalManualAdjust = (manualAdjust) => {
    return _.sumBy(manualAdjust, function (o) { return parseInt(o.value); });
  }
  // handleFaixas(matrizParameters) {

  //   numberOfRows.map((obj, index) => {
  //     <TableRow>
  //       <TableRowColumn style={{ textAlign: 'center' }}>{matrizParameters.columns[0].rows[0].rangeBegin}% Até {matrizParameters.columns[0].rows[0].rangeEnd}% </TableRowColumn>
  //       {numberOfColuns.map((obj, indexColumn) => {
  //         <TableRowColumn style={{ textAlign: 'center' }}>{matrizParameters.columns[indexColumn].rows[index].value}%</TableRowColumn>
  //       })}

  //     </TableRow>
  //   });
  // }

  render() {
    const { selectedItem } = this.state;

    const { volume, activity, bonus, taxation, additionalProperties, manualAdjust, silverPlus, active } = selectedItem;
    const haveMatrizValue = !!additionalProperties.matrizParameters && !!additionalProperties.bonusParameters && (additionalProperties.matrizParameters.leaderLevelName && additionalProperties.bonusParameters.leaderLevelName);
    const { matrizParameters, bonusParameters } = additionalProperties;
    let numberOfRows = [];
    let numberOfColuns = [];
    if (matrizParameters && matrizParameters.columns) {
      numberOfRows = matrizParameters.columns[0].rows;
      numberOfColuns = matrizParameters.columns;
    }
    
    return (
      <div>
        <div>
          <Paper className="payment-tracker-first-table">
            <div>
              <h4 className="paper-header extract-detail">
                <FormattedMessage
                  id="tabela-extrato-detalhado"
                  defaultMessage="Extrato detalhado"
                  description="Extrato detalhado"
                />
              </h4>
            </div>
            <Divider />
            <br />
            <Card style={this.styleCards}>
              <CardHeader
                titleStyle={{ fontSize: "18px" }}
                title="Detalhes da remuneração"
                actAsExpander={false}
                showExpandableButton={true}
              />
              <CardText className="row" expandable={true}>
                <div className="col-md-offset-2 col-md-8">
                  <h4>
                    <FormattedMessage
                      id="tabela-extrato-detalhado-volume-pontos-atividade"
                      defaultMessage={
                          matrizParameters ? indicators[matrizParameters.parameterColumnType] +
                          ' / ' + 
                          indicators[matrizParameters.parameterRowType] : ' '
                        }
                      description={
                        matrizParameters ? indicators[matrizParameters.parameterColumnType] +
                        ' / ' + 
                        indicators[matrizParameters.parameterRowType] : ' '
                      }

                    />
                  </h4>
                  <Table selectable={false}>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-gatilho-volume-pontos"
                            defaultMessage="Acelerador de volume de pontos"
                            description="Acelerador de volume de pontos"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{volume.goalPoints}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-volume-pontos-real"
                            defaultMessage="Volume de pontos real"
                            description="Volume de pontos real"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{volume.realPoints}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-volume-pontos-natura"
                            defaultMessage="Volume de pontos Rede Natura"
                            description="Volume de pontos Rede Natura"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{volume.redePoints}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-volume-pontos-total"
                            defaultMessage="Volume de pontos total"
                            description="Volume de pontos total"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{volume.totalPoints}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-atingimento-ativas-prata-mais"
                            defaultMessage="Atingimento de ativas prata mais"
                            description="Atingimento de ativas prata mais"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{silverPlus.exceeded}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-gatilho-ativas-prata-mais"
                            defaultMessage="Acelerador de ativas prata mais"
                            description="Acelerador de ativas prata mais"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{silverPlus.goal}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-ativas-prata-mais-real"
                            defaultMessage="Ativas prata mais real"
                            description="Ativas prata mais real"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{silverPlus.real}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-atingimento-ativas"
                            defaultMessage="Atingimento de ativas"
                            description="Atingimento de ativas"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{active.exceeded}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-gatilho-ativas"
                            defaultMessage="Acelerador de ativas"
                            description="Acelerador de ativas"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{active.goal}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-ativas-real"
                            defaultMessage="Ativas real"
                            description="Ativas real"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{active.real}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-atingimento-volume"
                            defaultMessage="Atingimento de volume"
                            description="Atingimento de volume"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{volume.exceeded}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-gatilho-atividade"
                            defaultMessage="Acelerador de atividade"
                            description="Acelerador de atividade"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{activity.goal}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-atividade-real"
                            defaultMessage="Atividade real"
                            description="Atividade real"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{activity.real}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-atingimento-atividade"
                            defaultMessage="Atingimento de atividade"
                            description="Atingimento de atividade"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{activity.exceeded}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }} >
                          <FormattedMessage
                            id="tabela-cadastro-comissao-volume-pontos"
                            defaultMessage="Comissão sobre volume de pontos"
                            description="Comissão sobre volume de pontos"
                          />

                          <FormattedMessage
                            id="tabela-extrato-detalhado-cadastro"
                            defaultMessage='{name}'
                            description='{bonus.bonusName}'
                            values={{
                              name: bonus.bonusName || 'Bonus'
                            }}
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms} >{volume.comissionPoints}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-remuneracao-rede-natura"
                            defaultMessage="Remuneração pelo rede Natura"
                            description="Remuneração pelo rede Natura"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{volume.remunerationByNaturaNetwork}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn></TableHeaderColumn>
                        <TableRowColumn style={{ textAlign: 'center', backgroundColor: 'rgba(119, 140, 163, 1.0)', fontSize: '15px', color: white }}>
                          <FormattedMessage
                            id="tabela-cadastro-total"
                            values={{ valor: selectedItem.totalVolumeActvityValue || 0.00 }}
                            defaultMessage={`Total: R$ {valor}`}
                            description="Total: "
                          />
                        </TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
                {
                  bonus.bonusName != "null" ? (
                <div className="col-md-offset-2 col-md-8">

                  <h4>
                    <FormattedMessage
                      id="tabela-extrato-detalhado-cadastro"
                      defaultMessage='{name}'
                      description='{bonus.bonusName}'
                      values={{
                        name: bonus.bonusName || 'Bonus'
                      }}
                    />
                  </h4>
                  <Table selectable={false}>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow style={bonus.bonusName === 'Saldo' ? {display: "none"} : {display: "table-row"}}>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-gatilho"
                            defaultMessage="Acelerador"
                            description="Acelerador"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{bonus.goal || 0}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-real"
                            defaultMessage="Real"
                            description="Real"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{bonus.real || 0}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-atingimento"
                            defaultMessage="Atingimento"
                            description="Atingimento"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>{bonus.exceeded || 0}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn></TableHeaderColumn>
                        <TableRowColumn style={{ textAlign: 'center', backgroundColor: 'rgba(119, 140, 163, 1.0)', fontSize: '15px', color: white }}>
                          <FormattedMessage
                            id="tabela-cadastro-total"
                            values={{ valor: bonus.bonusValue || 0.00 }}
                            defaultMessage={`Total: R$ {valor}`}
                            description="Total: "
                          />
                        </TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* <div style={{textAlign: "right", padding: "20px", backgroundColor: 'rgba(119, 140, 163, 1.0)', fontSize: '15px', color:white }}>
                      <span>Teste</span>
                    </div> */}
                </div>
                ) : ''
              }
                {
                  manualAdjust && manualAdjust.length > 0 ? (
                    <div className="col-md-offset-2 col-md-8">
                      <h4>
                        <FormattedMessage
                          id="tabela-extrato-detalhado-cadastro-outros"
                          defaultMessage="Outros"
                          description="Outros"
                        />
                      </h4>
                      <Table selectable={false}>
                        <TableBody displayRowCheckbox={false}>
                          {
                            manualAdjust.map((obj, index) =>
                              <TableRow key={index}>
                                <TableHeaderColumn style={{ fontSize: "14px", color: "black" }}>
                                  {this.handleTypeManulAdjust(obj.type)}
                                </TableHeaderColumn>
                                <TableRowColumn style={this.styleLineColunms}>R$ {obj.value || 0}</TableRowColumn>
                              </TableRow>
                            )
                          }

                          <TableRow>
                            <TableHeaderColumn></TableHeaderColumn>
                            <TableRowColumn style={{ textAlign: 'center', backgroundColor: 'rgba(119, 140, 163, 1.0)', fontSize: '15px', color: white }}>
                              <FormattedMessage
                                id="tabela-cadastro-total"
                                values={{ valor: this.handleTotalManualAdjust(manualAdjust) || 0.00 }}
                                defaultMessage={`Total: R$ {valor}`}
                                description="Total: "
                              />
                            </TableRowColumn>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>

                  ) : ''
                }
                <div className="col-md-offset-2 col-md-8" style={{ paddingTop: "20px" }}>
                  <Table selectable={false}>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow>
                        <TableHeaderColumn></TableHeaderColumn>
                        <TableRowColumn style={{ textAlign: 'center', border: "none", backgroundColor: 'rgba(119, 140, 163, 1.0)', fontSize: '15px', color: white }}>
                          <FormattedMessage
                            id="tabela-cadastro-total-bruto"
                            values={{ valor: selectedItem.remunerationBrutaCycle || 0.00 }}
                            defaultMessage={`Valor total Bruto: R$ {valor}`}
                            description="Valor total Bruto: "
                          />
                        </TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>

              </CardText>
            </Card>
            <Card style={{ ...this.styleCards, borderTop: "0px" }}>
              <CardHeader
                titleStyle={{ fontSize: "18px" }}
                title="Faixas de valores consideradas no cálculo"
                actAsExpander={false}
                showExpandableButton={true}
              />
              <CardText className="row" expandable={true}>
                <div className="col-md-offset-1 col-md-10 ">
                  {haveMatrizValue ? (
                    <div><Table className="table-faixas" multiSelectable={false} selectable={false}>
                      <TableHeader displaySelectAll={false}
                        adjustForCheckbox={false}
                        enableSelectAll={false}>
                        <TableRow>
                          <TableHeaderColumn style={{ textAlign: 'center', fontSize: "14px", color: "black" }}>
                            {
                              matrizParameters ? indicators[matrizParameters.parameterColumnType] +
                              ' / ' + 
                              indicators[matrizParameters.parameterRowType] : ' '
                            }
                          </TableHeaderColumn>
                          {
                            matrizParameters.columns.map((obj, index) => (
                              <TableHeaderColumn key={index} style={{ textAlign: 'center', fontSize: "14px", color: "black" }}>{obj.rangeBegin}% Até {obj.rangeEnd}%</TableHeaderColumn>
                            )
                            )
                          }

                        </TableRow>
                        
                      </TableHeader>
                      <TableBody displayRowCheckbox={false}>
                        {numberOfRows.map((obj, index) =>
                          <TableRow key={index}>
                            <TableRowColumn style={{ textAlign: 'center', fontSize: "14px", color: "black" }}>{matrizParameters.columns[0].rows[index].rangeBegin}% Até {matrizParameters.columns[0].rows[index].rangeEnd}% </TableRowColumn>
                            {numberOfColuns.map((obj, indexColumn) =>
                              <TableRowColumn key={indexColumn} style={{ textAlign: 'center', fontSize: "14px", color: "black" }}>{matrizParameters.columns[indexColumn].rows[index].value}%</TableRowColumn>
                            )}
                          </TableRow>
                        )
                        }
                      </TableBody>
                    </Table>
                      <div className="col-md-offset-2 col-md-8">
                        <br />
                        <h4>
                          <FormattedMessage
                            id="tabela-extrato-detalhado-cadastro"
                            defaultMessage='{name}'
                            description='{bonus.bonusName}'
                            values={{
                              name: bonus.bonusName || 'Bonus'
                            }}
                          />
                        </h4>
                        <Table selectable={false}>
                          <TableBody displayRowCheckbox={false}>
                            {
                              bonusParameters.rows.map((obj, index) =>
                                <TableRow key={index}>
                                  <TableHeaderColumn style={{ textAlign: 'center', fontSize: "14px", color: "black" }} >
                                    {obj.rangeBegin}% até {obj.rangeEnd}%
                                  </TableHeaderColumn>
                                  <TableRowColumn style={this.styleLineColunms}>R$ {obj.value || 0}</TableRowColumn>
                                </TableRow>
                              )
                            }
                          </TableBody>
                        </Table>
                      </div>
                    </div>) :
                    (<div className="homepage-messenger-container">
                      <div>
                        <FormattedMessage
                          id="faaixas-de-calculo-indisponiveis"
                          defaultMessage="Não existem faixas de calculos disponiveis para consulta"
                          description="Não existem faixas de calculos disponiveis para consulta"
                        />
                      </div>
                    </div>)
                  }

                </div>
              </CardText>
            </Card>
            <Card style={{ ...this.styleCards, borderTop: "0px" }}>
              <CardHeader
                titleStyle={{ fontSize: "18px" }}
                title="Tributação"
                actAsExpander={false}
                showExpandableButton={true}
              />
              <CardText className="row" expandable={true}>
                <div className="col-md-offset-2 col-md-8 ">
                  <Table selectable={false}>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow>
                        <TableHeaderColumn style={{ textAlign: 'center', fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-insss"
                            defaultMessage="INSS"
                            description="INSS"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>R$ {taxation.inssValue || 0}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn style={{ textAlign: 'center', fontSize: "14px", color: "black" }}>
                          <FormattedMessage
                            id="tabela-cadastro-real"
                            defaultMessage="Real"
                            description="Real"
                          />
                        </TableHeaderColumn>
                        <TableRowColumn style={this.styleLineColunms}>R$ {taxation.irrfValue || 0}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableHeaderColumn></TableHeaderColumn>
                        <TableRowColumn style={{ textAlign: 'center', backgroundColor: 'rgba(119, 140, 163, 1.0)', fontSize: '15px', color: white }}>
                          <FormattedMessage
                            id="tabela-cadastro-total-liquido"
                            values={{ valor: selectedItem.paymentLiquid || 0.00 }}
                            defaultMessage={`Total liquido: R$ {valor}`}
                            description="Total: "
                          />
                        </TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </CardText>
            </Card>
          </Paper>

        </div>

      </div>

    )
  }

}

VolumePointsTable.propTypes = {
  tableData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};

export default VolumePointsTable


