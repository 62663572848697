import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import usuario from './authReducer';
import people from './peopleReducer';
import person from './personReducer';
import externalApps from './externalAppsReducer';
import dynamicNavigation from './dynamicNavigationReducer';
import growthPlan from './growthPlanReducer';
import productKit from './productKitReducer';
import leaderKit from './leaderKitReducer';
import elegibility from './elegibilityReducer';
import report from './reportReducer';
import sucess from './sucessReducer';
import sucessDetail from './sucessDetailReducer';
import medal from './medalReducer';
import account from './accountReducer';
import consultant from './consultantReducer';
import indicators from './indicatorsReducer';
import remuneration from './remunerationReducer';
import bonus from './bonusReducer';

const rootReducer = combineReducers({
  usuario,
  people,
  person,
  externalApps,
  dynamicNavigation,
  growthPlan,
  productKit,
  leaderKit,
  elegibility,
  routing: routerReducer,
  report,
  sucess,
  medal,
  sucessDetail,
  account,
  consultant,
  indicators,
  remuneration,
  bonus
});

export default rootReducer;
