import React from 'react';
import { FormattedMessage } from 'react-intl';

class UnauthorizedPage extends React.Component {
  render(){
    return (
      <div>
        <p>
        <FormattedMessage
          id="unauthorized.mensagem"
          description="Mensagem de Acesso Negado"
          defaultMessage="Usuário sem acesso as funcionalidades do sistema"
          /></p>
      </div>
    );
  }
}

export default UnauthorizedPage;
