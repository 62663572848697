import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import globalStyles from '../../../styles';
import { IconButton, TextField } from 'material-ui';
import SearchIcon from 'material-ui/svg-icons/action/search';
import IndicatorsTable from './Tables/IndicatorsTable';
import { PropTypes } from 'prop-types';

class IndicatorsPanel extends Component {
  render() {
    const { cycle, handlers, tableDataRemunerated, tableDataBonus, tableDataIndicators, isLeader } = this.props;

    return (
      <div>
        <div style={globalStyles.container}>
          <div className="left">
            <TextField
              floatingLabelText={
                <FormattedMessage
                  id="leader.extrato.ciclo_placeholder"
                  defaultMessage="Digite um ciclo"
                  description="Digite um ciclo"
                />
              }
              name="cycle"
              value={cycle}
              onChange={handlers.changeTextField}
              onKeyUp={handlers.keyUp}
              maxLength="6"
            />
            <IconButton
              iconStyle={{
                color: '#7080A0',
                borderRadius: '50px'
              }}
              disabled={JSON.stringify(cycle).length !== 8}
              onClick={handlers.search}
            >
              <SearchIcon />
            </IconButton>
          </div>
        </div>
        <div style={globalStyles.container}>
          <IndicatorsTable
            isLeader={isLeader}
            data={tableDataRemunerated}
            type={'Remuneráveis'}
            toggleClass={handlers.toggleClass}
            indicatorsDetails={handlers.indicatorsDetails}
          />
        </div>
          <div style={globalStyles.container}>
            <IndicatorsTable
              isLeader={isLeader}
              data={tableDataBonus}
              // Checking if bonus is accumulated (more than one line inside tableDataBonus array)
              type={tableDataBonus.length > 1 ? 'Bônus Acumulado' : 'Bônus' }
              toggleClass={handlers.toggleClass}
              indicatorsDetails={handlers.indicatorsDetails}
            />
          </div>
          <div style={globalStyles.container}>
          <IndicatorsTable
            isLeader={isLeader}
            data={tableDataIndicators}
            type={'Indicadores'}
            toggleClass={handlers.toggleClass}
            indicatorsDetails={handlers.indicatorsDetails}
          />
        </div>
      </div>
    );
  }
}

IndicatorsPanel.propTypes = {
  cycle: PropTypes.any,
  handlers: PropTypes.object.isRequired,
  tableDataBonus: PropTypes.any,
  tableDataRemunerated: PropTypes.any,
  tableDataIndicators: PropTypes.any,
  isLeader: PropTypes.bool
};

export default IndicatorsPanel;
