import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {  injectIntl } from 'react-intl';
import ReportPageBase from '../common/ReportPageBase';
import MySucessDetail from './widgets/MySucessDetail';
import * as mySucessDetailActions from '../../actions/mySucessDetailActions';
import * as growthPlanActions from '../../actions/growthPlanActions';
import * as personActions from '../../actions/personActions';

class MySucessDetailReport extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.params.code || !parseInt(this.props.params.code))
          this.props.router.push('/');
    if (!this.props.params.periodo || !parseInt(this.props.params.periodo))
          this.props.router.push('/');
  }

  componentDidMount() {
    let {usuario} = this.props;
    this.props.personActions.getPerson(this.props.params.code, usuario)
      .then( () => {
        // let result = _.value.dc_roles;
          this.props.personActions.getPerson(this.props.params.code, usuario);
          this.props.mySucessDetailActions.getMySucessDetail(this.props.params.periodo,this.props.params.code, usuario)
        });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  render() {
    let { person, sucessDetail } = this.props;
    return (
      <div>
        <ReportPageBase
          person={person}
          onNewService={this.handleNewService}
        >

            <div>
              <MySucessDetail
                sucessDetail={sucessDetail.data}
                {...this.props}
              />
            </div>

        </ReportPageBase>
      </div>
    );
  }
}

MySucessDetailReport.propTypes = {
  sucessDetail: PropTypes.object.isRequired,
  mySucessDetailActions: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  growthPlan: PropTypes.object.isRequired,
  growthPlanActions: PropTypes.object.isRequired,
  params: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    growthPlan: state.growthPlan,
    person: state.person,
    sucessDetail: state.sucessDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    growthPlanActions: bindActionCreators(growthPlanActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    mySucessDetailActions: bindActionCreators(mySucessDetailActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MySucessDetailReport));
