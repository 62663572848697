import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import FiberManualRecord from 'material-ui/svg-icons/av/fiber-manual-record';

const CycleOrders = (props) => {

  const getLevel = (level) => {
    if (level == null) {
      return ' - '
      // eslint-disable-next-line
    } else if (level == "SEMENTE") {
      return <FiberManualRecord color={`rgba(${123}, ${106}, ${88}, ${1})`} />
      // eslint-disable-next-line
    } else if (level == "BRONZE") {
      return <FiberManualRecord color={`rgba(${196}, ${127}, ${91}, ${1})`} />
      // eslint-disable-next-line
    } else if (level == "PRATA") {
      return <FiberManualRecord color={`rgba(${157}, ${157}, ${156}, ${1})`} />
      // eslint-disable-next-line
    } else if (level == "OURO") {
      return <FiberManualRecord color={`rgba(${216}, ${183}, ${94}, ${1})`} />
      // eslint-disable-next-line
    } else if (level == "DIAMANTE") {
      return <FiberManualRecord color={`rgba(${174}, ${194}, ${206}, ${1})`} />
    }
  };

  const getOrderOrigin = (origin, channel) => {
    // eslint-disable-next-line default-case
    switch (channel) {
      case 1: {
        if (origin == null)
          return <div>
            <FormattedMessage
              id="geral.origem.atendimento"
              defaultMessage="Atendimento"
              description="Meio de captação"
            />
            <br />
            <FormattedMessage
              id="geral.canal.captacao.can"
              defaultMessage="Captação CAN"
              description="Nome do canal de captação"
            />
          </div>;

        break;
      }
      case 8: {
        if ([1, 4].indexOf(origin) > -1)
          return <div>
            <FormattedMessage
              id="geral.origem.internet"
              defaultMessage="Internet"
              description="Meio de captação"
            />
            <br />
            <FormattedMessage
              id="geral.canal.captacao.consultores"
              defaultMessage="(WEB)"
              description="Nome do canal de captação"
            />
          </div>;
        else if ([null, 5].indexOf(origin) > -1)
          return <div>
            <FormattedMessage
              id="geral.origem.app"
              defaultMessage="App Consultoria"
              description="Meio de captação"
            />
            <br />
            <FormattedMessage
              id="geral.canal.captacao.consultores"
              defaultMessage="(WEB)"
              description="Nome do canal de captação"
            />
          </div>;

        break;
      }
      case 11: {
        if ([1, 4].indexOf(origin) > -1)
          return <div>
            <FormattedMessage
              id="geral.origem.internet"
              defaultMessage="Internet"
              description="Meio de captação"
            />
            <br />
            <FormattedMessage
              id="geral.canal.captacao.lideres"
              defaultMessage="(WEB-Líder)"
              description="Nome do canal de captação"
            />
          </div>;
        else if ([null, 5].indexOf(origin) > -1)
          return <div>
            <FormattedMessage
              id="geral.origem.app"
              defaultMessage="App Consultoria"
              description="Meio de captação"
            />
            <br />
            <FormattedMessage
              id="geral.canal.captacao.lideres"
              defaultMessage="(WEB-Líder)"
              description="Nome do canal de captação"
            />
          </div>;

        break;
      }
    }
  };

  const getOrderStatus = (status) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case 3:
        return <FormattedMessage
          id="geral.pedido.status.captado.ok"
          defaultMessage="Captado OK"
          description="Captado OK"
        />;
      case 5:
        return <FormattedMessage
          id="geral.pedido.status.concluido.aprovado"
          defaultMessage="Concluído e aprovado"
          description="Concluído e aprovado"
        />;
      case 17:
        return <FormattedMessage
          id="geral.pedido.status.faturamento"
          defaultMessage="Enviado para faturamento"
          description="Enviado para faturamento"
        />;
      case 19:
        return <FormattedMessage
          id="geral.pedido.status.faturado"
          defaultMessage="Faturado"
          description="Faturado"
        />;
      case 23:
        return <FormattedMessage
          id="geral.pedido.status.enviado.separacao"
          defaultMessage="Enviado para a separação"
          description="Enviado para a separação"
        />;
    }
  };

  return (
    <div className="growth-plan-report-container">
      <div className="growth-plan-report-tr" />
      <h3 className="growth-plan-report-title-custom paper-header">
        <FormattedMessage
          id="extratos.titulo.lista.de.pedidos"
          defaultMessage="Lista de Pedidos"
          description="Lista de Pedidos"
        />
        {/*<span> - </span>*/}
        {/*<FormattedMessage*/}
        {/*id="geral.ciclo"*/}
        {/*defaultMessage="Ciclo {cycle}"*/}
        {/*description="Ciclo"*/}
        {/*values={{*/}
        {/*cycle: props.cycle*/}
        {/*}}*/}
        {/*/>*/}
      </h3>
      <div>
        <table className="growth-plan-report-table-container-second">
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id="geral.pedido"
                  defaultMessage="Pedido"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.pedido.rede"
                  defaultMessage="Pedido Rede"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.origem"
                  defaultMessage="Origem"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.ciclo.pedido"
                  defaultMessage="Ciclo do Pedido"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.pedido.data"
                  defaultMessage="Data do Pedido"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.pontuacao"
                  defaultMessage="Pontuação"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.valor"
                  defaultMessage="Valor"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.lucratividade"
                  defaultMessage="Lucratividade"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.nivel"
                  defaultMessage="Nível"
                  description="Header da coluna"
                />
              </th>
              <th>
                <FormattedMessage
                  id="geral.pedido.status"
                  defaultMessage="Situação do Pedido"
                  description="Header da coluna"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            {props.orders[0].length > 0 && props.orders[0].map((order, index) =>
              <tr key={`order_${index}`}>
                <td>{order.orderNumber}</td>
                <td>{order.orderNumberAtg || ' - '}</td>
                <td> {order.sourceSystemCode ? getOrderOrigin(order.sourceSystemCode, order.channelCode) : 'Rede Natura'} </td>
                <td>{order.orderCycle}</td>
                <td><FormattedDate value={new Date(order.entryOrderDate)} /></td>
                <td>
                  <FormattedMessage
                    id="consultora.canal.pontos"
                    defaultMessage="{points} pts."
                    description="Pontuação"
                    values={{ points: props.numberFormatter(order.totalOrderPoints) }}
                  />
                </td>
                <td>{props.currencyFormatter(order.totalOrderValue)}</td>
                <td>
                  {
                    order.profitability ?

                      <FormattedMessage
                        id="geral.lucratividade.percentual"
                        defaultMessage="{profitabilityPoints}%"
                        description="Lucratividade em pontos percentuais"
                        values={{ profitabilityPoints: props.numberFormatter(order.profitability) }}
                      />
                      : '-'}
                </td>
                <td>
                  {getLevel(order.level)}
                </td>
                <td>{getOrderStatus(order.orderStatus)}</td>
              </tr>
            )}
            {props.orders[1].length > 0 && props.orders[1].map((order, index) =>
              <tr key={`order_${index}`}>
                <td>{order.orderNumber}</td>
                <td>{order.orderNumberAtg}</td>
                <td> {order.sourceSystemCode ? getOrderOrigin(order.sourceSystemCode, order.channelCode) : 'Rede Natura'} </td>
                <td>{order.orderCycle ||  ' - '}</td>
                <td><FormattedDate value={new Date(order.entryOrderDate)} /></td>
                <td>
                  <FormattedMessage
                    id="consultora.canal.pontos"
                    defaultMessage="{points} pts."
                    description="Pontuação"
                    values={{ points: props.numberFormatter(order.totalOrderPoints) }}
                  />
                </td>
                <td>{props.currencyFormatter(order.totalOrderValue)}</td>
                <td>
                  {
                    order.profitability ?

                      <FormattedMessage
                        id="geral.lucratividade.percentual"
                        defaultMessage="{profitabilityPoints}%"
                        description="Lucratividade em pontos percentuais"
                        values={{ profitabilityPoints: props.numberFormatter(order.profitability) }}
                      />
                      : '-'}
                </td>
                <td>
                  {getLevel(order.level)}
                </td>
                <td>{getOrderStatus(order.orderStatus)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CycleOrders.propTypes = {
  // cycle: PropTypes.number.isRequired,
  orders: PropTypes.array.isRequired,
  numberFormatter: PropTypes.func.isRequired,
  currencyFormatter: PropTypes.func.isRequired,
};

export default CycleOrders;
