import React from 'react';
import { PropTypes } from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { routerActions } from 'react-router-redux';
import * as authActions from '../../actions/authActions';

class SignIn extends React.Component {
  // eslint-disable-next-line
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    let { usuario, accessToken, codigoUsuario, uuid } = this.props;
    if(uuid !== undefined && uuid !== '') {
      window.history.pushState("Login", "Gestão Comerical - Central de Relacionamento", "/");
      this.props.actions.decodeUuid(uuid).then(() => {
        this.props.router.push("/home");
      }).catch(() => {
        this.props.router.push("/unauthorized");
      }); // eslint-disable-next-line no-sequences
    } else if(accessToken !== undefined, codigoUsuario !== undefined) {
     this.props.actions.accessByToken(accessToken, codigoUsuario).then((response) => {
       if(response.value.isUser) {
         this.props.router.push("/home");
       } else {
         this.props.router.push("/unauthorized");
       }
     }).catch(() => {
        window.location =  process.env.REACT_APP_URL_HOME;
     });
    } else if (usuario && usuario.isAuthenticated) {
      if(usuario.isUser) {
        this.props.router.push("/home");
      } else {
        this.props.router.push("/unauthorized");
      }
    } else {
      window.location =  process.env.REACT_APP_URL_HOME;
    }
  }

  render() {
    return (<div/>);
  }
}

SignIn.propTypes = {
  usuario: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  redirect: PropTypes.string.isRequired,
  accessToken: PropTypes.string,
  codigoUsuario: PropTypes.string,
  uuid: PropTypes.string,
  replace: PropTypes.func.isRequired,
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    usuario: state.usuario,
    redirect: ownProps.location.query.redirect || '/',
    accessToken: ownProps.location.query.accessToken,
    codigoUsuario: ownProps.location.query.codigoUsuario,
    uuid: ownProps.location.query.uuid,
    replace: routerActions.replace
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
