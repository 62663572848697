import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import RaisedButton from 'material-ui/RaisedButton';
import InfoBox from '../global/InfoBox';
import CommercialStructure from '../global/CommercialStructure';
import globalStyles from '../../styles';

const ReportPageBase = props => {
  const {
    title,
    breadcrumbs,
    person,
    commercialStatus,
    type,
    status,
    intl
  } = props;

  const renderView = type => {
    switch (type) {
      case 'consultant':
        return '';
      case 'custom':
        return (
          <Paper style={globalStyles.paper}>
            {title && (
              <h3 className="product-kit-report-page-title paper-header">
                {title}
              </h3>
            )}
            <Divider />
            {props.children}
            <div style={globalStyles.clear} />
          </Paper>
        );
      default:
        return (
          <Paper style={globalStyles.paper}>
            {title && <div style={globalStyles.title}>{title}</div>}
            {props.children}
            <div style={globalStyles.clear} />
          </Paper>
        );
    }
  };

  return (
    <div>
      {breadcrumbs && (
        <span style={globalStyles.navigation}>{breadcrumbs}</span>
      )}
      <div className="report-page-base-button-container">
        <RaisedButton
          backgroundColor="#7080a0"
          labelColor="#fff"
          label={
            <FormattedMessage
              id="botao.novo.atendimento"
              defaultMessage="Novo Central de Relacionamento"
              description="Novo Central de Relacionamento"
            />
          }
          onTouchTap={props.onNewService}
        />
      </div>
      <div className="report-page-base-paper-group-container">
        <Paper className="report-page-base-paper-container paper-name-custom">
          <InfoBox
            person={person}
            commercialStatus={commercialStatus}
            type={type}
            status={status}
            intl={intl}
          />
          <div style={globalStyles.clear} />
        </Paper>
        <Paper className="report-page-base-paper-container paper-structure-custom">
          <CommercialStructure
            className="report-page-base-paper-header"
            person={person}
          />
          <div style={globalStyles.clear} />
        </Paper>
      </div>
      {renderView(type)}
    </div>
  );
};

ReportPageBase.propTypes = {
  intl: intlShape,
  children: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  breadcrumbs: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  commercialStatus: PropTypes.number,
  person: PropTypes.object.isRequired,
  type: PropTypes.string,
  status: PropTypes.string,
  onNewService: PropTypes.func.isRequired
};

export default ReportPageBase;
