import * as types from './actionTypes';
import productKitApi from '../api/productKitApi';

export function getListProductKit(person, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_LIST_PRODUCT_KIT, payload: productKitApi.getListProductKit(person, usuario) });
  };
}

export function getProductKit(productCode, cycle, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_PRODUCT_KIT, payload: productKitApi.getProductKit(productCode, cycle, usuario) });
  };
}
