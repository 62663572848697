import request from 'superagent';

const serviceApi = process.env.REACT_APP_SERVICE_URL_API;
class reportApi {
  static getTracking = (user, params) => {
    return new Promise((resolve, reject) => {
      request.get(`${serviceApi}/leader-payment/${params.personCode}`)
        .set('Accept', 'application/json')
        .set('access_token', user.accessToken)
        .set('client_id', user.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .query({ cycle: params.cycle })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));
            resolve(res.body);
        });
    });
  };

  static getExtract = (user, params) => {
    return new Promise((resolve, reject) => {
      request.get(`${serviceApi}/remunerationConsult`)
        .set('Accept', 'application/json')
        .set('access_token', user.accessToken)
        .set('client_id', user.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .query({
          'country': params.country,
          'businessModel': params.businessModel,
          'parentStructureLevel': params.parentStructureLevel,
          'cycle': params.cycle,
          'structureLevel': params.structureLevel,
          'leaderCode': params.leaderCode,
          'paymentId': params.paymentId
        })


        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));
          resolve(res.body);
        });
    });
  };

  static getPayments = (user, params) => {
    return new Promise((resolve, reject) => {

      request.get(`${serviceApi}/getPayments`)
        .set('Accept', 'application/json')
        .set('access_token', user.accessToken)
        .set('client_id', user.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .query({
          'country': params.country,
          'business_model': params.businessModel,
          'parent_structure_level': params.parentStructureLevel,
          'cycle': params.cycle,
          'structure_level': params.structureLevel,
          'group_leader_code': params.leaderCode
        }).end((err, res) => {
      if (err)
        reject(Object.assign({}, err.response));
      resolve(res.body);
    });
   });
  }
}

export default reportApi;
