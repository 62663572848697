import * as types from '../actions/actionTypes';
import _ from 'lodash/fp';

const initialState = {
    kitMaterialCycle: [],
    statusMaterialCycle: [],
    statusMaterialCycleHistoric: [],
    detailsMaterialCycle: [],
    modalOpen: false,
    order: null
};

const resetFields = (state, payload) => {
  let newFields = { ...state };
  if (Array.isArray(payload) && payload.length > 0)
    Object.values(payload).forEach(fieldName => {
      newFields[fieldName] = initialState[fieldName];
    });
  else if (!_.isEmpty(payload))
    newFields[payload] = initialState[payload];
  else
    newFields = { ...initialState };
  return newFields;
};

const checkArrayIsNull = (array) => {
  let emptyCheck = true;
  array.forEach((item)=>{
    if (item != null)
      emptyCheck = false;
  });
  return emptyCheck;
}

export default function leaderKitReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.LOAD_STATUS_MATERIAL_CYCLE}_FULFILLED`:
      return {
        ...state,
        statusMaterialCycle: (payload[0]) ? [payload[0]] : initialState.statusMaterialCycle,
        statusMaterialCycleHistoric: (checkArrayIsNull(payload)) ? initialState.statusMaterialCycleHistoric : payload
      };
    case `${types.LOAD_KIT_MATERIAL_CYCLE}_FULFILLED`:
      return { ...state, kitMaterialCycle: payload };
    case types.CHANGE_FLAG:
      return { ...state, modalOpen: payload.modalOpen, order: payload.order};
    case `${types.LOAD_DETAILS_MATERIAL_CYCLE}_FULFILLED`:
      return { ...state, detailsMaterialCycle: payload };
    case `${types.GET_DIGITAL_STORE_DETAILS}_FULFILLED`:
      return { ...state, digitalStore: payload };
    case types.RESET_FIELDS:
      return resetFields(state, payload);
    default:
      return state;
  }
}
