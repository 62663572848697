import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
//import FontIcon from 'material-ui/FontIcon';
//import { green500, red500 } from 'material-ui/styles/colors';
import ReportPageBase from '../common/ReportPageBase';
import Medal from './widgets/Medal';
import * as medalActions from '../../actions/medalActions';
import * as growthPlanActions from '../../actions/growthPlanActions';
import * as personActions from '../../actions/personActions';
import * as consultantActions from '../../actions/consultantActions';


class MedalReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lider: false
    };

    if (!this.props.params.code || !parseInt(this.props.params.code))
      this.props.router.push('/');
  }
  componentDidMount() {
    let {usuario} = this.props;
    this.props.personActions.getPerson(this.props.params.code, usuario)
      .then(_ => {
        // let result = _.value.dc_roles;
          this.props.personActions.getPerson(this.props.params.code, usuario);
          this.props.medalActions.getMedal(this.props.params.code, usuario);
          this.props.consultantActions.getDigitalStoreDetails(usuario, _.value);
        });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  render() {
    let { person, medal, consultant = {}, intl } = this.props;
    let { lider } = this.state;
    const digitalStore = consultant.digitalStore || {}
    return (
      <div>
        <ReportPageBase
          person={person}
          onNewService={this.handleNewService}
          commercialStatus={digitalStore.commercialStatus}
          intl={intl}
        >
          {lider ?
            <div>
              <h3 className="growth-plan-report-title-custom paper-header">
                <FormattedMessage
                  id="mySucess.missoes.periodo"
                  defaultMessage="Historico de Medalha"
                  description="Historico de Medalha"
                />
              </h3>
              <div className="homepage-messenger-container">
                <div>
                  <FormattedMessage
                    id="mySucess.status"
                    defaultMessage="Status."
                    description="Status."
                  />
                </div>
              </div>
            </div>
            :
            <div>
              <Medal
                medal={medal.data}
                {...this.props}
              />
            </div>
          }

        </ReportPageBase>
      </div>
    );
  }
}

MedalReport.propTypes = {
  intl: intlShape,
  medal: PropTypes.object.isRequired,
  medalActions: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  growthPlan: PropTypes.object.isRequired,
  growthPlanActions: PropTypes.object.isRequired,
  consultantActions: PropTypes.object.isRequired,
  params: PropTypes.object,
  consultant: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    growthPlan: state.growthPlan,
    person: state.person,
    medal: state.medal,
    consultant: state.consultant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    growthPlanActions: bindActionCreators(growthPlanActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    medalActions: bindActionCreators(medalActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MedalReport));
