import * as types from '../actions/actionTypes';

let initialState = {
  "navigation": []
};

export default function dynamicNavigationReducer(state = initialState, action) {
  if(localStorage.getItem('navigation')){
    state = { navigation: JSON.parse(localStorage.getItem('navigation')) };
  }
  switch(action.type) {
    case types.GET_APP_NAVIGATION_DATA:
      return {...state, navigation: action.payload};
    default:
      return state;
  }
}
