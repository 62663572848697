import * as types from '../actions/actionTypes';
import _ from 'lodash/fp'

/* eslint-disable eqeqeq */
const initialState = {
  fields: {
    cycle: '',
    cycleChoosen: '',
    partner: '',
    period: '',
    typePerson: '',
    personCode: ''
  },
  lists: {
    tracking: [],
    extract: [],
  },
  filters: {
    filterTracking: [],
    selectedFilterPayment: 0
  },
  selectedPayment: []
};

function setField(payload, state) {
  return _.set('fields.' + payload.path, payload.value, state);
}



const resetFields = (state, payload) => {
  let newFields = { ...state.fields };
  if (Array.isArray(payload) && payload.length > 0)
    Object.values(payload).forEach(fieldName => {
      newFields[fieldName] = initialState.fields[fieldName];
    });
  else if (!_.isEmpty(payload))
    newFields[payload] = initialState.fields[payload];
  else
    newFields = { ...initialState.fields };
  return newFields;
};

const resetLists = (state, payload) => {
  let newLists = { ...state.lists };
  if (Array.isArray(payload) && payload.length > 0)
    Object.values(payload).forEach(listName => {
      newLists[listName] = initialState.lists[listName];
    });
  else if (!_.isEmpty(payload))
    newLists[payload] = initialState.lists[payload];
  else
    newLists = { ...initialState.lists };
  return newLists;
};

function trackingSegment(payload) {
  let array = [];
  if (Array.isArray(payload) && payload.length > 0) {
    // eslint-disable-next-line
    payload.map(item => {
      array.push([
        {
          dicas_mei: item.dicas_mei === "" ? "Não" : "Sim",
          tipo_pessoa: item.tipo_pessoa || "-",
          per_ciclo_ini: item.per_ciclo_ini || "-",
          per_ciclo_fim: item.per_ciclo_fim || "-",
          field1: item.valor_nf,
          field2: item.pedido || "Não consta",
          field3: item.numero_frs || "Não consta",
          field4: item.ciclo,
          field5: "71.673.990/0001-77",
          field6: "10.05 . Agenciamento e Representação Comercial.",
          field7: item.cpom || "Não consta",
          field8: item.field8 || "Não consta",
        },
        {
          field9: item.numero_nf != "" ? "Sim" : "Não",
          field10: item.valor_nf == 0 ? "R$: 0" : "R$: " + item.valor_nf,
          field11: "R$ " + (parseFloat(item.valor_juros) + parseFloat(item.valor_multa)),
          field12: item.valor_inss == 0 ? "R$: 0" : "R$: " + item.valor_inss,
          field13: item.valor_ir == 0 ? "R$: 0" : "R$: " + item.valor_ir,
          field14: item.valor_iss == 0 ? "R$: 0" : "R$: " + item.valor_inss,
          field15: item.valor_adiantamento == 0 ? "R$: 0" : "R$: " + item.valor_adiantamento,
          field216: item.data_ag_ad === "00000000" ? "-" : item.data_ag_ad.substr(6, 8) + "/" + item.data_ag_ad.substr(4, 2) + "/" + item.data_ag_ad.substr(0, 4),
          field217: item.data_pag_ad === "00000000" ? "-" : item.data_pag_ad.substr(6, 8) + "/" + item.data_pag_ad.substr(4, 2) + "/" + item.data_pag_ad.substr(0, 4),
          field218: item.status_pag_ad === "" ? "-" : item.status_pag_ad,
          field219: item.valor_iss == 0 ? "R$: 0" : "R$: " + item.valor_iss,
          fieldAvaliabe: item.numero_nf
        },
        {
          field16: item.data_ag_nf === "00000000" ? "" : item.data_ag_nf.substr(6, 8) + "/" + item.data_ag_nf.substr(4, 2) + "/" + item.data_ag_nf.substr(0, 4),
          fieldAvaliabe: item.data_ag_nf,
          field220: item.numero_nf != "" ? item.numero_nf : "-",
        },
        {
          field17: item.data_pag_nf === "00000000" ? "" : item.data_pag_nf.substr(6, 8) + "/" + item.data_pag_nf.substr(4, 2) + "/" + item.data_pag_nf.substr(0, 4),
          field18: item.cod_banco && item.cod_agencia && item.cod_conta !== "" ? `${item.cod_banco} / ${item.cod_agencia} / ${item.cod_conta}` : "",
          field419: item.valor_pago_res == 0 ? "R$: 0" : "R$: " + item.valor_pago_res,
          field420: item.status_pag_res === "" ? "-" : item.status_pag_res,
          field421: item.data_ag_res === "00000000" ? "-" : item.data_ag_res.substr(6, 8) + "/" + item.data_ag_res.substr(4, 2) + "/" + item.data_ag_res.substr(0, 4),
          field422: item.data_pag_res === "00000000" ? "-" : item.data_pag_res.substr(6, 8) + "/" + item.data_pag_res.substr(4, 2) + "/" + item.data_pag_res.substr(0, 4),

        },
        {
          errors: item.errors,
        }
      ])
    })
  } else {
    array = [];
  }

  return array;
}
export default function reportReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.SET_FIELD:
      return setField(payload, state);
    case types.SET_SELECTED_FILTER:
      return { ...state, filters: { ...state.filters, selectedFilterPayment: payload }, selectedPayment: state.lists.tracking[payload], };
    case `${types.GET_EXTRACT}_PENDING`:
      return { ...state, lists: { ...state.lists, extract: {} } };
    case `${types.GET_EXTRACT}_FULFILLED`:
      return {
        ...state,
        lists: { ...state.lists, extract: payload }
      };
    case `${types.GET_TRACKING}_PENDING`:
      return { ...state, lists: { ...state.lists, tracking: [] }, filters: { ...state.filters, filterTracking: [], selectedFilterPayment: 0 }, selectedPayment: [] };
    case `${types.GET_TRACKING}_FULFILLED`:
      return {
        ...state,
        lists: { ...state.lists, tracking: trackingSegment(payload) },
        fields: { ...state.fields, partner: payload.field19, period: payload.field20, typePerson: payload.tipo_pessoa },
        filters: {
          ...state.filters, filterTracking: (payload || []).map((obj, index) => ({
            valuePayment: obj.valor_nf,
            indexPayment: index,
            paymentId: parseInt(obj.id_gc),
            datePayment: obj.apura_remunera != "00.00.0000" ? obj.apura_remunera.substr(0, 2) + "/" + obj.apura_remunera.substr(3, 2) + "/" + obj.apura_remunera.substr(6, 10) : null
          }))
        },
        selectedPayment: trackingSegment(payload)[0]
      };
    case types.RESET_FIELDS:
      return { ...state, fields: resetFields(state, payload), filters: { ...state.filters, filterTracking: [] } };
    case `${types.GET_PAYMENTS}_PENDING`:
      return { ...state, lists: { ...state.lists, tracking: [] }, filters: { ...state.filters, filterTracking: [], selectedFilterPayment: 0 }, selectedPayment: [] };
    case `${types.GET_PAYMENTS}_FULFILLED`:
      return {
        ...state,
        fields: { ...state.fields, partner: payload.field19, period: payload.field20, typePerson: payload.tipo_pessoa },
        filters: {
          ...state.filters, filterTracking: (payload || []).map((obj, index) => ({
            valuePayment: obj.valor_pagamento,
            indexPayment: index,
            paymentId: parseInt(obj.id_pagamento),
            datePayment: obj.data_pagamento != "00000000" ? obj.data_pagamento.substr(8, 2) + "/" + obj.data_pagamento.substr(5, 2) + "/" + obj.data_pagamento.substr(0, 4) : null
          }))
        },
        selectedPayment: state.filters.filterTracking[0]
      };
    case types.RESET_LISTS:
      return { ...state, lists: resetLists(state, payload), selectedPayment: {} };

    default:
      return state;
  }
}
