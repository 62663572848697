import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { Router, useRouterHistory } from "react-router";
import createBrowserHistory from "history/lib/createBrowserHistory";
import { syncHistoryWithStore } from "react-router-redux";
import getRoutes from "./routes";
import * as externalAppsActions from "./actions/externalAppsActions";
import * as dynamicNavigationActions from "./actions/dynamicNavigationActions";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../node_modules/jquery/dist/jquery.js";
import "../node_modules/pixeden-stroke-7-icon/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css";
import "./styles/styles.css";
import injectTapEventPlugin from "react-tap-event-plugin";
injectTapEventPlugin();

const store = configureStore();
const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: process.env.PUBLIC_PATH
});
const history = syncHistoryWithStore(browserHistory, store);

async function getRender() {
  await store.dispatch(dynamicNavigationActions.getAppNavigationData());
  await store.dispatch(externalAppsActions.getExternalNavigationData());
  getReactDom();
}

function getReactDom() {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history} routes={getRoutes(store)} />
    </Provider>,
    document.getElementById("root")
  );
}

getRender();
