export const indicators = {
  INATIVIDADE: 2,
  VOLUME_DE_PONTOS: 7,
  FATURAMENTO_REDE: 372,
  ESPACO_DIGITAL_ABERTO: 409,
  TOTAL_DE_PONTOS: 370,
  VOLUME_PRESENCIAL: 86,
  VOLUME_DIGITAL: 368,
  ATIVIDADE: [84, 91],
  ATIVIDADE_ATIVAS_FREQUENTE: 91,
  ATIVIDADE_ATIVAS: 28,
  ATIVIDADE_CADASTRO: 32,
  CADASTRO: 32,
  ATIVIDADE_SB: 403,
  ATIVAS_SEMENTE: 393,
  CADASTRO_ABERTURA_SEMENTE: 398,
  ATIVAS_BRONZE: 394,
  CADASTRO_ABERTURA_BRONZE: 399,
  SALDO: 392,
  SALDO_INICIO: 63,
  SALDO_CESSADAS: 74,
  SALDO_A_CESSAR: 57,
  TOTAL_CONSULTORAS: 408,
  PENETRACAO_CANAL: 411,
  ESPACOS_ABERTOS: 413,
  ATIVIDADE_DIGITAL: 415,
  ATIVIDADE_PRESENCIAL: 416,
  ATIVIDADE_AMBOS: 417,
  ATIVAS_PRATA_MAIS: 424,
  424: "Ativas Prata Mais",
  28: "Atividade Ativas",
  86: "Volume de Pontos",
  91: "Atividade"
};
