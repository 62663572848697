import * as types from '../actions/actionTypes';

const initialState = {};

export default function personReducer(state = initialState, action) {
  let { type, payload } = action;
  let id_consult = null;
  if(localStorage.getItem('personIdNaturaWeb')){
    id_consult = JSON.parse(localStorage.getItem('personIdNaturaWeb'));
  }
  switch (type) {
    case `${types.LOAD_PERSON}_PENDING`:
      return {...initialState};
    case `${types.LOAD_PERSON}_FULFILLED`:
      return {...payload};
    case `${types.LOAD_PERSON_THERMOMETER}_PENDING`:
      return {...state, thermometer: []};
    case `${types.LOAD_PERSON_THERMOMETER}_FULFILLED`:
      return {...state, thermometer: payload};
    case `${types.LOAD_PERSON_LEADER_THERMOMETER}_PENDING`:
      return {...state, leaderThermometer: []};
    case `${types.LOAD_PERSON_LEADER_THERMOMETER}_FULFILLED`:
      return {...state, leaderThermometer: payload};
    case `${types.LOAD_PERSON_SCORE_CYCLES}_PENDING`:
      return {...state, scoreCycles: []};
    case `${types.LOAD_PERSON_SCORE_CYCLES}_FULFILLED`:
      return {...state, scoreCycles: payload};
    case `${types.LOAD_PERSON_CYCLE_ORDERS}_PENDING`:
      return {...state, cycleOrders: []};
    case `${types.LOAD_PERSON_CYCLE_ORDERS}_FULFILLED`:
      return {...state, cycleOrders: payload};
    case `${types.LOAD_PERSON_MONTHLY_PAYMENT}_PENDING`:
      return {...state, monthlyPayment: {}};
    case `${types.LOAD_PERSON_MONTHLY_PAYMENT}_FULFILLED`:
      return {...state, monthlyPayment: payload};
	case types.CLEAR_PERSON_CYCLE_ORDERS:
      return {...state, cycleOrders: []};
    default:
      return {...state, id_consult};
  }
}
