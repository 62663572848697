import isPromise from '../utils/isPromise';
import isJSON from '../utils/isJSON';
import oneOfType from '../utils/oneOfType';
import * as types from '../actions/actionTypes';

const urlHome = process.env.REACT_APP_URL_HOME;

export default function globalErrorMiddleware() {
  return next => action => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }

    /**
     * Because it iterates on an array for every async action, this
     * oneOfType function could be expensive to call in production.
     * Another solution would would be to include a property in `meta`
     * and evaluate that property.
     *
     * if (action.meta.globalError === true) {
     *   // handle error
     * }
     *
     * The error middleware serves to dispatch the initial pending promise to
     * the promise middleware, but adds a `catch`.
     */
    if (oneOfType(action.type, types)) {

      // Dispatch initial pending promise, but catch any errors
      return next(action).catch(error => {
        if (process.env.NODE_ENV === 'development') {
          let message = (error.response && error.response.text) || error.message || error.text;
          if (error.response && isJSON(error.response.text))
            message = JSON.parse(error.response.text) || error.response.text;
          //eslint-disable-next-line
          console.error(`${action.type} caught at middleware with reason: `, message);
        }

        if (error.status === 401 || error.status === 403) {
          localStorage.removeItem("usuario");
          window.location.href = `${urlHome}/signOut`;
        }

        return error;
      });
    }

    return next(action);
  };
}
