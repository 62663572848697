import * as types from '../actions/actionTypes';

const initialState = {
  detailsAccount: {},
};

export default function accountReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.GET_ACCOUNT}_PENDING`:
      return {...state, detailsAccount: {} }
    case `${types.GET_ACCOUNT}_FULFILLED`:
      return { ...state, detailsAccount: payload }
    default:
      return state;
  }
}
