import React from 'react';
import {PropTypes} from 'prop-types';
import { FormattedMessage } from 'react-intl';

const BusinessLeader = (props) => {
  return (
    <div className="growth-plan-report-container">
      <div className="growth-plan-report-tr"/>
      <h3 className="growth-plan-report-title paper-header">
        <FormattedMessage
          id="extratos.titulo.periodo.de.crescimento"
          defaultMessage="Período de Crescimento"
          description="Período de Crescimento"
        />
        <span> - </span>
        <FormattedMessage
          id="extratos.titulo.ano.ciclos"
          defaultMessage="{year}: Ciclo {cycleStart} até {cycleEnd}"
          description="Ano: Ciclo de início até Ciclo de término"
          values={{
            year: `${props.year}`,
            cycleStart: `${props.cycleStart}`,
            cycleEnd: `${props.cycleEnd}`
          }}
        />
      </h3>
    </div>
  );
};

BusinessLeader.propTypes = {
  person: PropTypes.object.isRequired,
  leaderLevels: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
  cycleStart: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  cycleEnd: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  startingPointsFormatter: PropTypes.func.isRequired,
  remainingPointsFormatter: PropTypes.func.isRequired,
  getUpgradeStatus: PropTypes.func.isRequired,
  getComplianceStatus: PropTypes.func.isRequired,
  getChallengeInfo: PropTypes.func.isRequired
};

export default BusinessLeader;
