import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

class LeaderKitStatusDialog extends React.Component {
    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleCloseConfirm = () => {
        const { props, row } = this.props;
        if (row.ZZCAMPO_MOD === 'ATIVADO') {
            props.actions.getStatusMaterialCycle('D', props.person, props.usuario);
            this.setState({ open: false });
        } else if (row.ZZCAMPO_MOD === 'DESATIVADO') {
            props.actions.getStatusMaterialCycle('M', props.person, props.usuario);
            this.setState({ open: false });
        }
    };

    getTitle = (title) => {
        return <div className="extratos-titulo-lista-pedidos">{title}</div>;
    };

    render() {
        const { label, title, body } = this.props;

        const actions = ([
            <FlatButton
                label={
                    <FormattedMessage
                        id="botao.cancelar"
                        defaultMessage="Cancelar"
                        description="Botão Cancelar"
                    />
                }
                primary={true}
                onClick={this.handleClose}
                key={1}
            />,
            <FlatButton
                label={
                    <FormattedMessage
                        id="botao.confirmar"
                        defaultMessage="Confirmar"
                        description="Botão Confirmar"
                    />
                }
                primary={true}
                keyboardFocused={true}
                onClick={this.handleCloseConfirm}
                key={2}
            />
        ]);

        return (
            <div>
                <FlatButton label={label} onClick={this.handleOpen} className="flat-button-weight" />
                <Dialog
                    title={this.getTitle(title)}
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onClick={this.handleClose}
                >
                    {body}
                </Dialog>
            </div>
        );
    }
}

LeaderKitStatusDialog.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    row: PropTypes.any,
    label: PropTypes.any,
    props: PropTypes.any
};

export default LeaderKitStatusDialog;
