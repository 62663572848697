import React from 'react';
import { PropTypes } from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as authActions from '../../actions/authActions';

class SignOut extends React.Component {
  // eslint-disable-next-line
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    this.props.actions.logout().then(() => {
      window.location =  `${process.env.REACT_APP_URL_HOME}/signOut`;
    });
  }

  render() {
    return (<div/>);
  }
}

SignOut.propTypes = {
  actions: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
