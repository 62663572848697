import React from 'react';
import { PropTypes } from 'prop-types';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import globalStyles from '../../styles';

const PageBase = (props) => {

  const {title, breadcrumbs} = props;

  return (
    <div>
      {breadcrumbs && <span style={globalStyles.navigation}>{breadcrumbs}</span>}

      <Paper style={globalStyles.paper}>
        {title && <h3 className="pagebase-title-homepage">{title}</h3>}

        <Divider/>
        {props.children}

        <div style={globalStyles.clear}/>

      </Paper>
    </div>
  );
};

PageBase.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  children: PropTypes.element
};

export default PageBase;
