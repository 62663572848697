import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage, FormattedDate } from "react-intl";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from 'moment';

class MySucess extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  handleRowClick = (row) => {

    this.props.router.push(`/extratos/meusucessodetalhe/${this.props.params.code}/${row.num_periodo}`);
  };

  render() {
    let tableOtions = {
      onRowClick: this.handleRowClick
    };
    const getFormattedDate = (date) => {
      const dts = new Date(date).toISOString().substring(0,10)
        return <FormattedDate value={moment(dts,"YYYY-MM-DD").toLocaleString()} />
    };

    const getFormattedStatus = (date) => {
      var data_1 = new Date(date);
      if (data_1 >= new Date()) {
        return <FormattedMessage
          id="mySucess.em.aberto"
        />
      } else {
        return <FormattedMessage
          id="mySucess.finalizado"
        />
      }

    };
    let { sucess } = this.props;
    // const options = {
    //   expandRowBgColor: 'rgb(242, 255, 163)'
    // };
    return (
      <div className="elegibility-report-container">
        <div className="elegibility-report-container">
          <h4 className="elegibility-report-title">
            <FormattedMessage
              id="mySucess.missoes.periodo"
              defaultMessage="MISSÕES POR PERIODO"
              description="MISSÕES POR PERIODO"
            />
          </h4>
          <div>
            <BootstrapTable
              containerClass="elegibility-report-page-table-container"
              headerContainerClass="elegibility-report-page-table-header"
              bodyContainerClass="elegibility-report-page-table-body"
              data={sucess.data}
              bordered={false}
              striped
              hover
              pagination
              options={tableOtions} searchPlaceholder="Buscar nos resultados"
            >
              <TableHeaderColumn width="20%" row='0' rowSpan='2' isKey dataField='data_fim' dataFormat={getFormattedStatus}  >
                <FormattedMessage
                  id="mySucess.status"
                  defaultMessage="STATUS"
                  description="STATUS"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" row='0' rowSpan='2' dataField='num_periodo'  >
                <FormattedMessage
                  id="mySucess.periodo"
                  defaultMessage="Periodo"
                  description="Periodo"
                /></TableHeaderColumn>
              <TableHeaderColumn row='0' colSpan='2' >
                <FormattedMessage
                  id="mySucess.apuracao"
                  defaultMessage="apuracao"
                  description="apuracao"
                /></TableHeaderColumn>
              <TableHeaderColumn row='1' width="15%" dataField='data_inicio' dataFormat={getFormattedDate} >De</TableHeaderColumn>
              <TableHeaderColumn row='1' width="15%" dataField='data_fim' dataFormat={getFormattedDate} >Ate</TableHeaderColumn>

              <TableHeaderColumn row='0' colSpan='2' >
                <FormattedMessage
                  id="mySucess.ciclo"
                  defaultMessage="Ciclo"
                  description="Ciclo"
                /></TableHeaderColumn>
              <TableHeaderColumn row='1' width="5%" dataField='ciclo_inicio' >De</TableHeaderColumn>
              <TableHeaderColumn row='1' width="5%" dataField='ciclo_fim' >Ate</TableHeaderColumn>
              <TableHeaderColumn width="15%" row='0' rowSpan='2' dataField='ranking' >
                <FormattedMessage
                  id="mySucess.ranking.setor."
                  defaultMessage="Ranking do Setor"
                  description="Ranking do Setor"
                /></TableHeaderColumn>
              <TableHeaderColumn width="15%" row='0' rowSpan='2' dataField='passos' >
                <FormattedMessage
                  id="mySucess.passos"
                  defaultMessage="Passos"
                  description="Passos"
                /></TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}

MySucess.propTypes = {
  sucess: PropTypes.array.isRequired,
  params: PropTypes.object,
  router: PropTypes.object
};

export default MySucess;
