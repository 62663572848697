import request from 'superagent';

const SERVICE_URL_API  = process.env.REACT_APP_SERVICE_URL_API;

class accountApi {
  static getAccount = (usuario, personId) => {
    return new Promise((resolve, reject) => {
      request.get(`${SERVICE_URL_API}/digitalAccount/${personId}`)
        .set({
          'client_id': usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID,
          'access_token': usuario.accessToken
        })
        .then(res => {
          if(res.status >= 200 && res.status <= 207) {
            resolve(res.body);
          }
        })
        .catch(err => {
          reject(Object.assign({}, err.response));
        });
    });
  };

}

export default accountApi;
