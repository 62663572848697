import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';
import FiberManualRecord from 'material-ui/svg-icons/av/fiber-manual-record';
import FontIcon from 'material-ui/FontIcon';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { green500, red500 } from 'material-ui/styles/colors';
import ReportPageBase from '../common/ReportPageBase';
import * as consultantActions from '../../actions/consultantActions';

import IconButton from 'material-ui/IconButton';
import KeyboardArrowLeft from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import KeyboardArrowRight from 'material-ui/svg-icons/hardware/keyboard-arrow-right';

import Alert from '../global/Alert';

import BeautyConsultant from './widgets/BeautyConsultant';
import BusinessLeader from './widgets/BusinessLeader';
import SalesScore from './widgets/SalesScore';
import CyclesScore from './widgets/CyclesScore';
import CycleOrders from './widgets/CycleOrders';

import _ from 'lodash/fp';
import { Element, scroller } from 'react-scroll';

import * as growthPlanActions from '../../actions/growthPlanActions';
import * as personActions from '../../actions/personActions';

class GrowthPlanReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 0,
      currentRangeIndex: 0
    };

    if (!this.props.params.code || !parseInt(this.props.params.code))
      this.props.router.push('/');
  }

  componentDidMount() {
    let { usuario } = this.props;

    this.props.personActions.getPerson(this.props.params.code, usuario).then((result) => {
      this.props.consultantActions.getDigitalStoreDetails(usuario, result.value);
    })
  }

  componentDidUpdate(prevProps) {
    let { usuario } = this.props;
    let { person } = prevProps;
    if (person.currentCycle && !this.props.growthPlan.cycleRanges)
      this.props.growthPlanActions.getCycleRanges(person.currentCycle, usuario);

    if (!this.props.growthPlan.selectedCycleRange &&
      this.props.growthPlan.cycleRanges && this.props.growthPlan.cycleRanges.length > 0
    ) {
      let { growthPlan: { cycleRanges } } = this.props;
      this.setState({ currentRangeIndex: cycleRanges.length - 1 });
      this.props.growthPlanActions.selectCycleRange(cycleRanges[cycleRanges.length - 1]);
    }

    if (!person.thermometer && !this.props.person.thermometer && this.props.growthPlan.selectedCycleRange)
    //  { this.props.personActions.getThermometer(person, this.props.growthPlan.selectedCycleRange, usuario);}
    if (!person.leaderThermometer && !this.props.person.leaderThermometer && this.props.growthPlan.selectedCycleRange)
      // {this.props.personActions.getLeaderThermometer(person, this.props.growthPlan.selectedCycleRange, usuario);}
    if (!person.scoreCycles && !this.props.person.scoreCycles && this.props.growthPlan.selectedCycleRange) {
      // this.props.personActions.getScoreCycles(person, this.props.growthPlan.selectedCycleRange, usuario);
    }
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  handleSelectCycleRange = (event, index, value) => {
    let { person, usuario, growthPlan: { cycleRanges } } = this.props;

    value = JSON.parse(value);
    let selectedCycleRange = {
      year: value.year ? parseInt(value.year) : null,
      start: value.start ? parseInt(value.start) : null,
      end: value.end ? parseInt(value.end) : null
    };

    let currentRangeIndex = cycleRanges.findIndex(range => JSON.stringify(range) === JSON.stringify(selectedCycleRange));
    this.setState({ currentRangeIndex: currentRangeIndex > -1 ? currentRangeIndex : 0 });
    this.props.personActions.clearCycleOrders();
    this.props.personActions.getScoreCycles(person, selectedCycleRange, usuario);
    this.props.personActions.getThermometer(person, selectedCycleRange, usuario);
    this.props.personActions.getLeaderThermometer(person, selectedCycleRange, usuario);
    this.props.growthPlanActions.selectCycleRange(selectedCycleRange);
  };

  handlePreviousRange = () => {
    let { growthPlan: { cycleRanges, selectedCycleRange } } = this.props;
    let currentRangeIndex = cycleRanges.findIndex(range => JSON.stringify(range) === JSON.stringify(selectedCycleRange));
    let previousRangeIndex = currentRangeIndex - 1 > - 1 ? currentRangeIndex - 1 : 0;
    this.props.personActions.clearCycleOrders();
    this.handleSelectCycleRange(null, previousRangeIndex, JSON.stringify(cycleRanges[previousRangeIndex]));
  };

  handleNextRange = () => {
    let { growthPlan: { cycleRanges, selectedCycleRange } } = this.props;
    let currentRangeIndex = cycleRanges.findIndex(range => JSON.stringify(range) === JSON.stringify(selectedCycleRange));
    let nextRangeIndex = currentRangeIndex + 1 < cycleRanges.length ? currentRangeIndex + 1 : cycleRanges.length - 1;
    this.props.personActions.clearCycleOrders();
    this.handleSelectCycleRange(null, nextRangeIndex, JSON.stringify(cycleRanges[nextRangeIndex]));
  };

  getYear = (person) => {
    let now = new Date();
    let { growthPlan } = this.props;

    return growthPlan.selectedCycleRange && growthPlan.selectedCycleRange.year ? growthPlan.selectedCycleRange.year :
      person.thermometer // eslint-disable-next-line
      && person.thermometer.currentPlan // eslint-disable-next-line
      && parseInt(person.thermometer.currentPlan.growthPlanYear) // eslint-disable-next-line
      || now.getFullYear();
  };

  getPeriodCycle = (whichCycle = 'start') => {
    let { person, growthPlan } = this.props;

    // eslint-disable-next-line default-case
    switch (whichCycle) {
      case 'start':
        return growthPlan.selectedCycleRange && growthPlan.selectedCycleRange.start ?
          parseInt(growthPlan.selectedCycleRange.start / 100) < parseInt(growthPlan.selectedCycleRange.end / 100) ?
            growthPlan.selectedCycleRange.start :
            growthPlan.selectedCycleRange.start.toString().substr(growthPlan.selectedCycleRange.start.toString().length - 2) :
          person.thermometer && person.thermometer.periodStartCycle ?
            person.thermometer.periodStartCycle.toString().substr(person.thermometer.periodStartCycle.toString().length - 2) : 1;
      case 'end':
        return growthPlan.selectedCycleRange && growthPlan.selectedCycleRange.end ?
          parseInt(growthPlan.selectedCycleRange.start / 100) < parseInt(growthPlan.selectedCycleRange.end / 100) ?
            growthPlan.selectedCycleRange.end :
            growthPlan.selectedCycleRange.end.toString().substr(growthPlan.selectedCycleRange.end.toString().length - 2) :
          person.thermometer && person.thermometer.periodEndCycle ?
            person.thermometer.periodEndCycle.toString().substr(person.thermometer.periodEndCycle.toString().length - 2) : 10;
    }
  };

  getLevels = (person, whichPlan) => {
    return person[whichPlan] &&
      person[whichPlan].currentPlan &&
      person[whichPlan].currentPlan.levels &&
      person[whichPlan].currentPlan.levels.length > 0 ? person[whichPlan].currentPlan.levels : [];
  };

  getSaleScore = (whichScore, person) => {
    if (!person.thermometer)
      return 0;

    let { thermometer } = person;

    // eslint-disable-next-line default-case
    switch (whichScore) {
      case 'periodDirectSales':
        return thermometer.periodDirectSales >= 0 && this.numberFormatter(thermometer.periodDirectSales);
      case 'periodNaturaNetwork':
        return thermometer.periodNaturaNetwork >= 0 && this.numberFormatter(thermometer.periodNaturaNetwork);
      case 'periodBotBov':
        return thermometer.periodBotBov >= 0 && this.numberFormatter(thermometer.periodBotBov);
      case 'periodDistributor':
        return thermometer.periodDistributor >= 0 && this.numberFormatter(thermometer.periodDistributor);
      case 'periodTotalPoints':
        return thermometer.periodTotalPoints >= 0 && this.numberFormatter(thermometer.periodTotalPoints);
    }
  };

  getCyclesStar = (nm_cycle, person) => {
    if (!person.scoreCycles || !person.scoreCycles.upgradeLevel)
      return '';

    nm_cycle = parseInt(nm_cycle.toString().substr(nm_cycle.toString().length - 2));

    let levels = this.getLevels(person, 'thermometer');
    let leaderLevels = this.getLevels(person, 'leaderThermometer');
    let upgradeLevel = person.scoreCycles.upgradeLevel.filter(level => level.nm_cycle === nm_cycle).map(upLevel => {
      let level_id = parseInt(upLevel.level_id);
      let level = null;

      if (level_id > 5 && leaderLevels.length > 0)
        level = leaderLevels.filter(lvl => parseInt(lvl.levelId) === level_id).pop();
      else
        level = levels.filter(lvl => parseInt(lvl.levelId) === level_id).pop();

      return { ...upLevel, ...level }
    }).pop();

    // eslint-disable no-mixed-operators
    return upgradeLevel && // eslint-disable-next-line no-mixed-operators
      upgradeLevel.color_r && // eslint-disable-next-line no-mixed-operators
      upgradeLevel.color_g && // eslint-disable-next-line no-mixed-operators
      upgradeLevel.color_b && // eslint-disable-next-line no-mixed-operators
      upgradeLevel.color_a &&
      <div>
        <FiberManualRecord color={`rgba(${upgradeLevel.color_r}, ${upgradeLevel.color_g}, ${upgradeLevel.color_b}, ${upgradeLevel.color_a})`} />
        {/* eslint-disable-next-line no-mixed-operators */}
      </div> || null;
  };

  getCyclesScore = (whichScore, index, person) => {
    if (!person.scoreCycles || !person.scoreCycles[whichScore])
      return [];

    return person.scoreCycles[whichScore].filter(score =>
      score.nm_cycle === parseInt(index.toString().substr(index.toString().length - 2))
    ).pop();
  };

  getUpgradeStatus = (levelsToCompare) => {
    return levelsToCompare.levelId >= levelsToCompare.levelNecessaryId ?
      <div className="growth-plan-report-icon-container"><FontIcon className="pe-7s-check" color={green500} /> <div className="growth-plan-report-icon">{levelsToCompare.levelName}</div></div>
      : <div className="growth-plan-report-icon-container"><FontIcon className="pe-7s-close-circle" color={red500} /> <div className="growth-plan-report-icon">{levelsToCompare.levelName}</div></div>
  };

  getComplianceStatus = (leaderThermometer) => {
    if (leaderThermometer.ChargingId > 0)
      return <div className="growth-plan-report-icon-container">
        <FontIcon className="pe-7s-check" color={green500} />
        <div className="growth-plan-report-icon">
          <FormattedMessage
            id="geral.adimplente"
            defaultMessage="Adimplente"
            description="Adimplente"
          />
        </div>
      </div>;
    else if (leaderThermometer.ChargingId === 0)
      return <div className="growth-plan-report-icon-container">
        <FontIcon className="pe-7s-close-circle" color={red500} />
        <div className="growth-plan-report-icon">
          <FormattedMessage
            id="geral.inadimplente"
            defaultMessage="Inadimplente"
            description="Inadimplente"
          />
        </div>
      </div>;
  };

  getChallengeInfo = (challenge) => {
    return <FormattedMessage
      id="lider.desafios.cumprir.restante.desafio"
      defaultMessage=" {qtyRes} ciclos / {qtyDes} ciclos"
      description="Informação restante e desafios"
      values={{
        qtyRes: challenge.challengeRemaining,
        qtyDes: challenge.challenge
      }}
    />
  };

  getBusinessLeaderStatus = (person) => {
    return person.dc_roles && person.dc_roles.findIndex(role => role.nm_role === 19) > -1;
  };

  getCycleOrders = (event, person, orders, cycle) => {
    this.props.personActions.getCycleOrders(person, orders, this.props.usuario, cycle)
      .then(response => {
        if (response.value[0].length > 0 || response.value[1].length > 0)
          scroller.scrollTo('cycleOrders', {
            duration: 150,
            smooth: true
          });
        else {
          let alertOptions = {
            title: this.props.intl.formatMessage({ id: 'mensagens.titulo.ciclo.pedidos' }),
            content: this.props.intl.formatMessage({ id: 'mensagens.conteudo.ciclo.pedidos.nenhum' })
          };
          this.alert.handleOpen(alertOptions);
        }
      });
  };

  getCycleStatus = (event, person, cycle) => {
    this.props.personActions.getCycleStatus(person, cycle, this.props.usuario)
      .then(response => {
        let { value } = response
        if (value[0].length === 0 && value[1].length === 0) {
          let alertOptions = {
            title: this.props.intl.formatMessage({ id: 'mensagens.titulo.ciclo.pedidos' }),
            content: this.props.intl.formatMessage({ id: 'mensagens.conteudo.ciclo.pedidos.nenhum' })
          };
          this.alert.handleOpen(alertOptions);
        }
        let arrayOrders = [] // eslint-disable-next-line array-callback-return
        value.map(item => { // eslint-disable-next-line  array-callback-return
          item.map(i => {
            arrayOrders.push(i.orderNumber)
          })
        })
        let queryString = '';
        for (let i = 0; i < arrayOrders.length; i++) {
          if (queryString === '') {
            queryString = queryString.concat(`${arrayOrders[i]}`)
          } else {
            queryString = queryString.concat(`,${arrayOrders[i]}`)
          }
        }
        this.props.personActions.getCycleOrders(person, queryString, this.props.usuario, cycle)
          .then(response => {
            if (response.value[0].length > 0 || response.value[1].length > 0)
              scroller.scrollTo('cycleOrders', {
                duration: 150,
                smooth: true
              });
            else {
              let alertOptions = {
                title: this.props.intl.formatMessage({ id: 'mensagens.titulo.ciclo.pedidos' }),
                content: this.props.intl.formatMessage({ id: 'mensagens.conteudo.ciclo.pedidos.nenhum' })
              };
              this.alert.handleOpen(alertOptions);
            }
          })
      });
  };

  numberFormatter = (number) => {
    return <FormattedNumber value={number || 0} />;
  };

  currencyFormatter = (number) => { // eslint-disable-next-line react/style-prop-object
    return <FormattedNumber value={number || 0} style="currency" currency="BRL" />;
  };

  startingPointsFormatter = (level, target) => {
    let startingPoints = '0';
    // eslint-disable-next-line default-case
    switch (target) {
      case 'levelPointsRangeStart':
        if (level[target] && level[target] >= 0)
          startingPoints = level[target];
        break;
      case 'commercialStructureScore':
        if (level.score[target] && level.score[target] >= 0)
          startingPoints = level.score[target];
        break;

    }

    return <FormattedMessage
      id="consultora.canal.pontos"
      defaultMessage="{points} pts."
      description="Pontuação"
      values={{ points: this.numberFormatter(startingPoints) }}
    />

  };

  remainingPointsFormatter = (level, target) => {
    // eslint-disable-next-line default-case
    switch (target) {
      case 'levelPointsRemaining':
        if (level[target] === 0 || level[target] > 0) {
          let formattedPoints = this.numberFormatter(level[target]);

          if (level[target] > 0)
            return <FormattedMessage
              id="extratos.titulo.plano.de.crescimento.pontos.restantes"
              defaultMessage="Faltam {points} pts."
              description="Mensagem de pontos restantes"
              values={{
                points: formattedPoints
              }}
            />;
          else if (level[target] === 0)
            return <div>
              <FiberManualRecord color={`rgba(${level.color_r}, ${level.color_g}, ${level.color_b}, ${level.color_a})`} />
            </div>;
        }

        return 0;
      case 'commercialStructureScoreRemaining':
        if (level.score[target] === 0 || level.score[target] > 0) {
          let formattedPoints = this.numberFormatter(level.score[target]);

          if (level.score[target] > 0)
            return <FormattedMessage
              id="extratos.titulo.plano.de.crescimento.pontos.restantes"
              defaultMessage="Faltam {points} pts."
              description="Mensagem de pontos restantes"
              values={{
                points: formattedPoints
              }}
            />;
          else if (level.score[target] === 0)
            return <div>
              <FiberManualRecord color={`rgba(${level.color_r}, ${level.color_g}, ${level.color_b}, ${level.color_a})`} />
            </div>;
        }

        return 0;

    }
  };

  render() {
    let { person, growthPlan, consultant = {}, intl } = this.props;
    let levels = this.getLevels(person, 'thermometer');
    let leaderLevels = this.getLevels(person, 'leaderThermometer');
    let totalCycles = [];
    if (growthPlan.selectedCycleRange) {
      let cycleStart = growthPlan.selectedCycleRange.start;
      let cycleEnd = growthPlan.selectedCycleRange.end;

      let yearStart = parseInt(cycleStart / 100);
      let yearEnd = parseInt(cycleEnd / 100);

      if (yearStart === yearEnd)
        totalCycles = _.range(cycleStart, cycleEnd + 1);
      else {
        totalCycles = _.range((yearEnd * 100 + 1), cycleEnd + 1);
        totalCycles.unshift(cycleStart);
      }

    } else if (person.thermometer && person.thermometer.periodStartCycle && person.thermometer.periodEndCycle) {
      totalCycles = _.range(person.thermometer.periodStartCycle, person.thermometer.periodEndCycle + 1);
    }
    const digitalStore = consultant.digitalStore || {}
    return (
      <ReportPageBase
        person={person}
        commercialStatus={digitalStore.commercialStatus}
        onNewService={this.handleNewService}
        intl={intl}
      >
        <div>
          {growthPlan.cycleRanges &&
            <div className="selectfield-periodo-container">
              <IconButton onTouchTap={this.handlePreviousRange} disabled={this.state.currentRangeIndex === 0} className="iconbutton-periodo">
                <KeyboardArrowLeft />
              </IconButton>
              <SelectField
                className="selectfield-periodo"
                floatingLabelText={
                  <FormattedMessage
                    id="geral.selecione.periodo"
                    defaultMessage="Selecione um periodo"
                    description="Selecione um período"
                  />
                }
                value={JSON.stringify(growthPlan.selectedCycleRange)}
                onChange={this.handleSelectCycleRange}
              >
                {growthPlan.cycleRanges.map(range => {
                  return <MenuItem
                    key={`${range.year}${range.start}_${range.year}${range.end}`}
                    value={JSON.stringify(range)}
                    primaryText={`${range.start} - ${range.end}`}
                  />
                }
                )}
              </SelectField>
              <IconButton onTouchTap={this.handleNextRange} disabled={this.state.currentRangeIndex === growthPlan.cycleRanges.length - 1} className="iconbutton-periodo">
                <KeyboardArrowRight />
              </IconButton>
            </div>
          }
          <h3 className="growth-plan-report-title paper-header">
            <FormattedMessage
              id="extratos.titulo.plano.de.crescimento"
              defaultMessage="Plano de Crescimento Consultora de Beleza"
              description="Título da página de extrato Plano de Crescimento"
            />
            <span> - </span>
            <FormattedMessage
              id="extratos.titulo.ano.ciclos"
              defaultMessage="{year}: Ciclo {cycleStart} até {cycleEnd}"
              description="Ano: Ciclo de início até Ciclo de término"
              values={{
                year: `${this.getYear(person)}`,
                cycleStart: `${this.getPeriodCycle('start')}`,
                cycleEnd: `${this.getPeriodCycle('end')}`
              }}
            />
          </h3>
          {/* eslint-disable-next-line no-mixed-operators */}
          {person.thermometer && levels.length > 0 &&
            <div>
              <BeautyConsultant
                person={person}
                levels={levels}
                startingPointsFormatter={this.startingPointsFormatter}
                remainingPointsFormatter={this.remainingPointsFormatter}
              />
              {this.getBusinessLeaderStatus(person) && person.leaderThermometer &&
                <BusinessLeader
                  person={person}
                  leaderLevels={leaderLevels}
                  year={this.getYear(person)}
                  cycleStart={this.getPeriodCycle('start')}
                  cycleEnd={this.getPeriodCycle('end')}
                  startingPointsFormatter={this.startingPointsFormatter}
                  remainingPointsFormatter={this.remainingPointsFormatter}
                  getUpgradeStatus={this.getUpgradeStatus}
                  getComplianceStatus={this.getComplianceStatus}
                  getChallengeInfo={this.getChallengeInfo}
                />
              }
              <SalesScore
                person={person}
                getSaleScore={this.getSaleScore}
              />
              {person.scoreCycles &&
                <CyclesScore
                  person={person}
                  totalCycles={totalCycles}
                  getCyclesStar={this.getCyclesStar}
                  getCyclesScore={this.getCyclesScore}
                  onTouchTap={this.getCycleStatus}
                  numberFormatter={this.numberFormatter}
                  currencyFormatter={this.currencyFormatter}
                />
              }
            </div> // eslint-disable-next-line no-mixed-operators
            ||
            <div className="homepage-messenger-container">
              <div>
                <FormattedMessage
                  id="consultora.sem.nivel"
                  defaultMessage="Esta pessoa não está associada à um Plano de Crescimento"
                  description="Mensagem de consultora sem nível (api não retornou nada)"
                />
              </div>
            </div>
          }
          {person.cycleOrders && person.cycleOrders.length > 0 && (person.cycleOrders[0].length > 0 || person.cycleOrders[1].length > 0) &&
            <Element name="cycleOrders">
              <CycleOrders
                cycle={person.cycleOrders[0]}
                orders={person.cycleOrders}
                numberFormatter={this.numberFormatter}
                currencyFormatter={this.currencyFormatter}
              />
            </Element>
          }
          <Alert ref={alert => this.alert = alert} />
        </div>
      </ReportPageBase>
    );
  }
}

GrowthPlanReport.propTypes = {
  intl: intlShape,
  usuario: PropTypes.object.isRequired,
  growthPlan: PropTypes.object.isRequired,
  growthPlanActions: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  params: PropTypes.object,
  consultant: PropTypes.object,
  consultantActions: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    growthPlan: state.growthPlan,
    person: state.person,
    consultant: state.consultant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    growthPlanActions: bindActionCreators(growthPlanActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GrowthPlanReport));
