import React from "react";
import { PropTypes } from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { FormattedMessage, FormattedDate } from "react-intl";
import moment from 'moment';
class MySucessDetail extends React.Component { // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {

    let { sucessDetail } = this.props;
    function indexN(cell, row, enumObject, index) {
      return (<div>{index+1}</div>) 
  }
  const getFormattedDate = (date) => {
    const dts = new Date(date).toISOString().substring(0,10)
    return <FormattedDate value={moment(dts,"YYYY-MM-DD").toLocaleString()} />
    };

    return (
      <div className="elegibility-report-container">
        <div className="elegibility-report-container">
          <h4 className="elegibility-report-title">
            <FormattedMessage
              id="mySucess.missoes.periodo"
              defaultMessage="MISSÕES POR PERIODO"
              description="MISSÕES POR PERIODO"
            />
          </h4>
          <div>
            <BootstrapTable
              containerClass="elegibility-report-page-table-container"
              headerContainerClass="elegibility-report-page-table-header"
              bodyContainerClass="elegibility-report-page-table-body"
              bordered={false}
              data={sucessDetail.data}
              searchPlaceholder="Buscar nos resultados"
            >
                <TableHeaderColumn width="2%" row='0' rowSpan='2' dataField="titulo" dataFormat={indexN} >
                #</TableHeaderColumn>
                <TableHeaderColumn width="10%" row='0' rowSpan='2' dataField='data_cumprimento_missao'   dataFormat={getFormattedDate} >
                <FormattedMessage
                  id="mySucess.data"
                  defaultMessage="Data"
                  description="Data"
                /></TableHeaderColumn>
              <TableHeaderColumn width="10%" row='0' rowSpan='2' dataField='titulo'  isKey>
                <FormattedMessage
                  id="mySucess.titulo.missao"
                  defaultMessage="Titulo Missao"
                  description="Titulo Missao"
                /></TableHeaderColumn>

              <TableHeaderColumn width="10%" row='0' dataField='descricao' rowSpan='2'>
                <FormattedMessage
                  id="mySucess.descricao"
                  defaultMessage="Descricao"
                  description="Descricao"
                /></TableHeaderColumn>
              <TableHeaderColumn width="10%" row='0' rowSpan='2' dataField='acao'  >
                <FormattedMessage
                  id="mySucess.acao"
                  defaultMessage="Acao"
                  description="Acao"
                /></TableHeaderColumn>
              <TableHeaderColumn row='0' colSpan='2' width="20%" >
                <FormattedMessage
                  id="mySucess.conquistas"
                  defaultMessage="Conquistas"
                  description="Conquistas"
                /></TableHeaderColumn>
              <TableHeaderColumn row='1' width="10%"   dataField='passos' >Passos</TableHeaderColumn>
              <TableHeaderColumn row='1' width="10%"dataField='medalha'  >Medalhas</TableHeaderColumn>
              
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}

MySucessDetail.propTypes = {
   sucessDetail: PropTypes.array.isRequired,
};

export default MySucessDetail;
