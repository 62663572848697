import * as types from '../actions/actionTypes';
import _ from 'lodash/fp'

const initialState = {
  fields: {
    consult_id: '',
    consult_documentation: '',
    consult_name: ''
  },
  searchResults: []
};

function addField(payload, state) {
  return _.set('fields.' + payload.path, payload.value, state);
}

export default function peopleReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.PEOPLE_ADD_FIELD:
      return addField(payload, state);
    case `${types.PEOPLE_SEARCH}_PENDING`:
      return {...state, searchResults: []};
    case `${types.PEOPLE_SEARCH}_FULFILLED`:
      return {...state, searchResults: payload};
    case types.PEOPLE_SEARCH_CLEAR:
      return initialState;
    default:
      return state;
  }
}
