import * as types from '../actions/actionTypes';

const initialState = {
  data: [],
};

export default function productKitReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.LOAD_LIST_PRODUCT_KIT}_FULFILLED`:
      return {...state, data: payload, productDetail: null};
    case `${types.LOAD_PRODUCT_KIT}_PENDING`:
      return {...state, productDetail: null};
    case `${types.LOAD_PRODUCT_KIT}_FULFILLED`:
      return {...state, productDetail: payload};
    case `${types.GET_DIGITAL_STORE_DETAILS}_FULFILLED`:
      return { ...state, digitalStore: payload };
    default:
      return state;
  }
}
