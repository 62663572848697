import React from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as personActions from '../../actions/personActions';
import * as consultantActions from '../../actions/consultantActions';
import * as indicatorsActions from '../../actions/indicatorsActions';
import * as remunerationActions from '../../actions/remunerationActions';
import * as bonusActions from '../../actions/bonusActions';
import ReportPageBase from '../common/ReportPageBase';
import CardBase from '../common/CardBase';
import IndicatorsPanel from './widgets/IndicatorsPanel';
import IndicatorsPanelDetails from './widgets/IndicatorsPanelDetails';
import RemunerationPanel from './widgets/RemunerationPanel';
import { indicators as indicator } from '../../utils/indicators';
import { ROLE_TYPES } from '../../utils/role-types';
import CircularProgress from 'material-ui/CircularProgress';
class IndicatorsReport extends React.Component {
  state = {
    chevronR1: false,
    chevronR2: false,
    chevronR3: false,
    panelDetails: {
      open: false,
      type: ''
    },
    leaderLevelCode: null,
    bonusesLeader: {},
    matricesLeader: {},
    isLeader: false
  };

  async componentDidMount() {
    const { consultantActions, personActions, usuario } = this.props;
    await personActions
      .getPerson(this.props.params.code, usuario)
      .then(result => {
        consultantActions.getDigitalStoreDetails(usuario, result.value);
      });
    await this.getCurrentCycle();
    await this.handleSearch();
  }

  getCurrentCycle = () => {
    const { person } = this.props;
    this.props.indicatorsActions.setField({
      field: 'cycle',
      value: JSON.stringify(person.currentCycle)
    });
  };

  handleSearch = async () => {
    const { indicators, person, remunerationActions } = this.props;
    const isLeader = this.handleCheckIfLeader(person);
    this.setState({ isLeader })
    if (indicators.cycle) {
      await remunerationActions.cleanSimulationResult();
      await this.resetIndicators();
      await this.getIndicators();
      await this.getBonusAchievement();
      await this.getRemunerationParameters();
      this.setRemuneration();
      if (isLeader) await this.handleSimulation('current');
    }
  };

  resetIndicators = () => {
    const { indicatorsActions } = this.props;
    indicatorsActions.resetIndicators();
  };

  getIndicators = async () => {
    const { indicators, indicatorsActions, person } = this.props;
    await indicatorsActions.getToken();
    await indicatorsActions.getIndicators(indicators, person);
  };

  getBonusAchievement = async () => {
    const { indicators, bonusActions, person, usuario } = this.props;
    await bonusActions.getBonusAchievement(indicators.cycle, person, usuario);
    this.setRemuneration();
  };

  getRemunerationParameters = async () => {
    const { indicators, remunerationActions, person, usuario } = this.props;
    await remunerationActions.getRemunerationParameters(indicators.cycle, person, usuario);
    this.setRemuneration();
  };

  getIndicators = async () => {
    const { indicators, indicatorsActions, person } = this.props;
    await indicatorsActions.getToken();
    await indicatorsActions.getIndicators(indicators, person);
  };

  setRemuneration = () => {
    const { remuneration, person } = this.props;
    if (remuneration.parameters.bonuses && remuneration.parameters.matrices) {
      const leaderInfo = person.leaderInfo.find(item => Number(item.cd_tipo_papel) === ROLE_TYPES.LEADER);
      if (leaderInfo) {
        const leaderLevelCode = Number(leaderInfo.cd_nivel_atual);
        this.setState({ leaderLevelCode: leaderLevelCode })
        this.setState({ bonusesLeader: remuneration.parameters.bonuses[0].levels.find(item => item.leaderLevelCode === leaderLevelCode) })
        this.setState({ matricesLeader: remuneration.parameters.matrices.find(item => item.leaderLevelCode === leaderLevelCode) })
      }
    }
  }

  handleChangeTextField = event => {
    this.props.indicatorsActions.setField({
      field: event.target.name,
      value: event.target.value
    });
  };

  handleSetFieldRemuneration = event => {
    this.props.remunerationActions.setFieldRemuneration({
      field: event.target.name,
      value: event.target.value
    });
  };

  handleKeyUp = event => {
    if (event.key === 'Enter') this.handleSearch();
  };

  handleNewService = () => {
    this.props.router.push('/');
  };

  handleToggleClass = chevronUpdate => {
    const currentState = this.state[chevronUpdate];
    this.setState({ [chevronUpdate]: !currentState });
  };

  handleIndicatorsPanelDetails = async type => {
    const { indicators, indicatorsActions, person } = this.props;
    this.setState({
      panelDetails: {
        open: true,
        type
      }
    });
    if (type) {
      await this.getRemunerationParameters();
      await indicatorsActions.getToken();
      await indicatorsActions.getIndicatorsDetails(indicators, person, type);
    }
  };

  handleCloseIndicatorsPanelDetails = () => {
    const { indicatorsActions } = this.props;
    indicatorsActions.cleanIndicatorsDetails();
    this.setState({
      panelDetails: {
        open: false,
        type: ''
      }
    });
  };

  handleSimulation = async type => {
    const { bonusesLeader } = this.state;
    const {
      indicators: { indicators },
      remunerationActions,
      bonus: { bonusAchievement },
      remuneration: {
        registrationsIndex,
        activitySBIndex,
        balanceIndex,
        activityPersonsIndex,
        scores,
        scoresNetwork,
        activeIndex,
        silverPlus,
        bonusAccumulatedIndex
      }
    } = this.props;
    let params = {};
    if (type === 'current') {
      params = {
        //% ATINGIMENTO BÔNUS POR PERÍODO
        bonusAccumulatedIndex: bonusAchievement ? bonusAchievement.achievedTotal : null,
        //% ATINGIMENTO CADASTRO
        registrationsIndex:
          bonusesLeader.performanceIndexCode === indicator.ATIVIDADE_CADASTRO
            ? indicators[`${indicator.CADASTRO}`] &&
              indicators[`${indicator.CADASTRO}`].achieved
              ? parseFloat(
                indicators[`${indicator.CADASTRO}`].achieved.toFixed(2)
              )
              : 0
            : null,
        //% ATINGIMENTO ATIVIDADE S+B
        activitySBIndex:
          bonusesLeader.performanceIndexCode === indicator.ATIVIDADE_SB
            ? indicators[`${indicator.ATIVIDADE_SB}`] &&
              indicators[`${indicator.ATIVIDADE_SB}`].achieved
              ? parseFloat(
                indicators[`${indicator.ATIVIDADE_SB}`].achieved.toFixed(2)
              )
              : 0
            : null,
        //% ATINGIMENTO SALDO
        balanceIndex:
          bonusesLeader.performanceIndexCode === indicator.SALDO
            ? indicators[`${indicator.SALDO}`] &&
              indicators[`${indicator.SALDO}`].achieved
              ? parseFloat(indicators[`${indicator.SALDO}`].achieved.toFixed(2))
              : 0
            : null,
        //% ATINGIMENTO ATIVIDADE
        activityPersonsIndex:
          indicators[`${indicator.ATIVIDADE}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`] &&
            indicators[`${indicator.ATIVIDADE}`].indexValue &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`].goal
            ? parseFloat(
              (
                (indicators[`${indicator.ATIVIDADE}`].indexValue /
                  indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`]
                    .goal) *
                100
              ).toFixed(2)
            )
            : null,
        //VOLUME TOTAL ATINGIMENTO
        scoresIndex:
          indicators[`${indicator.VOLUME_PRESENCIAL}`] &&
            indicators[`${indicator.TOTAL_DE_PONTOS}`] &&
            indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue &&
            indicators[`${indicator.VOLUME_PRESENCIAL}`].goal
            ? parseFloat(
              (
                (indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue /
                  indicators[`${indicator.VOLUME_PRESENCIAL}`].goal) *
                100
              ).toFixed(2)
            )
            : null,
        //VOLUME TOTAL REAL
        scores: indicators[`${indicator.TOTAL_DE_PONTOS}`]
          ? indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue
          : null,
        //PONTOS REDE
        scoresNetwork: null,
        //% ATINGIMENTO ATIVAS
        activeIndex:
        indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].indexValue
          : null,
        //% ATINGIMENTO ATIVIDADE PRATA MAIS
        activeSilverPlusIndex:
        indicators[`${indicator.ATIVAS_PRATA_MAIS}`]
          ? indicators[`${indicator.ATIVAS_PRATA_MAIS}`].indexValue
          : null,
      };
    }

    if (type === 'simulation') {
      params = {
        //% ATINGIMENTO CADASTRO
        registrationsIndex:
          indicators[`${indicator.CADASTRO}`] &&
            indicators[`${indicator.CADASTRO}`].goal
            ? parseFloat(
              (
                (registrationsIndex /
                  indicators[`${indicator.CADASTRO}`].goal) *
                100
              ).toFixed(2)
            )
            : null,
        //% ATINGIMENTO ATIVIDADE S+B
        activitySBIndex:
          indicators[`${indicator.ATIVIDADE_SB}`] &&
            indicators[`${indicator.ATIVIDADE_SB}`].goal
            ? parseFloat(
              (
                (activitySBIndex /
                  indicators[`${indicator.ATIVIDADE_SB}`].goal) *
                100
              ).toFixed(2)
            )
            : null,
        //% ATINGIMENTO SALDO
        balanceIndex:
          indicators[`${indicator.SALDO}`] &&
            indicators[`${indicator.SALDO}`].goal
            ? parseFloat(
              (
                (balanceIndex / indicators[`${indicator.SALDO}`].goal) *
                100
              ).toFixed(2)
            )
            : null,
        //% ATINGIMENTO ATIVIDADE
        activityPersonsIndex:
          indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`].goal
            ? parseFloat(
              (
                (activityPersonsIndex /
                  indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`]
                    .goal) *
                100
              ).toFixed(2)
            )
            : null,
        //VOLUME TOTAL ATINGIMENTO
        scoresIndex:
          indicators[`${indicator.VOLUME_PRESENCIAL}`] &&
            indicators[`${indicator.VOLUME_PRESENCIAL}`].goal
            ? parseFloat(
              (
                (scores / indicators[`${indicator.VOLUME_PRESENCIAL}`].goal) *
                100
              ).toFixed(2)
            )
            : null,
        //VOLUME TOTAL REAL
        scores: parseFloat(scores),
        //PONTOS REDE
        scoresNetwork: scoresNetwork,
        //% ATINGIMENTO ATIVAS
        activeIndex: parseInt(activeIndex),
        //% ATINGIMENTO ATIVIDADE PRATA MAIS
        activeSilverPlusIndex: parseInt(silverPlus),
        bonusAccumulatedIndex: bonusAchievement &&
          bonusAchievement.goalTotal
          ? parseFloat(
            (
              (bonusAccumulatedIndex /
                bonusAchievement.goalTotal) *
              100
            ).toFixed(2)
          )
          : null,
      };
    }

    await remunerationActions.cleanSimulationResult();
    await this.postSimulation(params, type);
  };

  postSimulation = async (params, type) => {
    const { usuario, indicators, person, remuneration, remunerationActions } = this.props;
    const { leaderLevelCode } = this.state;
    const postParams = {
      businessModel: person.id_commercial_model,
      country: person.id_country,
      personCode: person.id_consult,
      structureCode: person.commercial_structure.nm_group,
      structureLevel: 5,
      cycle: JSON.parse(indicators.cycle),
      leaderLevelCode,
      parameters: remuneration.parameters,
      ...params
    };
    await remunerationActions.postSimulation(usuario, postParams, type);
  };

  handleCleanSimulationResult = () => {
    const { remunerationActions } = this.props;
    remunerationActions.cleanSimulationResult();
  };

  handleCleanSimulationValues = () => {
    const { remunerationActions } = this.props;
    remunerationActions.cleanSimulationValues();
  };

  handleCheckIfLeader = (person) => {
    return (person && person.commercial_structure && person.commercial_structure.nm_group) ? true : false
  }

  handlers = {
    changeTextField: this.handleChangeTextField,
    setFieldRemuneration: this.handleSetFieldRemuneration,
    simulation: this.handleSimulation,
    indicatorsDetails: this.handleIndicatorsPanelDetails,
    keyUp: this.handleKeyUp,
    search: this.handleSearch,
    toggleClass: this.handleToggleClass,
    cleanSimulationResult: this.handleCleanSimulationResult,
    cleanSimulationValues: this.handleCleanSimulationValues
  };

  render() {
    const { panelDetails, chevronR1, chevronR2, chevronR3, matricesLeader, isLeader } = this.state;
    const {
      intl,
      person,
      consultant = {},
      indicators: { cycle, indicators, indicatorsDetails },
      bonus: { bonusAchievement },
      remuneration: {
        simulationCurrentValue,
        simulationValue,
        activityPersonsIndex,
        scores,
        activeIndex,
        silverPlus,
        bonusAccumulatedIndex
      }
    } = this.props;
    const digitalStore = consultant.digitalStore || {};

    let tableDataRemunerated = [
      {
        class: 'clickable',
        dataToggle: 'collapse',
        id: 'row1',
        dataTarget: '.row1',
        chevronName: 'chevronR1',
        chevron: chevronR1,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.VOLUME_PRESENCIAL
          || matricesLeader.parameterColumnType === indicator.VOLUME_PRESENCIAL
          : true,
        indicador: 'Volume de pontos',
        onConsultants: isLeader,
        real: indicators[`${indicator.TOTAL_DE_PONTOS}`]
          ? indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue
          : undefined,
        meta: indicators[`${indicator.VOLUME_PRESENCIAL}`]
          ? indicators[`${indicator.VOLUME_PRESENCIAL}`].goal
          : null,
        atingimento:
          indicators[`${indicator.VOLUME_PRESENCIAL}`] &&
            indicators[`${indicator.TOTAL_DE_PONTOS}`] &&
            indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue &&
            indicators[`${indicator.VOLUME_PRESENCIAL}`].goal
            ? `${parseFloat(
              (
                (indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue /
                  indicators[`${indicator.VOLUME_PRESENCIAL}`].goal) *
                100
              ).toFixed(2)
            )}%`
            : null
      },
      {
        class: 'collapse row1',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.VOLUME_PRESENCIAL
          || matricesLeader.parameterColumnType === indicator.VOLUME_PRESENCIAL
          : true,
        indicador: 'Presencial',
        onConsultants: false,
        real: indicators[`${indicator.VOLUME_PRESENCIAL}`]
          ? indicators[`${indicator.VOLUME_PRESENCIAL}`].indexValue
          : null
      },
      {
        class: 'collapse row1',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.VOLUME_PRESENCIAL
          || matricesLeader.parameterColumnType === indicator.VOLUME_PRESENCIAL
          : true,
        indicador: 'Digital',
        onConsultants: false,
        real: indicators[`${indicator.VOLUME_DIGITAL}`]
          ? indicators[`${indicator.VOLUME_DIGITAL}`].indexValue
          : null
      },
      {
        class: 'clickable',
        dataToggle: 'collapse',
        id: 'row2',
        dataTarget: '.row2',
        chevronName: 'chevronR2',
        chevron: chevronR2,
        enable: isLeader ? indicator.ATIVIDADE.includes(matricesLeader.parameterRowType)
          || indicator.ATIVIDADE.includes(matricesLeader.parameterColumnType)
          : true,
        type: 'float',
        indicador: 'Atividade',
        onConsultants: isLeader,
        real: // eslint-disable-next-line
          indicators[`${indicator.ATIVIDADE[1]}`] &&
          (indicators[`${indicator.ATIVIDADE[1]}`].indexValue
            ? `${parseFloat(
              indicators[`${indicator.ATIVIDADE[1]}`].indexValue.toFixed(2)
            )}%`
            : indicators[`${indicator.ATIVIDADE[1]}`].indexValue === null ? null
              : undefined) // eslint-disable-next-line
          || indicators[`${indicator.ATIVIDADE[0]}`] &&
          (indicators[`${indicator.ATIVIDADE[0]}`].indexValue
            ? `${parseFloat(
              indicators[`${indicator.ATIVIDADE[0]}`].indexValue.toFixed(2)
            )}%`
            : indicators[`${indicator.ATIVIDADE[0]}`].indexValue === null ? null
              : undefined),
        meta: // eslint-disable-next-line
          indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`].goal
            ? `${parseFloat(
              indicators[
                `${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`
              ].goal.toFixed(2)
            )}%`
            : null,
        atingimento: // eslint-disable-next-line
          indicators[`${indicator.ATIVIDADE}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`] &&
            indicators[`${indicator.ATIVIDADE}`].indexValue &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`].goal
            ? `${parseFloat(
              (
                (indicators[`${indicator.ATIVIDADE}`].indexValue /
                  indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`]
                    .goal) *
                100
              ).toFixed(2)
            )}%`
            : null
      },
      {
        class: 'collapse row2',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? indicator.ATIVIDADE.includes(matricesLeader.parameterRowType)
          || indicator.ATIVIDADE.includes(matricesLeader.parameterColumnType)
          : true,
        indicador: 'Ativas',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].indexValue
          : null
      },
      {
        class: 'collapse row2',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? indicator.ATIVIDADE.includes(matricesLeader.parameterRowType)
          || indicator.ATIVIDADE.includes(matricesLeader.parameterColumnType)
          : true,
        indicador: 'Cadastro',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_CADASTRO}`]
          ? indicators[`${indicator.ATIVIDADE_CADASTRO}`].indexValue
          : null
      },
      {
        class: 'clickable',
        dataToggle: 'collapse',
        id: 'row3',
        dataTarget: '.row3',
        chevronName: 'chevronR3',
        chevron: chevronR3,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.ATIVIDADE_ATIVAS
          || matricesLeader.parameterColumnType === indicator.ATIVIDADE_ATIVAS
          : false,
        indicador: 'Ativas Total',
        onConsultants: true,
        real: indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].indexValue
          : undefined,
        meta: indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].goal
          : undefined,
        atingimento: indicators[`${indicator.ATIVIDADE_ATIVAS}`] &&
          indicators[`${indicator.ATIVIDADE_ATIVAS}`].achieved
          ? `${parseFloat(
            indicators[`${indicator.ATIVIDADE_ATIVAS}`].achieved.toFixed(2)
          )}%`
          : null
      },
      {
        class: 'collapse row3',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.ATIVIDADE_ATIVAS
          || matricesLeader.parameterColumnType === indicator.ATIVIDADE_ATIVAS
          : false,
        indicador: 'Ativas Presencial',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_PRESENCIAL}`]
          ? indicators[`${indicator.ATIVIDADE_PRESENCIAL}`].indexValue
          : null,
        meta: null,
        atingimento: null
      },
      {
        class: 'collapse row3',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.ATIVIDADE_ATIVAS
          || matricesLeader.parameterColumnType === indicator.ATIVIDADE_ATIVAS
          : false,
        indicador: 'Ativas Digital',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_DIGITAL}`]
          ? indicators[`${indicator.ATIVIDADE_DIGITAL}`].indexValue
          : null,
        meta: null,
        atingimento: null
      },
      {
        class: 'collapse row3',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.ATIVIDADE_ATIVAS
          || matricesLeader.parameterColumnType === indicator.ATIVIDADE_ATIVAS
          : false,
        indicador: 'Ativas Ambos',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_AMBOS}`]
          ? indicators[`${indicator.ATIVIDADE_AMBOS}`].indexValue
          : null,
        meta: null,
        atingimento: null
      },
      {
        class: 'clickable',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: isLeader ? matricesLeader.parameterRowType === indicator.ATIVAS_PRATA_MAIS
          || matricesLeader.parameterColumnType === indicator.ATIVAS_PRATA_MAIS
          : false,
        indicador: 'Ativas Prata Mais',
        onConsultants: isLeader,
        real: indicators[`${indicator.ATIVAS_PRATA_MAIS}`]
          ? indicators[`${indicator.ATIVAS_PRATA_MAIS}`].indexValue
          : null,
        meta: indicators[`${indicator.ATIVAS_PRATA_MAIS}`]
          ? indicators[`${indicator.ATIVAS_PRATA_MAIS}`].goal
          : null,
        atingimento:
          indicators[`${indicator.ATIVAS_PRATA_MAIS}`] &&
            indicators[`${indicator.ATIVAS_PRATA_MAIS}`] &&
            indicators[`${indicator.ATIVAS_PRATA_MAIS}`].indexValue &&
            indicators[`${indicator.ATIVAS_PRATA_MAIS}`].goal
            ? `${parseFloat(
              (
                (indicators[`${indicator.ATIVAS_PRATA_MAIS}`].indexValue /
                  indicators[`${indicator.ATIVAS_PRATA_MAIS}`]
                    .goal) *
                100
              ).toFixed(2)
            )}%`
            : null
      }
    ];

    // Checking if bonus is accumulated (more than one cycle inside cycleListDetails array)
    const accumulatedBonus = (bonusAchievement && bonusAchievement.cycleListDetails && bonusAchievement.cycleListDetails.length > 1) ? true : false;

    let tableDataBonus = [
      {
        class: 'clickable',
        dataToggle: accumulatedBonus ? 'collapse' : false,
        id: 'row1',
        dataTarget: '.row1',
        chevronName: 'chevronR1',
        chevron: chevronR1,
        type: 'integer',
        enable: true,
        indicador: bonusAchievement ? this.props.intl.formatMessage({
          id: 'index.name.' + bonusAchievement.indexCode
        }) : " - ",
        onConsultants: false,
        real: bonusAchievement &&
        bonusAchievement.realTotal
          ? bonusAchievement.realTotal
          : undefined,
        meta: bonusAchievement &&
        bonusAchievement.goalTotal
          ? bonusAchievement.goalTotal
          : undefined,
        atingimento:
          bonusAchievement &&
            bonusAchievement.achievedTotal
            ? `${parseFloat(
              bonusAchievement.achievedTotal.toFixed(2)
            )}%`
            : undefined
      }
    ].concat((accumulatedBonus) ?
      bonusAchievement.cycleListDetails.map(item => {
      return {
        class: 'collapse row1',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: true,
        indicador: item.cycle,
        onConsultants: false,
        real: item.real
          ? item.real
          : null,
        meta: item.goal
        ? item.goal
        : null,
        atingimento:
          item &&
            item.achieved
            ? `${parseFloat(
              item.achieved.toFixed(2)
            )}%`
            : null
      }
    }) : []);

    let tableDataRemuneration = [
      {
        type: 'integer',
        enable: true,
        indicador: 'Volume de pontos',
        real: indicators[`${indicator.TOTAL_DE_PONTOS}`]
          ? indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue
          : null,
        meta: indicators[`${indicator.VOLUME_PRESENCIAL}`]
          ? indicators[`${indicator.VOLUME_PRESENCIAL}`].goal
          : null,
        atingimento:
          indicators[`${indicator.VOLUME_PRESENCIAL}`] &&
            indicators[`${indicator.TOTAL_DE_PONTOS}`] &&
            indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue &&
            indicators[`${indicator.VOLUME_PRESENCIAL}`].goal
            ? `${parseFloat(
              (
                (indicators[`${indicator.TOTAL_DE_PONTOS}`].indexValue /
                  indicators[`${indicator.VOLUME_PRESENCIAL}`].goal) *
                100
              ).toFixed(2)
            )}%`
            : null,
        realSimulado: scores,
        realSimuladoLabel: 'scores',
        atingimentoSimulado:
          indicators[`${indicator.VOLUME_PRESENCIAL}`] &&
            indicators[`${indicator.VOLUME_PRESENCIAL}`].goal
            ? `${parseFloat(
              (
                (scores / indicators[`${indicator.VOLUME_PRESENCIAL}`].goal) *
                100
              ).toFixed(2)
            )}%`
            : null
      },
      {
        enable: isLeader ? indicator.ATIVIDADE.includes(matricesLeader.parameterRowType)
        || indicator.ATIVIDADE.includes(matricesLeader.parameterColumnType)
        : true,
        type: 'float',
        indicador: 'Atividade',
        real:
          indicators[`${indicator.ATIVIDADE}`] &&
            indicators[`${indicator.ATIVIDADE}`].indexValue
            ? `${parseFloat(
              indicators[`${indicator.ATIVIDADE}`].indexValue.toFixed(2)
            )}%`
            : null,
        meta:
          indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`].goal
            ? `${parseFloat(
              indicators[
                `${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`
              ].goal.toFixed(2)
            )}%`
            : null,
        atingimento:
          indicators[`${indicator.ATIVIDADE}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`] &&
            indicators[`${indicator.ATIVIDADE}`].indexValue &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`].goal
            ? `${parseFloat(
              (
                (indicators[`${indicator.ATIVIDADE}`].indexValue /
                  indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`]
                    .goal) *
                100
              ).toFixed(2)
            )}%`
            : null,
        realSimulado: activityPersonsIndex,
        realSimuladoLabel: 'activityPersonsIndex',
        atingimentoSimulado:
          indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`].goal
            ? `${parseFloat(
              (
                (activityPersonsIndex /
                  indicators[`${indicator.ATIVIDADE_ATIVAS_FREQUENTE}`]
                    .goal) *
                100
              ).toFixed(2)
            )}%`
            : null
      },
      {
        type: 'integer',
        enable: true,
        indicador: 'Ativas',

        real: indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].indexValue
          : null,
        meta: indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].goal
          : null,
        atingimento:
          indicators[`${indicator.ATIVIDADE_ATIVAS}`] &&
            indicators[`${indicator.ATIVIDADE_ATIVAS}`].achieved
            ? `${parseInt(
              indicators[`${indicator.ATIVIDADE_ATIVAS}`].achieved.toFixed(2)
            )}%`
            : null,
        realSimulado: activeIndex,
        realSimuladoLabel: 'activeIndex',
        atingimentoSimulado: activeIndex
      },
      {
        type: 'integer',
        enable: true,
        indicador: 'Ativas Prata Mais',

        real: indicators[`${indicator.ATIVAS_PRATA_MAIS}`]
          ? indicators[`${indicator.ATIVAS_PRATA_MAIS}`].indexValue
          : null,
        meta: indicators[`${indicator.ATIVAS_PRATA_MAIS}`]
          ? indicators[`${indicator.ATIVAS_PRATA_MAIS}`].goal
          : null,
        atingimento:
          indicators[`${indicator.ATIVAS_PRATA_MAIS}`] &&
            indicators[`${indicator.ATIVAS_PRATA_MAIS}`].achieved
            ? `${parseInt(
              indicators[`${indicator.ATIVAS_PRATA_MAIS}`].achieved.toFixed(2)
            )}%`
            : null,
        realSimulado: silverPlus,
        realSimuladoLabel: 'silverPlus',
        atingimentoSimulado: silverPlus
      },
      {
        type: 'float',
        enable: true,
        indicador: 'Bônus do Período',
        real: bonusAchievement &&
          bonusAchievement.realTotal
            ? bonusAchievement.realTotal
            : undefined,
        meta: bonusAchievement &&
        bonusAchievement.goalTotal
          ? bonusAchievement.goalTotal
          : undefined,
        atingimento:
          bonusAchievement &&
            bonusAchievement.achievedTotal
            ? `${parseFloat(
              bonusAchievement.achievedTotal.toFixed(2)
            )}%`
            : undefined,
        realSimulado: bonusAccumulatedIndex,
        realSimuladoLabel: 'bonusAccumulatedIndex',
        atingimentoSimulado: bonusAchievement &&
          bonusAchievement.goalTotal
          ? `${parseFloat(
            (
              (bonusAccumulatedIndex /
                bonusAchievement.goalTotal) *
              100
            ).toFixed(2)
          )}%`
          : null
      }
    ];

    let tableDataIndicators = [
      {
        class: 'clickable',
        dataToggle: 'collapse',
        id: 'row7',
        dataTarget: '.row7',
        chevronName: 'chevronR3',
        chevron: chevronR3,
        type: 'integer',
        enable: matricesLeader.parameterRowType === indicator.ATIVIDADE_ATIVAS
          || matricesLeader.parameterColumnType === indicator.ATIVIDADE_ATIVAS
          ? false : true,
        indicador: 'Ativas Total',
        onConsultants: true,
        real: indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].indexValue
          : undefined,
        meta: indicators[`${indicator.ATIVIDADE_ATIVAS}`]
          ? indicators[`${indicator.ATIVIDADE_ATIVAS}`].goal
          : undefined,
        atingimento: indicators[`${indicator.ATIVIDADE_ATIVAS}`] &&
          indicators[`${indicator.ATIVIDADE_ATIVAS}`].achieved
          ? `${parseFloat(
            indicators[`${indicator.ATIVIDADE_ATIVAS}`].achieved.toFixed(2)
          )}%`
          : null
      },
      {
        class: 'collapse row7',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: true,
        indicador: 'Ativas Presencial',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_PRESENCIAL}`]
          ? indicators[`${indicator.ATIVIDADE_PRESENCIAL}`].indexValue
          : null,
        meta: null,
        atingimento: null
      },
      {
        class: 'collapse row7',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: true,
        indicador: 'Ativas Digital',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_DIGITAL}`]
          ? indicators[`${indicator.ATIVIDADE_DIGITAL}`].indexValue
          : null,
        meta: null,
        atingimento: null
      },
      {
        class: 'collapse row7',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: true,
        indicador: 'Ativas Ambos',
        onConsultants: false,
        real: indicators[`${indicator.ATIVIDADE_AMBOS}`]
          ? indicators[`${indicator.ATIVIDADE_AMBOS}`].indexValue
          : null,
        meta: null,
        atingimento: null
      },
      {
        class: 'clickable',
        dataToggle: 'collapse',
        id: 'row8',
        dataTarget: '.row8',
        chevronName: 'chevronR2',
        chevron: chevronR2,
        enable: true,
        type: 'float',
        indicador: 'Penetração de Canal',
        onConsultants: true,
        real:
          indicators[`${indicator.PENETRACAO_CANAL}`] &&
          (indicators[`${indicator.PENETRACAO_CANAL}`].indexValue
            ? `${(indicators[`${indicator.PENETRACAO_CANAL}`].indexValue * 100).toFixed(2)}%`
            : indicators[`${indicator.PENETRACAO_CANAL}`].indexValue === null ? null
              : undefined),
        meta: null,
        atingimento: null
      },
      {
        class: 'collapse row8',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: true,
        indicador: 'Espaços Abertos',
        onConsultants: false,
        real: indicators[`${indicator.ESPACOS_ABERTOS}`]
          ? indicators[`${indicator.ESPACOS_ABERTOS}`].indexValue
          : null,
        meta: null,
        atingimento: null
      },
      {
        class: 'collapse row8',
        dataToggle: false,
        id: false,
        dataTarget: false,
        chevronName: false,
        chevron: false,
        type: 'integer',
        enable: true,
        indicador: 'Total de Consultoras',
        onConsultants: false,
        real: indicators[`${indicator.TOTAL_CONSULTORAS}`]
          ? indicators[`${indicator.TOTAL_CONSULTORAS}`].indexValue
          : null,
        meta: null,
        atingimento: null
      }
    ];

    return (
      <div>
        <ReportPageBase
          person={person}
          onNewService={this.handleNewService}
          commercialStatus={digitalStore.commercialStatus}
          intl={intl}
          type={'consultant'}
        />

        {!panelDetails.open && (
          <div>
            {isLeader && (
              <CardBase
                title={this.props.intl.formatMessage({
                  id: 'remuneration.report.name'
                })}
              >
                <RemunerationPanel
                  handlers={this.handlers}
                  simulationCurrentValue={simulationCurrentValue}
                  simulationValue={simulationValue}
                  tableDataRemuneration={tableDataRemuneration}
                />
              </CardBase>
            )}
            <CardBase
              title={this.props.intl.formatMessage({
                id: 'indicators.report.name'
              })}
            >
              <IndicatorsPanel
                isLeader={isLeader}
                cycle={cycle}
                handlers={this.handlers}
                tableDataRemunerated={tableDataRemunerated}
                tableDataBonus={tableDataBonus}
                tableDataIndicators={tableDataIndicators}
              />
            </CardBase>
          </div>
        )}
        {panelDetails.open && (
          <CardBase
            title={panelDetails.type}
            btnLabel={'VOLTAR'}
            btnClose={this.handleCloseIndicatorsPanelDetails}
          >
            {indicatorsDetails && indicatorsDetails.length > 0 ?
              <IndicatorsPanelDetails
                indicatorsDetails={indicatorsDetails}
                type={panelDetails.type}
              />
              :
              <div className="indicators-loading">
                <CircularProgress />
                <p>
                  {this.props.intl.formatMessage({
                    id: 'indicators.report.loading'
                  })}
                </p>
              </div>
            }
          </CardBase>
        )}
      </div>
    );
  }
}

IndicatorsReport.propTypes = {
  intl: intlShape,
  params: PropTypes.object,
  usuario: PropTypes.object,
  consultant: PropTypes.object,
  indicators: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  remuneration: PropTypes.object.isRequired,
  bonus: PropTypes.object.isRequired,
  consultantActions: PropTypes.object,
  indicatorsActions: PropTypes.object,
  personActions: PropTypes.object.isRequired,
  remunerationActions: PropTypes.object,
  bonusActions: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    indicators: state.indicators,
    person: state.person,
    usuario: state.usuario,
    remuneration: state.remuneration,
    bonus: state.bonus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    consultantActions: bindActionCreators(consultantActions, dispatch),
    indicatorsActions: bindActionCreators(indicatorsActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    remunerationActions: bindActionCreators(remunerationActions, dispatch),
    bonusActions: bindActionCreators(bonusActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(IndicatorsReport));
