import React from "react";
import {PropTypes} from "prop-types";
import {FormattedMessage} from "react-intl";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

class ElegibilityTraining extends React.Component { // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    let {training} = this.props;

    return (
      <div className="elegibility-report-container">
        <div className="elegibility-report-container">
          <h4 className="elegibility-report-title">
            <FormattedMessage
              id="elegibilidade.treinamentos"
              defaultMessage="Treinamentos"
              description="Treinamentos"
            />
          </h4>
          <div>
            <BootstrapTable
              containerClass="elegibility-report-page-table-container"
              headerContainerClass="elegibility-report-page-table-header"
              bodyContainerClass="elegibility-report-page-table-body"
              bordered={false}
              data={training}
            >
              <TableHeaderColumn dataField="order" hidden={true} isKey={true}/>
              <TableHeaderColumn width="45%" dataField="header">
                <FormattedMessage
                  id="elegibility.treinamento"
                  defaultMessage="Treinamento"
                  description="Treinamento"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="55%" dataField="status" dataFormat={this.props.statusFormatter}>
                <FormattedMessage
                  id="elegibility.situacao"
                  defaultMessage="Situação"
                  description="Situação"
                />
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}

ElegibilityTraining.propTypes = {
  training: PropTypes.array.isRequired,
  statusFormatter: PropTypes.func.isRequired
};

export default ElegibilityTraining;
