import * as types from './actionTypes';
import CommercialFirebaseService from '../service/CommercialFirebaseService';

export function getAppNavigationData() {
  return async function(dispatch) {
    const data = await CommercialFirebaseService.getAllRemoteConfigData();
    const arr = [];
      data.length > 0 &&
        data.sort((a,b)=>a.order - b.order) // eslint-disable-next-line
        .map(item => {
          if(item.active)
            arr.push({
              label: item.menuName,
              icon: "",
              link: item.route,
              roles: item.roles.map(item => normalizeString(item)),
              target: item.target,
              type: "internal"
            });
        });

    localStorage.setItem('navigation', JSON.stringify(arr));
    return dispatch({
      type: types.GET_APP_NAVIGATION_DATA,
      payload: arr
    });
  };
}

const normalizeString = (string) => {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
}
