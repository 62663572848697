import React from "react";
import {PropTypes} from "prop-types";
import {FormattedMessage} from "react-intl";
// import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

import FontIcon from 'material-ui/FontIcon';
import {green500, red500} from 'material-ui/styles/colors';

class ElegibilityCriteria extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    let {criteria} = this.props;

    return (
      <div className="elegibility-report-container">
        <div className="elegibility-report-container">
          <h4 className="elegibility-report-title">
            <FormattedMessage
              id="elegibilidade.criterios"
              defaultMessage="Critérios de Elegibilidade"
              description="Critérios de Elegibilidade"
            />
          </h4>
          {/*<div>*/}
            {/*<BootstrapTable*/}
              {/*containerClass="elegibility-report-page-table-container"*/}
              {/*headerContainerClass="elegibility-report-page-table-header"*/}
              {/*bodyContainerClass="elegibility-report-page-table-body"*/}
              {/*bordered={false}*/}
              {/*data={criteria}*/}
            {/*>*/}
              {/*<TableHeaderColumn dataField="order" hidden={true} isKey={true}/>*/}
              {/*<TableHeaderColumn width="30%" dataField="header"/>*/}
              {/*<TableHeaderColumn width="30%" dataField="description">*/}
                {/*<FormattedMessage*/}
                  {/*id="elegibility.criteria"*/}
                  {/*defaultMessage="Critério"*/}
                  {/*description="Critério"*/}
                {/*/>*/}
              {/*</TableHeaderColumn>*/}
              {/*<TableHeaderColumn width="30%" dataField="status" dataFormat={this.props.statusFormatter}>*/}
                {/*<FormattedMessage*/}
                  {/*id="elegibility.status"*/}
                  {/*defaultMessage="Status"*/}
                  {/*description="Status"*/}
                {/*/>*/}
              {/*</TableHeaderColumn>*/}
            {/*</BootstrapTable>*/}
          {/*</div>*/}

          <div>
            <table className="elegibility-report-table-container">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  {criteria && criteria.map(item =>
                    <th key={Math.random()}>{`${item.header}`}</th>
                  )}
                </tr>
              </thead>
              <tbody className="growth-plan-report-table-hover">
              <tr>
                <td>
                  <FormattedMessage
                    id="elegibility.criteria"
                    defaultMessage="Critério"
                    description="Critério"
                  />
                </td>
                {criteria && criteria.map(item =>
                  <td key={Math.random()}>{`${item.description}`}</td>
                )}
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id="elegibility.status"
                    defaultMessage="Status"
                    description="Status"
                  />
                </td> {/* eslint-disable-next-line array-callback-return  */}
                {criteria && criteria.map(item => {
                  // eslint-disable-next-line default-case
                  switch (item.status) {
                    case true:
                      return (
                      <td key={Math.random()}><FontIcon className="pe-7s-check" color={green500}/></td>

                      );
                    case false:
                      return (
                      <td key={Math.random()}><FontIcon className="pe-7s-close-circle" color={red500}/></td>
                      );
                    }
                  })
                }
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    );
  }
}

ElegibilityCriteria.propTypes = {
  criteria: PropTypes.array.isRequired,
  statusFormatter: PropTypes.func.isRequired
};

export default ElegibilityCriteria;
