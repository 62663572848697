import { typography } from 'material-ui/styles';
import { grey600 } from 'material-ui/styles/colors';

const styles = {
  navigation: {
    fontSize: 15,
    fontWeight: typography.fontWeightLight,
    color: grey600,
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: typography.fontWeightLight,
    marginBottom: 20
  },
  paper: {
    padding: 30,
    marginBottom: 20
  },
  clear: {
    clear: 'both'
  },
  tableIndicators: {
    width: '95%',
    margin: '0px auto'
  },
  cycleField: {
    background: 'pink',
    margin: 20,
    float: 'left'
  },
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '95%',
    margin: '0px auto',
    paddingTop: 10,
    paddingBottom: 10
  },
  cardBase: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
};

export default styles;
