import { routerActions } from 'react-router-redux';
import { UserAuthWrapper } from 'redux-auth-wrapper';

export const UserIsAuthenticated = UserAuthWrapper({
  authSelector: state => state.usuario,
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated',
  failureRedirectPath: '/signIn'
});

export const UserIsUser = UserAuthWrapper({
  authSelector: state => state.usuario,
  redirectAction: routerActions.replace,
  failureRedirectPath: '/',
  wrapperDisplayName: 'UserIsUser',
  predicate: usuario => usuario.isUser,
  allowRedirectBack: false
});
