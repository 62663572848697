import * as types from './actionTypes';
import authApi from '../api/authApi';

export function accessByToken(accessToken, codigoUsuario) {
  return function (dispatch) {
    return dispatch({
      type: types.AUTHENTICATE_SUCCESS,
      payload: authApi.accessByToken(accessToken, codigoUsuario)
    });
  };
}

export function decodeUuid(uuid) {
  return function (dispatch) {
    return dispatch({
      type: types.DECODE_UUID,
      payload: authApi.decodeUuid(uuid)
    });
  };
}

export function logout() {
  return function (dispatch) {
    return new Promise((resolve) => {
    localStorage.removeItem('usuario');
    dispatch({ type: types.LOGOUT_SUCCESS});
    resolve();
  });
  };
}
