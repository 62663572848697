/* eslint-disable no-console, no-nested-ternary */

import _isNull from 'lodash/isNull';
import _isUndefined from 'lodash/isUndefined';
import _isNaN from 'lodash/isNaN';
import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';
import _isBoolean from 'lodash/isBoolean';
import _isArray from 'lodash/isArray';
import _isArrayBuffer from 'lodash/isArrayBuffer';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import _isDate from 'lodash/isDate';
import _isObjectLike from 'lodash/isObjectLike';

const validateData = (
  data,
  {
    // types
    allowNumber = true,
    allowString = true,
    allowBoolean = true,
    allowArray = true,
    allowArrayBuffer = true,
    allowObject = true,
    allowDate = true,
    allowFunction = true,
    allowNormalFunction = true,
    allowAsyncFunction = true,
    allowGeneratorFunction = true,
    // false/empty values
    allowZero = true,
    allowNegative = true,
    allowFalse = true,
    allowEmptyString = true,
    allowEmptyArray = true,
    allowEmptyObject = true,
    allowNull = false,
    allowUndefined = false,
    allowNaN = false,
  } = {},
) => {
  // general false value checks
  // const isNull = data === null
  // const isUndefined = data === undefined
  // const isNaN = Number.isNaN(data)
  const isNull = _isNull(data);
  const isUndefined = _isUndefined(data);
  const isNaN = _isNaN(data);

  // number checks
  // const isNumber = typeof data === 'number'
  const isNumber = _isNumber(data);
  const isZero = data === 0;
  const isNegative = isNumber && data < 0;
  const isValidNumber = isNaN
    ? false
    : isNumber
    ? isNegative
      ? allowNegative
      : isZero
      ? allowZero
      : true
    : false;

  // string checks
  // const isString = typeof data === 'string'
  const isString = _isString(data);
  const isEmptyString = data === '';
  const isValidString = isString
    ? isEmptyString
      ? allowEmptyString
      : true
    : false;

  // boolean checks
  // const isBoolean = typeof data === 'boolean'
  const isBoolean = _isBoolean(data);
  const isFalse = data === false;
  const isValidBoolean = isBoolean ? (isFalse ? allowFalse : true) : false;

  // array checks
  // const isArray = data instanceof Array
  const isArray = _isArray(data);
  const isEmptyArray = isArray && _isEmpty(data);
  const isValidArray = isArray
    ? isEmptyArray
      ? allowEmptyArray
      : true
    : false;

  // array buffer checks
  const isArrayBuffer = _isArrayBuffer(data);
  const isValidArrayBuffer = isArrayBuffer;

  // object checks
  const isObject = _isObjectLike(data) && !isArray;
  const isEmptyObject = !!(isObject && _isEmpty(data));
  const isValidObject = isObject
    ? isEmptyObject
      ? allowEmptyObject
      : true
    : false;

  // function checks
  // const isFunction = typeof data === 'function'
  const isValidFunction = _isFunction(data);

  const isNormalFunction =
    isValidFunction && data.constructor.name === 'Function';
  const isValidNormalFunction = allowNormalFunction && isNormalFunction;

  const isAsyncFunction =
    isValidFunction && data.constructor.name === 'AsyncFunction';
  const isValidAsyncFunction = allowAsyncFunction && isAsyncFunction;

  const isGeneratorFunction =
    isValidFunction && data.constructor.name === 'GeneratorFunction';
  const isValidGeneratorFunction =
    allowGeneratorFunction && isGeneratorFunction;

  // date checks
  // const isFunction = typeof data === 'function'
  const _date = new Date(data);
  const isPossibleDate = isString || isNumber || _isDate(data);
  const isValidDate =
    isPossibleDate && _isDate(_date) && _date.toString() !== 'Invalid Date';

  const isValidData =
    (allowUndefined && isUndefined) ||
    (allowNull && isNull) ||
    (allowNaN && isNaN) ||
    (allowString && isValidString) ||
    (allowNumber && isValidNumber) ||
    (allowBoolean && isValidBoolean) ||
    (allowArray && isValidArray) ||
    (allowArrayBuffer && isValidArrayBuffer) ||
    (allowObject && isValidObject) ||
    (allowDate && isValidDate) ||
    (allowFunction &&
      ((allowNormalFunction && isValidNormalFunction) ||
        (allowAsyncFunction && isValidAsyncFunction) ||
        (allowGeneratorFunction && isValidGeneratorFunction)));

  return isValidData;
};

export default validateData;
export const isValidData = data => {
  return validateData(data, {
    allowNumber: true,
    allowString: true,
    allowBoolean: true,
    allowArray: true,
    allowArrayBuffer: true,
    allowObject: true,
    allowFunction: true,
    allowNormalFunction: true,
    allowAsyncFunction: true,
    allowGeneratorFunction: true,
    allowDate: true,
    allowNegative: true,
    allowFalse: true,
    allowZero: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isNonFalsyOrNonEmptyData = data => {
  return validateData(data, {
    allowNumber: true,
    allowString: true,
    allowBoolean: true,
    allowArray: true,
    allowObject: true,
    allowFunction: true,
    allowNormalFunction: true,
    allowAsyncFunction: true,
    allowGeneratorFunction: true,
    allowDate: true,
    allowNegative: true,
    allowArrayBuffer: true,
    allowFalse: false,
    allowZero: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isNonEmptyData = data => {
  return validateData(data, {
    allowNumber: true,
    allowString: true,
    allowBoolean: true,
    allowArray: true,
    allowObject: true,
    allowFunction: true,
    allowNormalFunction: true,
    allowAsyncFunction: true,
    allowGeneratorFunction: true,
    allowDate: true,
    allowNegative: true,
    allowArrayBuffer: true,
    allowEmptyArray: true,
    allowEmptyObject: true,
    allowNaN: true,
    allowFalse: true,
    allowZero: false,
    allowNull: false,
    allowUndefined: false,
    allowEmptyString: false,
  });
};
export const isValidNonEmptyData = data => {
  return validateData(data, {
    allowNumber: true,
    allowString: true,
    allowBoolean: true,
    allowArray: true,
    allowObject: true,
    allowFunction: true,
    allowNormalFunction: true,
    allowAsyncFunction: true,
    allowGeneratorFunction: true,
    allowDate: true,
    allowNegative: true,
    allowArrayBuffer: true,
    allowFalse: true,
    allowZero: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isValidNumber = data => {
  return validateData(data, {
    allowNumber: true,
    allowZero: true,
    allowArrayBuffer: false,
    allowNegative: true,
    allowString: false,
    allowBoolean: false,
    allowArray: false,
    allowObject: false,
    allowFunction: false,
    allowDate: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isValidPositiveNumber = data => {
  return validateData(data, {
    allowNumber: true,
    allowZero: true,
    allowArrayBuffer: false,
    allowNegative: false,
    allowString: false,
    allowBoolean: false,
    allowArray: false,
    allowObject: false,
    allowFunction: false,
    allowDate: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isValidNonEmptyNumber = data => {
  return validateData(data, {
    allowNumber: true,
    allowNegative: true,
    allowArrayBuffer: false,
    allowZero: false,
    allowString: false,
    allowBoolean: false,
    allowArray: false,
    allowObject: false,
    allowFunction: false,
    allowDate: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isValidString = data => {
  return validateData(data, {
    allowString: true,
    allowEmptyString: true,
    allowArrayBuffer: false,
    allowNumber: false,
    allowBoolean: false,
    allowArray: false,
    allowObject: false,
    allowFunction: false,
    allowDate: false,
    allowZero: false,
    allowNegative: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isValidNonEmptyString = data => {
  return validateData(data, {
    allowString: true,
    allowArrayBuffer: false,
    allowEmptyString: false,
    allowNumber: false,
    allowBoolean: false,
    allowArray: false,
    allowObject: false,
    allowFunction: false,
    allowDate: false,
    allowZero: false,
    allowNegative: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyArray: false,
    allowEmptyObject: false,
  });
};
export const isValidArray = data => {
  return validateData(data, {
    allowArray: true,
    allowEmptyArray: true,
    allowArrayBuffer: false,
    allowNumber: false,
    allowString: false,
    allowBoolean: false,
    allowObject: false,
    allowFunction: false,
    allowDate: false,
    allowZero: false,
    allowNegative: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyObject: false,
  });
};
export const isValidNonEmptyArray = data => {
  return validateData(data, {
    allowArray: true,
    allowArrayBuffer: false,
    allowEmptyArray: false,
    allowNumber: false,
    allowString: false,
    allowBoolean: false,
    allowObject: false,
    allowFunction: false,
    allowDate: false,
    allowZero: false,
    allowNegative: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyObject: false,
  });
};
export const isValidNonEmptyNumberArray = data => {
  return (
    validateData(data, {
      allowArray: true,
      allowArrayBuffer: false,
      allowEmptyArray: false,
      allowNumber: false,
      allowString: false,
      allowBoolean: false,
      allowObject: false,
      allowFunction: false,
      allowDate: false,
      allowZero: false,
      allowNegative: false,
      allowFalse: false,
      allowNull: false,
      allowUndefined: false,
      allowNaN: false,
      allowEmptyString: false,
      allowEmptyObject: false,
    }) && data.every(isValidNumber)
  );
};
export const isValidNonEmptyStringArray = data => {
  return (
    validateData(data, {
      allowArray: true,
      allowEmptyArray: false,
      allowArrayBuffer: false,
      allowNumber: false,
      allowString: false,
      allowBoolean: false,
      allowObject: false,
      allowFunction: false,
      allowDate: false,
      allowZero: false,
      allowNegative: false,
      allowFalse: false,
      allowNull: false,
      allowUndefined: false,
      allowNaN: false,
      allowEmptyString: false,
      allowEmptyObject: false,
    }) && data.every(isValidString)
  );
};
export const isValidObject = data => {
  return validateData(data, {
    allowObject: true,
    allowEmptyObject: true,
    allowArrayBuffer: false,
    allowNumber: false,
    allowString: false,
    allowBoolean: false,
    allowArray: false,
    allowFunction: false,
    allowDate: false,
    allowZero: false,
    allowNegative: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};
export const isValidNonEmptyObject = data => {
  return validateData(data, {
    allowObject: true,
    allowArrayBuffer: false,
    allowEmptyObject: false,
    allowNumber: false,
    allowString: false,
    allowBoolean: false,
    allowArray: false,
    allowFunction: false,
    allowDate: false,
    allowZero: false,
    allowNegative: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};
export const isValidDate = data => {
  return validateData(data, {
    allowDate: true,
    allowArrayBuffer: false,
    allowObject: false,
    allowEmptyObject: false,
    allowNumber: false,
    allowString: false,
    allowBoolean: false,
    allowArray: false,
    allowFunction: false,
    allowZero: false,
    allowNegative: false,
    allowFalse: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};

export const isValidBoolean = data => {
  return validateData(data, {
    allowBoolean: true,
    allowFalse: true,
    allowArrayBuffer: false,
    allowDate: false,
    allowObject: false,
    allowEmptyObject: false,
    allowNumber: false,
    allowString: false,
    allowArray: false,
    allowFunction: false,
    allowZero: false,
    allowNegative: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};

export const isValidFunction = data => {
  return validateData(data, {
    allowFunction: true,
    allowNormalFunction: true,
    allowAsyncFunction: true,
    allowGeneratorFunction: true,
    allowArrayBuffer: false,
    allowBoolean: false,
    allowFalse: false,
    allowDate: false,
    allowObject: false,
    allowEmptyObject: false,
    allowNumber: false,
    allowString: false,
    allowArray: false,
    allowZero: false,
    allowNegative: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};

export const isValidNormalFunction = data => {
  return validateData(data, {
    allowFunction: true,
    allowNormalFunction: true,
    allowAsyncFunction: false,
    allowGeneratorFunction: false,
    allowArrayBuffer: false,
    allowBoolean: false,
    allowFalse: false,
    allowDate: false,
    allowObject: false,
    allowEmptyObject: false,
    allowNumber: false,
    allowString: false,
    allowArray: false,
    allowZero: false,
    allowNegative: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};

export const isValidAsyncFunction = data => {
  return validateData(data, {
    allowFunction: true,
    allowAsyncFunction: true,
    allowNormalFunction: false,
    allowGeneratorFunction: false,
    allowArrayBuffer: false,
    allowBoolean: false,
    allowFalse: false,
    allowDate: false,
    allowObject: false,
    allowEmptyObject: false,
    allowNumber: false,
    allowString: false,
    allowArray: false,
    allowZero: false,
    allowNegative: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};

export const isValidGeneratorFunction = data => {
  return validateData(data, {
    allowFunction: true,
    allowGeneratorFunction: true,
    allowNormalFunction: false,
    allowAsyncFunction: false,
    allowArrayBuffer: false,
    allowBoolean: false,
    allowFalse: false,
    allowDate: false,
    allowObject: false,
    allowEmptyObject: false,
    allowNumber: false,
    allowString: false,
    allowArray: false,
    allowZero: false,
    allowNegative: false,
    allowNull: false,
    allowUndefined: false,
    allowNaN: false,
    allowEmptyString: false,
    allowEmptyArray: false,
  });
};
