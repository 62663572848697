import React from 'react';
import {PropTypes} from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {FormattedMessage, FormattedDate, injectIntl, intlShape} from 'react-intl';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import FiberManualRecord from 'material-ui/svg-icons/av/fiber-manual-record';
import FontIcon from 'material-ui/FontIcon';
import {green500, red500} from 'material-ui/styles/colors';
import ReportPageBase from '../common/ReportPageBase';
import * as consultantActions from '../../actions/consultantActions';

import Alert from '../global/Alert';
import { Element, scroller } from 'react-scroll';

import * as personActions from '../../actions/personActions';
import * as productKitActions from '../../actions/productKitActions';

import ProductKitDetail from './ProductKitDetail';

class ProductKitReportPage extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let {usuario, codePerson} = this.props;
    this.props.actions.getPerson(codePerson, usuario).then((action) => {
      let person = action.value;
      this.props.actions.getListProductKit(person, usuario);
      this.props.consultantActions.getDigitalStoreDetails(usuario, person);
    });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  handleRowClick = (row) => {
    let {usuario, person} = this.props;
    this.props.actions.getProductKit(row._id, person.currentCycle, usuario)
      .then(response => {
        if (response.action.payload)
          scroller.scrollTo('productKitDetail', {
            duration: 300,
            smooth: true
          });
        else {
          let alertOptions = {
            title: this.props.intl.formatMessage({id: 'mensagens.titulo.kit.desejo'}),
            content: this.props.intl.formatMessage({id: 'mensagens.conteudo.kit.desejo.nenhum'})
          };
          this.alert.handleOpen(alertOptions);
        }
      });
  };

  getYear = () => {
    let now = new Date();
    let { person } = this.props;

    return person.currentCycle // eslint-disable-next-line no-mixed-operators
      && parseInt(person.currentCycle.toString().substr(0, 4)) // eslint-disable-next-line no-mixed-operators
      || now.getFullYear();
  };

  levelColorFormatter = (cell) => {
    if (cell.nm_rgba_red && cell.nm_rgba_green && cell.nm_rgba_blue && cell.nm_rgba_alpha)
      return <FiberManualRecord
        color={`rgba(${cell.nm_rgba_red}, ${cell.nm_rgba_green}, ${cell.nm_rgba_blue}, ${cell.nm_rgba_alpha})`}
      />;
    else
      return '';
  };

  nextOrderFormatter = (cell) => {
    let nextOrderType = cell && cell.next_order_type ? cell.next_order_type : 0;

    switch (nextOrderType) {
      case 1:
        return <FormattedMessage
          id="table.column.proximo.pedido"
          defaultMessage="Próximo Pedido"
          description="coluna proximo pedido"
        />;
      case 3: {
        if (cell.cycles_remaining && cell.cycles_remaining > 0)
          return <FormattedMessage
            id="table.column.proximo.ciclo"
            defaultMessage="Daqui {value} ciclos"
            description="coluna proximo ciclo"
            values={{value: cell.cycles_remaining}}
          />;
        else
          return <FormattedDate value={cell.next_order}/>;
      }
      default:
        if (cell.next_order)
          return <FormattedDate value={cell.next_order}/>;
        else
          return '-';
    }
  };

  orderDateFormatter = (cell) => {
    return cell && cell.order_date ? <FormattedDate value={cell.order_date}/> : '-';
  };

  orderCodeFormatter = (cell) => {
    return cell && cell.order_code ? cell.order_code : '-';
  };

  purchasePermissionFormatter = (cell) => {
    switch (cell) {
      case 1:
        return <FontIcon className="pe-7s-check" color={green500}/>;
      default:
        return <FontIcon className="pe-7s-close-circle" color={red500}/>;
    }
  };

  render() {
    let {person, productKit, intl, consultant = {} } = this.props;
    let options = {
      onRowClick: this.handleRowClick
    };
    const digitalStore = consultant.digitalStore || {}
    return (
      <div>
        <ReportPageBase
          commercialStatus={digitalStore.commercialStatus}
          title={
            <h3 className="product-kit-report-page-title paper-header">
              <FormattedMessage
                id="extratos.titulo.kit.desejo"
                defaultMessage="Extrato de Kit Desejo - {year}"
                description="Titulo Kit Desejo"
                values={{year: this.getYear()}}
              />
            </h3>
          }
          person={person}
          onNewService={this.handleNewService}
          intl={intl}
        >
          <div>
            {productKit.data.length > 0 &&
            <BootstrapTable
              containerClass="product-kit-report-page-table-container"
              headerContainerClass="product-kit-report-page-table-header"
              bodyContainerClass="product-kit-report-page-table-body"
              data={productKit.data}
              options={options}
            >
              <TableHeaderColumn
                dataField="color"
                dataFormat={this.levelColorFormatter}
              />
              <TableHeaderColumn
                width="30%"
                dataField="cd_product"
                isKey={true}
                dataFormat={(cell, row) => `${cell} - ${row.no_product}`}
              >
                <FormattedMessage
                  id="table.column.kitdesejo"
                  defaultMessage="Kit Desejo"
                  description="coluna kit desejo"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField="cd_purchased_allowed"
                                 dataFormat={this.purchasePermissionFormatter}>
                <FormattedMessage
                  id="table.column.compra.permitida"
                  defaultMessage="Compra Permitida"
                  description="coluna compra permitida"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField="order" dataFormat={this.orderDateFormatter}>
                <FormattedMessage
                  id="table.column.data.compra"
                  defaultMessage="Data da Compra"
                  description="coluna data compra"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="order" dataFormat={this.orderCodeFormatter}>
                <FormattedMessage
                  id="table.column.pedido"
                  defaultMessage="Pedido"
                  description="coluna pedido"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField="order" dataFormat={this.nextOrderFormatter}>
                <FormattedMessage
                  id="table.column.proxima.compra"
                  defaultMessage="Próxima Compra"
                  description="coluna proxima compra"
                />
              </TableHeaderColumn>
            </BootstrapTable>
            }
            {productKit.productDetail &&
              <Element name="productKitDetail">
                <ProductKitDetail productKitDetail={productKit.productDetail}/>
              </Element>
            }
            <Alert ref={alert => this.alert = alert}/>
          </div>
        </ReportPageBase>
      </div>
    );
  }
}

ProductKitReportPage.propTypes = {
  intl: intlShape,
  usuario: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  productKit: PropTypes.object.isRequired,
  codePerson: PropTypes.string.isRequired,
  consultant: PropTypes.object,
  consultantActions: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    usuario: state.usuario,
    person: state.person,
    productKit: state.productKit,
    codePerson: ownProps.params.code,
    consultant: state.consultant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, personActions, productKitActions), dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductKitReportPage));
