/* eslint-disable no-unused-vars */
// ignore no-unused-vars
import request from 'superagent';
import _ from 'lodash';

const serviceApi = process.env.REACT_APP_SERVICE_URL_API;
// const mockApiAtendimento = process.env.REACT_APP_SERVICE_URL_MOCK_API;
const interfacesApiAtendimento = process.env.REACT_APP_SERVICE_GESTAO_COMERCIAL_INTERFACES_API;
const ORDERS_API = process.env.REACT_APP_SERVICE_ORDERS_API;

function getPersonJSON(person) {
  return { consultant_id: person.id_consult, consultant_country_id: person.id_country, consultant_commercial_model_id: person.id_commercial_model };
}

function getPersonByCpf(person) {
  return { cpf: person.nm_document, consultant_country_id: person.id_country, consultant_commercial_model_id: person.id_commercial_model };
}

function getCyclesJSON(person, selectedCycleRange) {
  return {
    cycle: person.currentCycle
      ? parseInt(person.currentCycle)
      : null,
    cycle_start: selectedCycleRange.start
      ? parseInt(selectedCycleRange.start)
      : person.periodStartCycle
        ? parseInt(person.periodStartCycle)
        : null,
    cycle_end: selectedCycleRange.end
      ? parseInt(selectedCycleRange.end)
      : person.periodEndCycle
        ? parseInt(person.periodEndCycle)
        : null
  }
}

function getCyclesResponse(person, selectedCycleRange) {
  return {
    cycle: selectedCycleRange.end
      ? parseInt(selectedCycleRange.end)
      : person.periodEndCycle
        ? parseInt(person.periodEndCycle)
        : null,
    cycle_start: selectedCycleRange.start
      ? parseInt(selectedCycleRange.start)
      : person.periodStartCycle
        ? parseInt(person.periodStartCycle)
        : null,
    cycle_end: selectedCycleRange.end
      ? parseInt(selectedCycleRange.end)
      : person.periodEndCycle
        ? parseInt(person.periodEndCycle)
        : null
  }
}

function getCommercialStructureJSON(person) {
  let structure = {
    commercial_structure_country_id: person.id_country,
    commercial_structure_market_management_id: person.commercial_structure.nm_market_management || '',
    commercial_structure_strategic_region_id: person.commercial_structure.nm_strategic_region,
    commercial_structure_sales_management_id: person.commercial_structure.nm_sales_region,
    commercial_structure_sector_id: person.commercial_structure.nm_sector
  };

  if (person.commercial_structure.nm_group)
    structure.commercial_structure_group_id = person.commercial_structure.nm_group;

  return structure;
}

const getTokenExternalApp = () => {
  const checkProductiveEnvironment = (process.env.REACT_APP_ENV === "prod");
  const testBody = {
    "user": "100",
    "password": "senha123",
    "grant_type": "password"
  };
  const prodBody = {
    "grant_type": "client_credentials",
    "extraInfo": { "sellerId": "125" }
  };
  return new Promise((resolve, reject) => {
    request
      .post(process.env.REACT_APP_TOKEN_SENSEDIA)
      .set("authorization", process.env.REACT_APP_TOKEN_SENSEDIA_AUTH)
      .set("client_id", process.env.REACT_APP_EXTERNAL_CLIENT_ID)
      .set("content-type", "application/json")
      .send((checkProductiveEnvironment) ? prodBody : testBody)
      .then(res => {
        if (res.status >= 200 && res.status <= 207) {
          resolve((checkProductiveEnvironment) ? res.body.access_token : res.body.accessToken);
        }
      })
      .catch(err => {
        reject(Object.assign({}, err.response));
      });
  });
};

const updateLocalStorage = (item, subItem, value) => {
  let res = JSON.parse(localStorage.getItem(item));
  res[subItem] = value;
  return JSON.stringify(res);
};

const addLocalStorage = async params => {
  localStorage.setItem(
    "structureLevelNaturaWeb",
    params.data.commercial_structure.no_group === "" ? 4 : 5
  );
  localStorage.setItem(
    "structureCodeNaturaWeb",
    params.data.commercial_structure.no_group === ""
      ? params.data.commercial_structure.nm_sector
      : params.data.commercial_structure.nm_group
  );
  localStorage.setItem("personIdNaturaWeb", params.data.id_consult);
  localStorage.setItem("cycleNaturaWeb", params.data.currentCycle);
  localStorage.setItem("accessTokenNaturaWeb", await getTokenExternalApp());
  localStorage.setItem("usuario", updateLocalStorage("usuario", "SECTOR_CODE", params.data.commercial_structure.nm_sector));
  // localStorage.setItem(
  //   "accessTokenNaturaWeb",
  //   JSON.parse(localStorage.getItem("usuario")).accessToken
  // );
};

const getPersonDataFromPromise = (code, usuario) => {
  return new Promise((resolve, reject) => {
    request
      .get(`${serviceApi}/consultant`)
      .set('Accept', 'application/json')
      .set('access_token', usuario.accessToken)
      .set('client_id', usuario.isUserDefault
        ? process.env.REACT_APP_CLIENT_ID_DEFAULT
        : process.env.REACT_APP_CLIENT_ID)
      .query({ "consult_id": JSON.parse(code) })
      .end((err, res) => {
        if (err)
          reject(Object.assign({}, err.response));

        if (res.status === 204 || !res.body)
          resolve(Object.assign({}));
        else {
          addLocalStorage(res.body);
          resolve(Object.assign({}, res.body.data));
        }
      }
      );
  });
};

const getProfitabilityDataFromPromise = (usuario, search) => {
  return new Promise((resolve, reject) => {
    request
      .get(`${interfacesApiAtendimento}/profitabilities`)
      .set('Accept', 'application/json')
      .set('access_token', usuario.accessToken)
      .set('client_id', usuario.isUserDefault
        ? process.env.REACT_APP_CLIENT_ID_DEFAULT
        : process.env.REACT_APP_CLIENT_ID)
      .query(search)
      .end((err, res) => {
        if (err)
          resolve(Object.assign([]));
        resolve(Object.assign([], res.body.data));
      });
  })
};

const getCycleAndLevelFromPromise = (usuario, resultOfFirstPromise, data) => {
  return new Promise((resolve, reject) => {
    request
      .get(`${interfacesApiAtendimento}/consultant-levels/${resultOfFirstPromise.id_consult}`)
      .set('Accept', 'application/json')
      .set('access_token', usuario.accessToken)
      .set('client_id', usuario.isUserDefault
        ? process.env.REACT_APP_CLIENT_ID_DEFAULT
        : process.env.REACT_APP_CLIENT_ID)
      .query({
        country_id: resultOfFirstPromise.id_country,
        consultant_commercial_model_id: resultOfFirstPromise.id_commercial_model,
        date: data,
        cycle: resultOfFirstPromise.currentCycle
      })
      .end((err, res) => {
        if (err)
          reject(Object.assign({}, err.response));

        if (res.status === 204)
          resolve(Object.assign({}));
        else
          resolve(Object.assign({}, res.body.data));
      }
      );
  })
};

const getArrayOfRoles = (array) => {
  return (Array.isArray(array) && array.length > 0)
    ? array.map(_ => _.nm_role)
    : [2];
};

const objectToBeFound = (resultOfFirstPromise, currentDate, group, resultOfSecondPromise) => {
  try {
    let foundObject = {
      person_id: resultOfFirstPromise.id_consult,
      channel_id: 8,
      source_system_id: 4,
      date_validity: currentDate,
      cycle_validity: resultOfFirstPromise.currentCycle,
      commercial_structure_country_id: resultOfFirstPromise.id_country,
      commercial_structure_market_management_id: resultOfFirstPromise.commercial_structure.nm_market_management || '',
      commercial_structure_strategic_region_id: resultOfFirstPromise.commercial_structure.nm_strategic_region,
      commercial_structure_sales_management_id: resultOfFirstPromise.commercial_structure.nm_sales_region,
      commercial_structure_sector_id: resultOfFirstPromise.commercial_structure.nm_sector,
      country_id: resultOfFirstPromise.id_country,
      consultant_commercial_model_id: resultOfFirstPromise.id_commercial_model,
      role_id: getArrayOfRoles(resultOfFirstPromise.dc_roles),
      consultant_segments: ''
    };

    if (!_.isEmpty(resultOfFirstPromise.commercial_structure.nm_group))
      foundObject.commercial_structure_group_id = resultOfFirstPromise.commercial_structure.nm_group;

    if (resultOfSecondPromise && !_.isEmpty(resultOfSecondPromise[0].cd_plano_atual) && !_.isEmpty(resultOfSecondPromise[0].cd_nivel_atual)) {
      let cd_plano_atual = resultOfSecondPromise[0].cd_plano_atual
      let cd_nivel_atual = resultOfSecondPromise[0].cd_nivel_atual
      for (let key in resultOfSecondPromise) {
        if (resultOfSecondPromise[key].nm_ciclo_operacional_termino === '0' && resultOfSecondPromise[key].cd_plano_atual === '1') {
          cd_plano_atual = resultOfSecondPromise[key].cd_plano_atual
          cd_nivel_atual = resultOfSecondPromise[key].cd_nivel_atual
        }
      }
      foundObject.consultant_levels = `career_progression_plan_id=${cd_plano_atual},level_id=${cd_nivel_atual}`;
    }

    return foundObject;

  } catch (err) {
    return {};
  }
};

class personApi {

  static getPerson = async (code, usuario) => {
    /* First Promise Resolve */
    const resultOfFirstPromise = await getPersonDataFromPromise(code, usuario);
    /* Formating date to ISO pattern */
    const date = new Date();
    const currentDate = date.toISOString();
    /* Second Promise Resolve */
    const resultOfSecondPromise = await getCycleAndLevelFromPromise(usuario, resultOfFirstPromise, currentDate);
    const group = resultOfFirstPromise
      .commercial_structure
      .no_group
      .split(' ', 2);
    /* Third Promise Resolve */
    const resultOfThirdPromise = await getProfitabilityDataFromPromise(usuario, objectToBeFound(resultOfFirstPromise, currentDate, group, resultOfSecondPromise));
    /* Return a object that contains the result if this cn has or not financial pendencies */
    /*     const checkIfTheCnHasAnyFinancialPendency = {
      financial_situation: (resultOfSenSediaPromise.debtList.length > 0) ? 'Inadimplência' : 'Adimplência'
    } */
    /* Max Value BetweenProfitabilityArray*/
    const maxValueBetweenFrofitabilityArray = {
      pc_profitability: Math
        .max
        .apply(null, resultOfThirdPromise.map(_ => _.pc_profitability))
    };
    const leaderInfo = [];
    for (let key in resultOfSecondPromise) {
      leaderInfo.push(resultOfSecondPromise[key]);
    }
    const result = {
      ...resultOfFirstPromise,
      ...maxValueBetweenFrofitabilityArray,
      leaderInfo: [...leaderInfo]
    }
    /* Join All Objects and return a single object to by rendering by view */
    return result;
  };

  static getThermometer = (person, selectedCycleRange, usuario) => {
    return new Promise((resolve, reject) => {
      request
        .get(`${serviceApi}/consultantThermometer`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault
          ? process.env.REACT_APP_CLIENT_ID_DEFAULT
          : process.env.REACT_APP_CLIENT_ID)
        .query({
          ...getPersonJSON(person),
          ...getCommercialStructureJSON(person),
          ...getCyclesJSON(person, selectedCycleRange)
        })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign({}));
          else

            resolve(Object.assign({}, res.body.data));
        }
        );
    });
  };

  static getLeaderThermometer = (person, selectedCycleRange, usuario) => {
    return { "message": "Consultant Level not found" }
  };

  static getScoreCycles = (person, selectedCycleRange, usuario) => {
    return new Promise((resolve, reject) => {
      request
        .get(`${serviceApi}/score-cycles`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault
          ? process.env.REACT_APP_CLIENT_ID_DEFAULT
          : process.env.REACT_APP_CLIENT_ID)
        .query({
          ...getPersonJSON(person),
          ...getCommercialStructureJSON(person),
          ...getCyclesResponse(person, selectedCycleRange)
        })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign({}));
          else
            resolve(Object.assign({}, res.body.data));
        }
        );
    });
  };

  static getCycleOrders = (person, orders, usuario, cycle) => {
    let p1 = new Promise((resolve, reject) => {
      request
        .get(`${ORDERS_API}/sellers/${person.id_consult}/orders/capta`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault
          ? process.env.REACT_APP_CLIENT_ID_DEFAULT
          : process.env.REACT_APP_CLIENT_ID)
        .query({
          'ordersId': orders,
          '_offset': 0,
          '_limit': 20
        })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign([], res.body.orders));
        }
        );
    });

    let p2 = new Promise((resolve, reject) => {
      request.get(`${ORDERS_API}/sellers/${person.id_consult}/orders/ecommerce`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault
          ? process.env.REACT_APP_CLIENT_ID_DEFAULT
          : process.env.REACT_APP_CLIENT_ID)
        .query({ cycleId: cycle })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign([], res.body.orders));
        }
        );
    });

    let promiseResult = [p1, p2];
    return Promise.all(promiseResult);
  };

  static getCycleStatus = (person, cycle, usuario) => {
    let p1 = new Promise((resolve, reject) => {
      request
        .get(`${ORDERS_API}/sellers/${person.id_consult}/orders/status`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault
          ? process.env.REACT_APP_CLIENT_ID_DEFAULT
          : process.env.REACT_APP_CLIENT_ID)
        .query({ cycleId: cycle })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign([], res.body.orders));
        }
        );
    });

    let p2 = new Promise((resolve, reject) => {
      request.get(`${ORDERS_API}/sellers/${person.id_consult}/orders/ecommerce`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault
          ? process.env.REACT_APP_CLIENT_ID_DEFAULT
          : process.env.REACT_APP_CLIENT_ID)
        .query({ cycleId: cycle })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign([], res.body.orders));
        }
        );
    });

    let promiseResult = [p1, p2];
    return Promise.all(promiseResult);

  };

  static getMonthlyPayments = (person, user) => {
    return new Promise((resolve, reject) => {
      request
        .get(`${serviceApi}/monthly-payment`)
        .set({
          'Accept': 'application/json',
          'access_token': user.accessToken,
          'client_id': user.isUserDefault
            ? process.env.REACT_APP_CLIENT_ID_DEFAULT
            : process.env.REACT_APP_CLIENT_ID
        })
        .query({
          ...getPersonByCpf(person)
        })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign({}, res.body.data));
        }
        );
    });
  };
}

export default personApi;
