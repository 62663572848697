import React from "react";
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AppLoader from "../../externalAppLoader/AppLoader";
import RaisedButton from 'material-ui/RaisedButton';

class ExternalReport extends React.Component {
  handleNewService = () => {
    // eslint-disable-next-line react/prop-types
    this.props.router.push('/');
  };

  render() {
    const { customNavigation, location } = this.props;
    const currentPage = customNavigation.find(item => item.link === location.pathname);
    const pageTitle = (currentPage) ? currentPage.label.replace(/^"(.+(?="$))"$/, '$1') : '';

    return (
      <div>
        <div className="external-report-page-base-title-container">
          <h3 style={{display: "inline-block"}}>
            {pageTitle}
          </h3>
          <div>
            <RaisedButton
              backgroundColor="#7080a0"
              labelColor="#fff"
              label={
                <FormattedMessage
                  id="botao.novo.atendimento"
                  defaultMessage="Novo Central de Relacionamento"
                  description="Novo Central de Relacionamento"
                />
              }
              onTouchTap={this.handleNewService}
            />
        </div>
        </div>
        <AppLoader />
        {/* TODO - MICROFRONTEND DINAMICO */}
      </div>
    );
  }
}

ExternalReport.propTypes = {
  customNavigation: PropTypes.array,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    customNavigation: state.externalApps.customNavigation,
  };
}

export default connect(mapStateToProps)(ExternalReport);
