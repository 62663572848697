import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import ReportPageBase from '../common/ReportPageBase';
import Paper from 'material-ui/Paper';

import globalStyles from '../../styles';

import * as consultantActions from '../../actions/consultantActions';
import * as personActions from '../../actions/personActions';
import * as leaderKitActions from '../../actions/leaderKitActions';

import LeaderKitStatusDialog from './widgets/LeaderKitStatusDialog';
import LeaderKitDetailDialog from './widgets/LeaderKitDetailDialog';
import LeaderKitStatusHistoricDialog from './widgets/LeaderKitStatusHistoricDialog';

class LeaderKitReportPage extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let { usuario, codePerson } = this.props;
    this.props.actions.getPerson(codePerson, usuario).then((action) => {
      let person = action.value;
      this.props.actions.getKitMaterialCycle(person, usuario);
      this.props.actions.getStatusMaterialCycle('C', person, usuario);
      this.props.consultantActions.getDigitalStoreDetails(usuario, person);
    });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  customClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText={this.props.intl.formatMessage({ id: 'botao.limpar_consulta' })}
        className='leader-kit-report-page-button'
        onClick={onClick}
      />
    );
  }

  componentDidUpdate(prevProps) {
    let { usuario } = this.props;
    if (this.props.leaderKit.modalOpen !== prevProps.leaderKit.modalOpen
      && (this.props.leaderKit.order !== prevProps.leaderKit.order && this.props.leaderKit.order !== null)) {
      this.props.actions.getDetailsMaterialCycle(this.props.person.id_consult, this.props.leaderKit.order, usuario);
    }
  }

  leaderKitStatusDialog(cell, row, props) {

    if (row.ZZCAMPO_MOD.toUpperCase() === 'ATIVADO')
      this.body = props.intl.formatMessage({ id: 'body.dialog.status.ativo' });
    else
      this.body = props.intl.formatMessage({ id: 'body.dialog.status.inativo' });

    return <LeaderKitStatusDialog
      title={props.intl.formatMessage({ id: 'title.dialog.status' })}
      body={this.body}
      label={props.intl.formatMessage({ id: 'title.dialog.status' })}
      row={row}
      props={props}
    />;
  }

  leaderKitDetailDialog = (cell, row, props) => {
    return <LeaderKitDetailDialog
      title={props.intl.formatMessage({ id: 'title.dialog.leader.kit.detail' })}
      row={row}
      props={props}
    />
  };

  dateFormatter = (row) => {
    const { leaderKit: { statusMaterialCycle } } = this.props;
    moment.locale('pt-br');
    let data = moment(statusMaterialCycle[0].ZZDATA_ULT_MOD).format('L');
    let time = moment(statusMaterialCycle[0].ZZHORA_ULT_MOD.replace(':', ''), "hmm").format("HH:mm");
    return row ? `${data} ${time}` : "N/A";
  };

  statusFormatter = (row) => {
    const { intl } = this.props;
    if (row === 'ATIVADO') {
      return intl.formatMessage({ id: 'table.column.ativo' });
    } else if (row === 'DESATIVADO') {
      return intl.formatMessage({ id: 'table.column.inativo' });
    }
  };

  leaderKitStatusHistoryDialog = (cell, row, props) => {
    return <LeaderKitStatusHistoricDialog
      label={props.intl.formatMessage({ id: 'table.column.ver.mais' })}
      title={props.intl.formatMessage({ id: 'title.dialog.status.history' })}
      row={row}
      props={props}
    />
  };

  kitMaterialCycleListFormatted = (data) => {
    const { intl } = this.props;
    const listFormattedByStatus = data.filter(item => item.statusPedido !== "1");
    listFormattedByStatus.map(item => {
        var listFormatted = Object.assign({}, item);
        if (listFormatted.cancelado === 'X') {
            listFormatted.descStatusPedido = intl.formatMessage({ id: 'table.column.cancelado' });
        }
        return listFormatted;
    });
    return listFormattedByStatus.sort((a,b) => {return new Date(b.dataPedido) - new Date(a.dataPedido)});
  }

  render() {
    let { person, leaderKit, intl, consultant = {} } = this.props;

    let options = {
      noDataText: this.props.intl.formatMessage({ id: 'table.empty.data' }),
      clearSearch: true,
      clearSearchBtn: this.customClearButton,
      hideSizePerPage: true,
      sizePerPage: 5,
      paginationSize: 3,
      prePage: this.props.intl.formatMessage({ id: 'geral.anterior' }),
      nextPage: this.props.intl.formatMessage({ id: 'geral.proximo' }),
    };

    const digitalStore = consultant.digitalStore || {}
    return (
      <div>
        <ReportPageBase
          commercialStatus={digitalStore.commercialStatus}
          person={person}
          onNewService={this.handleNewService}
          intl={intl}
        >
          <div>
            {leaderKit.statusMaterialCycle &&
              <BootstrapTable
                containerClass="product-kit-report-page-table-container"
                headerContainerClass="product-kit-report-page-table-header"
                bodyContainerClass="product-kit-report-page-table-body"
                data={leaderKit.statusMaterialCycle}
                options={options}
              >
                <TableHeaderColumn width="30%" dataField="ZZCAMPO_MOD"
                  isKey={true}
                  dataFormat={this.statusFormatter}
                >
                  <FormattedMessage
                    id="table.column.status"
                    defaultMessage="Status"
                    description="Coluna Status"
                  />
                </TableHeaderColumn>
                <TableHeaderColumn width="20%" dataField="ZZDATA_ULT_MOD" dataFormat={this.dateFormatter}>
                  <FormattedMessage
                    id="table.column.desde"
                    defaultMessage="Desde"
                    description="Coluna Desde"
                  />
                </TableHeaderColumn>
                <TableHeaderColumn width="20%" dataField="ZZRESPONSAVEL_ALTERACAO">
                  <FormattedMessage
                    id="table.column.alterado.por"
                    defaultMessage="Alterado Por"
                    description="Coluna Alterado Por"
                  />
                </TableHeaderColumn>
                <TableHeaderColumn width="20%" dataField="ds_updated"
                  formatExtraData={this.props}
                  dataFormat={this.leaderKitStatusDialog}>
                  <FormattedMessage
                    id="table.column.alterar"
                    defaultMessage="Alterar"
                    description="Coluna Alterar"
                  />
                </TableHeaderColumn>
                <TableHeaderColumn width="20%" dataField="see_more"
                  formatExtraData={this.props}
                  dataFormat={this.leaderKitStatusHistoryDialog}>
                </TableHeaderColumn>
              </BootstrapTable>
            }
          </div>
        </ReportPageBase>
        <div>
          <Paper style={globalStyles.paper}>
            <div style={globalStyles.title}>
              <h3 className="product-kit-report-page-title paper-header">
                <FormattedMessage
                  id="extratos.titulo.kit.lider"
                  defaultMessage="Ciclo do Material"
                  description="Titulo Ciclo do Material"
                />
              </h3>
            </div>
            <div>
              {leaderKit.kitMaterialCycle.length > 0 &&
                <BootstrapTable
                  containerClass="leader-kit-report-page-table-container"
                  headerContainerClass="product-kit-report-page-table-header"
                  bodyContainerClass="product-kit-report-page-table-body"
                  pagination
                  data={this.kitMaterialCycleListFormatted(leaderKit.kitMaterialCycle)}
                  options={options}
                  search={true}
                  searchPlaceholder={this.props.intl.formatMessage({ id: 'table.search.field.ciclo.material' })}
                >
                  <TableHeaderColumn
                    width="30%"
                    dataField="cicloReferente"
                    isKey={true}
                  >
                    <FormattedMessage
                      id="table.column.ciclo.material"
                      defaultMessage="Ciclo do Material"
                      description="Coluna Ciclo do Material"
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn width="10%" dataField="descStatusPedido"
                    searchable={false}>
                    <FormattedMessage
                      id="table.column.situacao"
                      defaultMessage="Etapa"
                      description="Coluna Etapa"
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn width="20%" dataField="cicloFaturado"
                    searchable={false}>
                    <FormattedMessage
                      id="table.column.ciclo"
                      defaultMessage="Ciclo"
                      description="Coluna Ciclo"
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn width="10%" dataField="numeroPedido"
                    searchable={false}
                    formatExtraData={this.props}
                    dataFormat={this.leaderKitDetailDialog}>
                    <FormattedMessage
                      id="table.column.numero.pedido"
                      defaultMessage="N° do Pedido"
                      description="Coluna N° do Pedido"
                    />
                  </TableHeaderColumn>
                  <TableHeaderColumn width="20%" dataField="descricaoKIT"
                    searchable={false}>
                    <FormattedMessage
                      id="table.column.descricao.kit"
                      defaultMessage="Descrição do Kit"
                      description="Coluna Descrição do Kit"
                    />
                  </TableHeaderColumn>
                </BootstrapTable>
              }
            </div>
            <div style={globalStyles.clear} />
          </Paper>
        </div>
      </div>
    );
  }
}

LeaderKitReportPage.propTypes = {
  intl: intlShape,
  usuario: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  leaderKit: PropTypes.object.isRequired,
  codePerson: PropTypes.string.isRequired,
  consultant: PropTypes.object,
  consultantActions: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return {
    usuario: state.usuario,
    person: state.person,
    leaderKit: state.leaderKit,
    codePerson: ownProps.params.code,
    consultant: state.consultant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, personActions, leaderKitActions), dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LeaderKitReportPage));
