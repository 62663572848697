import request  from 'superagent';

const serviceApi = process.env.REACT_APP_SERVICE_URL_API;

function getPersonJSON(person) {
  return {
    person_id: person.id_consult,
    country_id: person.id_country,
    consultant_commercial_model_id: person.id_commercial_model,
    role_id: person.dc_roles.map(role => role.nm_role)
  };
}

function getCommercialStructureJSON(person) {
	let structure = {
		commercial_structure_country_id: person.id_country,
		commercial_structure_market_management_id: person.commercial_structure.nm_market_management || '',
		commercial_structure_strategic_region_id: person.commercial_structure.nm_strategic_region,
		commercial_structure_sales_management_id: person.commercial_structure.nm_sales_region,
		commercial_structure_sector_id: person.commercial_structure.nm_sector
	};

  if (person.commercial_structure.nm_group)
    structure.commercial_structure_group_id = person.commercial_structure.nm_group;

  return structure;
}

class productKitApi {

  static getListProductKit(person, usuario) {
    return new Promise((resolve, reject) => {
      // request.get(`${mockServiceApi}/kit-desejo`)
      request.get(`${serviceApi}/consultant-kits`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .query({...getPersonJSON(person), ...getCommercialStructureJSON(person)})
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign([], res.body.data));
        });
    });
  }

  static getProductKit(productCode, cycle, usuario) {
    return new Promise((resolve, reject) => {
      request.get(`${serviceApi}/consultant-kits/${productCode}`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .query({cycle_validity: parseInt(cycle)})
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          if (res.status === 204)
            resolve(null);
          else
            resolve(Object.assign({}, res.body.data));
        });
    });
  }

}

export default productKitApi;
