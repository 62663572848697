import request  from 'superagent';

const customerServiceApi = process.env.REACT_APP_SERVICE_URL_API;

const getFilterCriteria = (fields) => {
  let filters = {...fields};

  if (!filters.consult_id)
    delete filters.consult_id;

  if (!filters.consult_documentation)
    delete filters.consult_documentation;

  if (!filters.consult_name)
    delete filters.consult_name;

  return filters;
};

class peopleApi {
  static searchConsultant = (fields, usuario) => {
    return new Promise((resolve, reject) => {
      request.get(`${customerServiceApi}/consult`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .query(getFilterCriteria(fields))
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          resolve(Object.assign([], res.body.data));
        });
    });
  };
}

export default peopleApi;
