import request from 'superagent';

const SERVICE_GAMEFICATION_URL_API  = process.env.REACT_APP_SERVICE_GAMEFICATION_URL_API;

class medalApi {

  static getMedal = (sellerId, usuario) => {
    return new Promise((resolve, reject) => {
      request.get(`${SERVICE_GAMEFICATION_URL_API}/medal/`)
        .set('access_token', usuario.accessToken)
        .set('client_id', process.env.REACT_APP_CLIENT_ID_DEFAULT)
        .set('personid',sellerId )
        .then(res => {
          if(res.status >= 200 && res.status <= 207) {
            resolve(Object.assign([], res.body));
          }
        })
        .catch(err => {
          reject(Object.assign({}, err.response));
        });
    });
  };

}

export default medalApi;
