import React from 'react';
import withAppLoader from './app-loader';

const elementId = 'fv-feat-fe';
const appUrl = process.env.REACT_APP_EXTERNAL_URL;

const AppLoader = () => {
  const AppLoader = withAppLoader({
    elementId,
    appUrl: appUrl
  });
  return <AppLoader />;
};

export default AppLoader;
