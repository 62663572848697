import React from 'react';
import {PropTypes} from 'prop-types';
import { FormattedMessage } from 'react-intl';

const SalesScore = (props) => {
  return (
    <div className="growth-plan-report-box-container">
      <div className="growth-plan-report-box">
        <h3>
          <FormattedMessage
            id="consultora.canal.pontos"
            defaultMessage="{points} pts."
            description="Pontuação"
            values={{points: props.getSaleScore('periodDirectSales', props.person)}}
          />
        </h3>
        <h4>
          <FormattedMessage
            id="consultora.canal.venda.direta"
            defaultMessage="Venda Direta"
            description="Venda Direta"
          />
          <span> - </span>
          <FormattedMessage
            id="consultora.canal.bot.bov"
            defaultMessage="Bot/Bov"
            description="Bot/Bov"
          />
        </h4>
      </div>
      <h1>+</h1>
      <div className="growth-plan-report-box">
        <h3>
          <FormattedMessage
            id="consultora.canal.pontos"
            defaultMessage="{points} pts."
            description="Pontuação"
            values={{points: props.getSaleScore('periodNaturaNetwork', props.person)}}
          />
        </h3>
        <h4>
          <FormattedMessage
            id="consultora.canal.rede.natura"
            defaultMessage="Rede Natura"
            description="Rede Natura"
          />
        </h4>
      </div>
      <h1>+</h1>
      <div className="growth-plan-report-box">
        <h3>
          <FormattedMessage
            id="consultora.canal.pontos"
            defaultMessage="{points} pts."
            description="Pontuação"
            values={{points: props.getSaleScore('periodDistributor', props.person)}}
          />
        </h3>
        <h4>
          <FormattedMessage
            id="consultora.canal.distribuidor"
            defaultMessage="Distribuidor"
            description="Distribuidor"
          />
        </h4>
      </div>
      <h1>=</h1>
      <div className="growth-plan-report-box">
        <h3>
          <FormattedMessage
            id="consultora.canal.pontos"
            defaultMessage="{points} pts."
            description="Pontuação"
            values={{points: props.getSaleScore('periodTotalPoints', props.person)}}
          />
        </h3>
        <h4>
          <FormattedMessage
            id="consultora.canal.total"
            defaultMessage="Total"
            description="Total"
          />
        </h4>
      </div>
    </div>
  );
};

SalesScore.propTypes = {
  person: PropTypes.object.isRequired,
  getSaleScore: PropTypes.func.isRequired
};

export default SalesScore;
