export const indicatorsApi = {
  'Volume de pontos': 'index/consultants/2,7,368,370',
  'Volume Presencial': 'index/consultants/86',
  Atividade: 'index/consultants/2',
  Cadastro: 'index/consultants/2',
  Saldo: 'index/balance',
  'Atividade Semente + Bronze': 'index/activity',
  'Ativas Total': 'convergence/structure/consultant',
  'Ativas': 'convergence/structure/consultant',
  'Penetração de Canal': 'index/consultants/368,372,409',
  'Ativas Prata Mais': 'index/silver-plus'
};
