import * as types from '../actions/actionTypes';
import _ from 'lodash/fp';

const initialState = {
  bonusAchievement: null
};

export default function bonusReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.SET_FIELD:
      return _.merge(state, { [payload.field]: payload.value });
    case types.GET_BONUS_ACHIEVEMENT:
      return { ...state, bonusAchievement: payload };
    default:
      return state;
  }
}
