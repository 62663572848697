import request  from 'superagent';
import {Base64} from 'js-base64';

function isAllowAccess(module, usuario) {
  let allowed = false;
  if(module.allow_roles !== undefined) {
    for(let role of module.allow_roles) {
      if(usuario.roles.includes(role)) {
        allowed = true;
        break;
      }
    }
  }
  return allowed;
}

function addPermissionToUser(usuario, modules) {
  let allowModules = [];
  for(let module of modules) {
    if(isAllowAccess(module, usuario)) {
      allowModules.push(module);
    }
    if(module.code === 'ATENDIMENTO') {
      usuario.isUser = isAllowAccess(module, usuario);
    }
  }
  usuario.allowModules = allowModules;

  return usuario;
}

function getUserDefault() {
  let usuario = {
    "accessToken" : "ca1a846a-0b3e-3760-9b2f-2e252f1c91e9",
    "userCode" : "atendente_csu",
    "userName" : "Atendente",
    "userFullName" : "Atendente Csu",
    "email" : "NOT_FOUND",
    "country" : 1,
    "countryName" : "Brasil",
    "businessModel" : 1,
    "businessModelName" : "Consultoras de Beleza",
    "locale" : "pt-BR",
    "roles" : [
        "GESTAO_COMERCIAL_CENTRAL_ATENDE_BR"
    ],
    "allowModules":[],
    "isUser": true,
    "isAuthenticated": true,
    "isUserDefault": true
  };

  return usuario;
}

class AuthApi {

  static accessByToken(accessToken, codigoUsuario){
    return new Promise((resolve, reject) => {
      let usuario = {};
      request.get(process.env.REACT_APP_SERVICE_GESTAO_COMERCIAL_API + '/session')
       .set('Accept', 'application/json')
       .set('access_token', accessToken)
       .set('client_id', process.env.REACT_APP_CLIENT_ID)
       .set('token', accessToken)
       .set('userCode', codigoUsuario)
       .end(function(err, res) {
         if(err) {
           reject(Object.assign({}, err));
         } else if(res.status >= 200 && res.status <= 207) {
           let usuario = Object.assign({}, res.body.data);
           usuario.isAuthenticated = true;

           request.get(process.env.REACT_APP_SERVICE_GESTAO_COMERCIAL_API + '/modules')
            .set('Accept', 'application/json')
            .set('access_token', usuario.accessToken)
            .set('client_id', process.env.REACT_APP_CLIENT_ID)
            .set('id_country', usuario.country)
            .end(function(err, res) {
              if(err) {
                localStorage.setItem('usuario', JSON.stringify(usuario));
                resolve(Object.assign({}, usuario));
              } else {
                let usuarioAndRoles = addPermissionToUser(usuario, res.body.data);
                localStorage.setItem('usuario', JSON.stringify(usuarioAndRoles));
                resolve(usuarioAndRoles);
              }
            });
         } else if(res.status === 401 || res.status === 403) {
           localStorage.removeItem('usuario');
         } else {
           usuario.isAuthenticated = true;
         }
        });

    });
  }

  static decodeUuid(uuid) {
    return new Promise((resolve, reject) => {
        let json = Base64.decode(uuid);
        let user = JSON.parse(json);
        if(user.usuario !== undefined && user.password !== undefined) {
          if(user.usuario === 'atendente_csu') {
            let usuarioDefault = getUserDefault();
            localStorage.setItem('usuario', JSON.stringify(usuarioDefault));
            resolve(usuarioDefault);
          } else {
            reject();
          }

        } else {
          reject();
        }

    });
  }

}

export default AuthApi;
