import request from 'superagent';
import { structureLevels as structureLevel } from '../utils/structureLevels';

const SERVICE_URL_API  = process.env.REACT_APP_SERVICE_URL_API;

class consultantApi {

  static getDigitalStoreDetails = (usuario, params) => {
    const personStructure = (params.commercial_structure.nm_group) ? params.commercial_structure.nm_group : params.commercial_structure.nm_sector;
    const personStructureLevel = (params.commercial_structure.nm_group) ? structureLevel.GROUP : structureLevel.SECTOR;

    return new Promise((resolve, reject) => {
      request.get(`${SERVICE_URL_API}/digitalStore/${params.id_consult}`)
        .set({
          'access_token': usuario.accessToken,
          'client_id': usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID,
          'business-model-id': usuario.businessModel,
          'country': usuario.countryCode,
          'structure-code': personStructure,
          'structure-level-id': personStructureLevel,
          'company': 1
        })
        .then(res => {
          if(res.status >= 200 && res.status <= 207) {
            resolve(res.body);
          }
        })
        .catch(err => {
          reject(Object.assign({}, err.response));
        });
    });
  };

}

export default consultantApi;
