import request from 'superagent';
import { countries as country } from '../utils/countries';
import { structureLevels as structureLevel } from '../utils/structureLevels';

const SERVICE_BONUS_API = process.env.REACT_APP_SERVICE_BONUS_API;

class bonusApi {

  static getBonusAchievement = (cycle, person, usuario) => {
    const personStructure = (person.commercial_structure.nm_group) ? person.commercial_structure.nm_group : person.commercial_structure.nm_sector;
    const personStructureLevel = (person.commercial_structure.nm_group) ? structureLevel.GROUP : structureLevel.SECTOR;
    return new Promise((resolve) => {
      request
        .get(`${SERVICE_BONUS_API}/external/achievement/bonus`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set(
          'client_id',
          usuario.isUserDefault
            ? process.env.REACT_APP_CLIENT_ID_DEFAULT
            : process.env.REACT_APP_CLIENT_ID
        )
        .set('business-model-id', person.id_commercial_model)
        .set('company', 1)
        .set('country', country[person.id_country])
        .set('cycle', cycle)
        .set('structure-code', personStructure)
        .set('structure-level-id', personStructureLevel)
        .then(res => {
          if (res.status >= 200 && res.status <= 207) {
            resolve(res.body);
          }
        })
        .catch(() => {
          resolve(null);
        });
    });
  };

}

export default bonusApi;
