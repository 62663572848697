import * as types from './actionTypes';
import remunerationApi from '../api/remunerationApi';


export const setFieldRemuneration = payload => dispatch =>
  dispatch({
    type: types.SET_FIELD_REMUNERATION,
    payload
  });

export function getRemunerationParameters(cycle, person, usuario) {
  return async function (dispatch) {
    const parameters = await remunerationApi.getRemunerationParameters(
      cycle,
      person,
      usuario
    );
    return dispatch({
      type: types.GET_REMUNERATION_PARAMETERS,
      payload: (parameters) ? parameters[0] : null
    });
  };
}

export function postSimulation(usuario, params, type) {
  return async function(dispatch) {
    return dispatch({
      type:
        type === 'current'
          ? types.POST_SIMULATION_CURRENT
          : types.POST_SIMULATION,
      payload: await remunerationApi.postSimulation(usuario, params)
    });
  };
}

export function cleanSimulationResult() {
  return async function(dispatch) {
    return dispatch({
      type: types.CLEAN_SIMULATION_RESULT,
      payload: undefined
    });
  };
}

export function cleanSimulationValues() {
  return async function(dispatch) {
    return dispatch({
      type: types.CLEAN_SIMULATION_VALUES,
      payload: 0
    });
  };
}
