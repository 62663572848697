import * as types from '../actions/actionTypes';

let initialState = {
  "allowModules": []
};

export default function authReducer(state = initialState, action) {
  if(localStorage.getItem('usuario')){
    state = JSON.parse(localStorage.getItem('usuario'));
  }
  switch(action.type) {
    case `${types.AUTHENTICATE_SUCCESS}_FULFILLED`:
      return action.payload;
    case `${types.DECODE_UUID}_FULFILLED`:
      return action.payload;
    case types.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
