import React from 'react';
import { PropTypes } from 'prop-types';
import { Paper, Divider } from 'material-ui';
import { FormattedMessage, intlShape } from 'react-intl';
import moment from 'moment';

const ConsultantDetails = (props) => {

  const formatDate = (date) => {
    if (date)
      return moment(date).format("DD/MM/YY HH:mm");
    else
      return '-';
  };

  const formatStatus = (status) => {
    const { intl } = props;
    if (status === 1)
      return intl.formatMessage({ id: 'status.loja.1' });
    else if (status === 2)
      return intl.formatMessage({ id: 'status.loja.2' });
    else if (status === 3)
      return intl.formatMessage({ id: 'status.loja.3' });
    else if (status === 4)
      return intl.formatMessage({ id: 'status.loja.4' });
    else if (status === 5)
      return intl.formatMessage({ id: 'status.loja.5' });
    else if (status === 6)
      return intl.formatMessage({ id: 'status.loja.6' });
    else if (status === 7)
      return intl.formatMessage({ id: 'status.loja.7' });
    else if (status === 8)
      return intl.formatMessage({ id: 'status.loja.8' });
    else if (status === 9)
      return intl.formatMessage({ id: 'status.loja.9' });
    else if (status === 10)
      return intl.formatMessage({ id: 'status.loja.10' });
  };


  const { consultant: { digitalStore } } = props;
  return (
    <div>
      <Paper className="consultant-report-paper">
        <h3 className="paper-header">
          <FormattedMessage
            id="consultantDetails.menu.espaco.digital"
            defaultMessage="Espaço Digital"
            description="Espaço Digital" />
        </h3>
        <Divider />
        <div className="consultant-report-body">
          <div className="row">
            <div className="col-md-4">
              <span className="text-bold">
                <FormattedMessage
                  id="consultantDetails.data_hora_ativacao"
                  defaultMessage="Data e Hora da Ativação: "
                  description="Data e Hora da Ativação: " />
              </span>
              <span className="report-page-base-paper-body-text-gray">{digitalStore && digitalStore.storeActiveAt ? formatDate(digitalStore.storeActiveAt) : '-'}</span>
            </div>
            <div className="col-md-4">
              <span className="text-bold">
                <FormattedMessage
                  id="consultantDetails.status_url"
                  defaultMessage="Status URL: "
                  description="Status URL: " />
              </span>
              <span className="report-page-base-paper-body-text-gray">{digitalStore && digitalStore.storeStatus ? formatStatus(digitalStore.storeStatus) : '-'}</span>
            </div>
            <div className="col-md-4">
              <span className="text-bold">
                <FormattedMessage
                  id="consultantDetails.nome_url"
                  defaultMessage="Nome URL: "
                  description="Nome URL: " />
              </span>
              <span className="report-page-base-paper-body-text-gray">{digitalStore && digitalStore.storeName ? digitalStore.storeName : '-'}</span>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Paper>
    </div>
  )
}

ConsultantDetails.propTypes = {
  intl: intlShape,
  consultant: PropTypes.object.isRequired,
};

export default ConsultantDetails

