import * as types from '../actions/actionTypes';
//import _ from 'lodash/fp'

const initialState = {
  data: [],  
};

function processPayload(payload) {
  const dataF = payload.data.filter(elem => elem.total > 0);
  return Object.assign({}, payload, {
    data: dataF  
  })
}

export default function elegibilityReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.LOAD_MEDAL}_FULFILLED`:
      return processPayload(payload);
    default:
      return state;
  }
}
