import * as types from '../actions/actionTypes';

let initialState = {
  "customNavigation": []
};

export default function externalAppsReducer(state = initialState, action) {
  if(localStorage.getItem('customNavigation')){
    state = { customNavigation: JSON.parse(localStorage.getItem('customNavigation')) };
  }
  switch(action.type) {
    case types.GET_EXTERNAL_NAVIGATION_DATA:
      return {...state, customNavigation: action.payload};
    default:
      return state;
  }
}
