import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import { RaisedButton } from 'material-ui';
import CircularProgress from 'material-ui/CircularProgress';

class IndicatorsTable extends Component {
  state = {
    fixedHeader: true,
    fixedFooter: false,
    stripedRows: false,
    showRowHover: true,
    selectable: false,
    multiSelectable: false,
    enableSelectAll: false,
    deselectOnClickaway: false,
    showCheckboxes: false
  };

  loadingData = data => {
    switch (data) {
      case null:
        return '-';
      case undefined:
        return <CircularProgress size={20} />;
      default:
        return data;
    }
  };

  checkEmptyData = data => {
    return (data) ? data : "-";
  }

  render() {
    const { data, type, toggleClass, indicatorsDetails, isLeader } = this.props;
    return (
      <Table
        height={this.state.height}
        fixedHeader={this.state.fixedHeader}
        fixedFooter={this.state.fixedFooter}
        selectable={this.state.selectable}
        multiSelectable={this.state.multiSelectable}
      >
        <TableHeader
          displaySelectAll={this.state.showCheckboxes}
          adjustForCheckbox={this.state.showCheckboxes}
          enableSelectAll={this.state.enableSelectAll}
        >
          <TableRow>
            <TableHeaderColumn>{type}</TableHeaderColumn>
            <TableHeaderColumn>Real</TableHeaderColumn>
            <TableHeaderColumn>{(isLeader) ? "Acelerador" : "Meta"}</TableHeaderColumn>
            <TableHeaderColumn>Atingimento</TableHeaderColumn>
            <TableHeaderColumn />
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={this.state.showCheckboxes}
          deselectOnClickaway={this.state.deselectOnClickaway}
          showRowHover={this.state.showRowHover}
          stripedRows={this.state.stripedRows}
        >
          {data &&
            data.map(
              (row, index) =>
                row.enable &&
                (row.dataToggle ? (
                  <TableRow
                    key={index}
                    className={row.class}
                    data-toggle={row.dataToggle}
                    id={row.id}
                    data-target={row.dataTarget}
                    onClick={toggleClass.bind(this, row.chevronName)}
                  >
                    <TableRowColumn>
                      <i
                        className={
                          row.chevron
                            ? 'glyphicon glyphicon-chevron-up'
                            : 'glyphicon glyphicon-chevron-down'
                        }
                      >
                        {'\u00A0'}
                      </i>
                      {row.indicador}
                    </TableRowColumn>
                    <TableRowColumn>{this.loadingData(row.real)}</TableRowColumn>
                    <TableRowColumn>{this.loadingData(row.meta)}</TableRowColumn>
                    <TableRowColumn>{this.loadingData(row.atingimento)}</TableRowColumn>
                    <TableRowColumn>
                      {row.onConsultants && (
                        <RaisedButton
                          backgroundColor="#7080A0"
                          labelColor="#FFF"
                          label="VER CONSULTORAS"
                          disabled={false}
                          onClick={() => indicatorsDetails(row.indicador)}
                        />
                      )}
                    </TableRowColumn>
                  </TableRow>
                ) : (
                    <TableRow key={index} className={row.class}>
                      <TableRowColumn style={{ paddingLeft: '50px' }}>
                        {this.checkEmptyData(row.indicador)}
                      </TableRowColumn>
                      <TableRowColumn>{this.checkEmptyData(row.real)}</TableRowColumn>
                      <TableRowColumn>{this.checkEmptyData(row.meta)}</TableRowColumn>
                      <TableRowColumn>{this.checkEmptyData(row.atingimento)}</TableRowColumn>
                      <TableRowColumn>
                        {row.onConsultants && (
                          <RaisedButton
                            backgroundColor="#7080A0"
                            labelColor="#FFF"
                            label="VER CONSULTORAS"
                            disabled={false}
                            onClick={() => indicatorsDetails(row.indicador)}
                          />
                        )}
                      </TableRowColumn>
                    </TableRow>
                  ))
            )}
        </TableBody>
      </Table>
    );
  }
}

IndicatorsTable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  toggleClass: PropTypes.any,
  indicatorsDetails: PropTypes.any,
  panelDetails: PropTypes.any,
  isLeader: PropTypes.bool
};

export default IndicatorsTable;
