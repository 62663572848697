import React from 'react';

const BackgroundLogin = () => {
  return (
      <div>
        <img className="groups-container-login-background" src={require('../../img/backgroundNatura.png')} alt="Background Natura"  />
        <div className="groups-container-login-background-img">
          <img className="header-logo-img" src={require('../../img/natura.png')} alt="Natura Logo"  />
        </div>
      </div>
  );
};

export default BackgroundLogin;
