/* eslint-disable eqeqeq */
import request from 'superagent';
import { structureLevels as structureLevel } from '../utils/structureLevels';

const SERVICE_REMUNERACAO_API = process.env.REACT_APP_SERVICE_REMUNERACAO_API;

class remunerationApi {
  static getRemunerationParameters = (cycle, person, usuario) => {
    const personStructure = (person.commercial_structure.nm_group) ? person.commercial_structure.nm_group : person.commercial_structure.nm_sector;
    const personStructureLevel = (person.commercial_structure.nm_group) ? structureLevel.GROUP : structureLevel.SECTOR;
    return new Promise((resolve, reject) => {
      request
        .get(`${SERVICE_REMUNERACAO_API}/external/parameters`)
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set(
          'client_id',
          usuario.isUserDefault
            ? process.env.REACT_APP_CLIENT_ID_DEFAULT
            : process.env.REACT_APP_CLIENT_ID
        )
        .set('country', person.id_country)
        .set('businessModel', person.id_commercial_model)
        .set('cycle', cycle)
        .set('structureCode', personStructure)
        .set('structureLevel', personStructureLevel)
        .then(res => {
          if (res.status == 204)
            reject([]);
          if (res.status >= 200 && res.status <= 207) {
            resolve(res.body);
          }
        })
        .catch(err => {
          reject(Object.assign({}, err.response));
        });
    });
  };

  static postSimulation = (usuario, params) => {
    return new Promise((resolve) => {
      request
        .post(`${SERVICE_REMUNERACAO_API}/external/simulation/calculation`)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set(
          'client_id',
          usuario.isUserDefault
            ? process.env.REACT_APP_CLIENT_ID_DEFAULT
            : process.env.REACT_APP_CLIENT_ID
        )
        .send(JSON.stringify(params))
        .then(res => {
          if(res.status == 204) resolve(null);
          if (res.status >= 200 && res.status <= 207) {
            resolve((res.body.grossValue) ? res.body.grossValue : null);
          }
        })
        .catch(() => {
          resolve(null);
        });
    });
  };
}

export default remunerationApi;
