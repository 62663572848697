import React from 'react';
import {PropTypes} from 'prop-types';
import {FormattedMessage} from 'react-intl';
import Divider from 'material-ui/Divider';

const CommercialStructure = (props) => {
  let { person } = props;

  return (
    <div>
      <h5 className="paper-header commercial-structure-paper-title">
        <FormattedMessage
          id="geral.estrutura.comercial"
          defaultMessage="Estrutura Comercial"
          description="Estrutura Comercial"
        />
      </h5>
      <Divider/>
      <div className="commercial-structure-paper-body-container">
        <div className="commercial-structure-paper-body-line"/>
        <div className="commercial-structure-paper-body">
          <div className="commercial-structure-paper-body-ball"/>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.gerencia.de.mercado"
                defaultMessage="Gerência de Mercado"
                description="Gerência de Mercado"
              />:
            </span>
            {person.commercial_structure && person.commercial_structure.no_market_management}
          </div>
        </div>
        <div className="commercial-structure-paper-body">
          <div className="commercial-structure-paper-body-ball"/>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.regiao.estrategica"
                defaultMessage="Região Estratégica"
                description="Região Estratégica"
              />:
            </span>
            {person.commercial_structure && person.commercial_structure.no_strategic_region}
          </div>
        </div>
        <div className="commercial-structure-paper-body">
          <div className="commercial-structure-paper-body-ball"/>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.gerencia.de.vendas"
                defaultMessage="Gerência de Vendas"
                description="Gerência de Vendas"
              />:
            </span>
            {person.commercial_structure && person.commercial_structure.no_sales_region}
          </div>
        </div>
        <div className="commercial-structure-paper-body">
          <div className="commercial-structure-paper-body-ball"/>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.setor"
                defaultMessage="Setor"
                description="Setor"
              />:
            </span>
            {person.commercial_structure && person.commercial_structure.no_sector}
          </div>
        </div>
        <div className="commercial-structure-paper-body">
          <div className="commercial-structure-paper-body-ball"/>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.grupo"
                defaultMessage="Grupo"
                description="Grupo"
              />:
            </span>
            {(person.commercial_structure && person.commercial_structure.no_group) ?
              person.commercial_structure && person.commercial_structure.no_group :
              "-"
            }
          </div>
        </div>
      </div>
    </div>
  );
};

CommercialStructure.propTypes = {
  person: PropTypes.object.isRequired
};

export default CommercialStructure;
