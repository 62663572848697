import * as types from './actionTypes';
import growthPlanApi from '../api/growthPlanApi';

export function getCycleRanges(currentCycle, user) {
  return function (dispatch) {
    return dispatch({ type: types.GROWTH_PLAN_CYCLE_RANGES, payload: growthPlanApi.getCycleRanges(currentCycle, user) });
  };
}

export function selectCycleRange(selectedCycleRange) {
  return function (dispatch) {
    return dispatch({ type: types.GROWTH_PLAN_SELECT_CYCLE_RANGE, payload: selectedCycleRange });
  };
}
