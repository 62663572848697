import request from 'superagent';

const serviceApi = process.env.REACT_APP_SERVICE_URL_API;

// function getPersonJSON(person) {
//   return {
//     person_id: person.id_consult,
//     country_id: person.id_country,
//     consultant_commercial_model_id: person.id_commercial_model,
//     role_id: person.dc_roles.map(role => role.nm_role)
//   };
// }

// function getCommercialStructureJSON(person) {
//   let structure = {
//     commercial_structure_country_id: person.id_country,
//     commercial_structure_market_management_id: person.commercial_structure.nm_market_management,
//     commercial_structure_strategic_region_id: person.commercial_structure.nm_strategic_region,
//     commercial_structure_sales_management_id: person.commercial_structure.nm_sales_region,
//     commercial_structure_sector_id: person.commercial_structure.nm_sector
//   };

//   if (person.commercial_structure.nm_group)
//     structure.commercial_structure_group_id = person.commercial_structure.nm_group;

//   return structure;
// }

class leaderKitApi {

  static getKitMaterialCycle(person, usuario) {
    return new Promise((resolve, reject) => {
      request.post(`${serviceApi}/sap-orders?cn_code=${person.id_consult}`)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));
          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign([], res.body));
        });
    });
  }

  static getStatusMaterialCycle(type, person, usuario) {
    return new Promise((resolve, reject) => {
      request.post(`${serviceApi}/sap-cn-info?action=${type}&cn_code=${person.id_consult}&user=${usuario.email}`)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));
          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(res.body.E_T_LOG.item);
        });
    });
  }

  static getDetailsMaterialCycle(person, nmRequestOrder, usuario) {
    return new Promise((resolve, reject) => {
      request.post(`${serviceApi}/sap-order-details?cn_code=${person}&nm_order=${nmRequestOrder}`)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('access_token', usuario.accessToken)
        .set('client_id', usuario.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID)
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));
          if (res.status === 204)
            resolve(Object.assign([]));
          else
            resolve(Object.assign([], res.body));
        });
    });
  }

}

export default leaderKitApi;
