import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { channel } from '../../../../../utils/channel';

class TableActive extends React.Component {
  channelFormatter = cell => {
    return channel[cell];
  };

  render() {
    const { type, indicatorsDetails, options } = this.props;

    return (
      <div style={{ marginTop: '40px' }}>
        {indicatorsDetails && indicatorsDetails.length > 0 && (
          <BootstrapTable
            tableContainerClass="indicators-table-container-class"
            headerContainerClass="indicators-table-header-container-class"
            bodyContainerClass="indicators-table-body-container-class"
            data={indicatorsDetails}
            options={options}
            pagination
            striped
            hover
            search
            exportCSV
            csvFileName={`Relatório de ${type}.csv`}
            searchPlaceholder="Buscar por código ou nome"
          >
            <TableHeaderColumn
              dataField="cnCode"
              width="10%"
              dataSort={true}
              csvHeader={'Código da Consultora'}
              isKey
            >
              <FormattedMessage
                id="indicators.panel.details.cn.code"
                defaultMessage="Código da Consultora"
                description="Código da Consultora"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="cnName"
              width="30%"
              dataSort={true}
              csvHeader={'Nome da Consultora'}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.name"
                defaultMessage="Nome da Consultora"
                description="Nome da Consultora"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              width="15%"
              dataField="activeBy"
              csvHeader={'Canal'}
              csvFormat={this.channelFormatter}
              dataFormat={this.channelFormatter}
              formatExtraData={channel}
              filter={{
                type: 'SelectFilter',
                options: channel,
                placeholder: 'Todos'
              }}
              dataSort={true}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.channel"
                defaultMessage="Canal"
                description="Canal"
              />
            </TableHeaderColumn>
          </BootstrapTable>
        )}
      </div>
    );
  }
}

TableActive.propTypes = {
  indicatorsDetails: PropTypes.array,
  type: PropTypes.string,
  options: PropTypes.object,
};

export default TableActive;
