import React from 'react';
//import { PropTypes } from 'prop-types';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: '',
      content: ''
    }
  }

  handleOpen = (options) => {
    if (options)
      this.setOptions(options, this.setState({open: true}));
    else
      this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  getTitle = () => {
    return <div className="extratos-titulo-lista-pedidos">{this.state.title}</div>;
  };

  getContent = () => {
    return this.state.content;
  };

  setTitle = (title) => {
    this.setState({ title: title });
  };

  setContent = (content) => {
    this.setState({ content: content });
  };

  setOptions = (options, callback) => {
    this.setState({
      title: options.title || '',
      content: options.content || ''
    }, callback || null);
  };

  render() {
    const actions = ([
      <FlatButton
        key="btnDialogOk"
        label="Ok"
        primary={true}
        onTouchTap={this.handleClose}
      />
    ]);

    return (
      <div>
        <Dialog
          title={this.getTitle()}
          actions={actions}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          {this.getContent()}
        </Dialog>
      </div>
    );
  }
}

export default Alert;
