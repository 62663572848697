import * as types from './actionTypes';
import WebFvFirebaseService from '../service/WebFvFirebaseService';

export function getExternalNavigationData() {
  return async function(dispatch) {
    const data = await WebFvFirebaseService.getAllRemoteConfigData();
    const arr = [];
      data.length > 0 && // eslint-disable-next-line
        data.map(item => {
          if(item.active)
            arr.push({
              label: item.menuName,
              icon: "",
              link: item.route.replace(/(.*)(\/.*)/, "$2"),
              roles: item.roles.map(item => normalizeString(item)),
              target: "_self",
              type: "external"
            });
        });

    localStorage.setItem('customNavigation', JSON.stringify(arr));
    return dispatch({
      type: types.GET_EXTERNAL_NAVIGATION_DATA,
      payload: arr
    });
  };
}

const normalizeString = (string) => {
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
}
