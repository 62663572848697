import * as types from './actionTypes';
import peopleApi from '../api/peopleApi';

export function clear() {
  return function (dispatch) {
    return dispatch({ type: types.PEOPLE_SEARCH_CLEAR });
  };
}

export function addField(payload) {
  return function (dispatch) {
    return dispatch({ type: types.PEOPLE_ADD_FIELD, payload });
  };
}

export function searchConsultant(payload, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.PEOPLE_SEARCH, payload: peopleApi.searchConsultant(payload, usuario) });
  };
}
