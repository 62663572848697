import React, { Component } from 'react';
import { Divider } from 'material-ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import ReportPageBase from '../common/ReportPageBase';
import LeaderFilter from "./widgets/LeaderFilter";
import VolumePointsTable from './widgets/Tables/VolumePointsTable';
import * as reportActions from '../../actions/reportActions';
import * as personActions from '../../actions/personActions';
import * as consultantActions from '../../actions/consultantActions';
import * as elegibilityActions from '../../actions/elegibilityActions';
// import { debug } from 'util';

class LeaderReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personCode: '',
      filterTracker: [],
      selectedPaymentFilter: 0
    }
  }

  componentDidMount() {
    let { usuario, personActions } = this.props;
    personActions.getPerson(this.props.params.code, usuario)
      .then(_ => {
        let result = _.value.dc_roles;
        this.props.consultantActions.getDigitalStoreDetails(usuario, _.value);
        if (result && result.some(_ => _.nm_role === 19)) {
          this.setState({ lider: true });
        } else {
          this.setState({ lider: false });
        }
      });
  }

  componentDidUpdate(prevProps) {
    let { person, reportActions } = this.props;
    if (prevProps.person.id_consult !== person.id_consult && person.id_consult !== '') {
      reportActions.setField({ path: 'personCode', value: person.id_consult });
      this.setState({ personCode: person.id_consult })
    }
  }

  componentWillUnmount() {
    let { reportActions } = this.props;
    reportActions.resetFields();
    reportActions.resetLists();
  }

  handleChangeTextField = (event) => {
    const value = event.target.value.replace(/\D/ig, '');
    this.props.reportActions.setField({ path: event.target.name, value: value });
  };

  handleKeyUp = (event) => {
    if (event.key === 'Enter')
      this.handleSearch();
  };

  handleSearch = async () => {
    let { usuario, report, reportActions, person } = this.props;
    reportActions.setField({ path: 'cycleChoosen', value: report.fields.cycle });
    if (!_.isEmpty(report.fields.cycle)) {
      let params = {
        'parentStructureLevel': 3,
        'cycle': report.fields.cycle,
        'structureLevel': 5,
        'leaderCode': person.id_consult,
        'country': parseInt(usuario.country),
        'businessModel': usuario.businessModel
      }

      await reportActions.getPayments(usuario, params).then((r) => {
        if (r.value && r.value.length > 0) {
          params = {...params,
            'paymentId': parseInt(r.value[0].id_pagamento),
          }
          reportActions.getExtract(usuario, params);
        }
    })
  }
}

handleReset = () => {
  let { reportActions } = this.props;
  reportActions.resetFields(['cycle', 'cycleChoosen', 'partner', 'period',]);
  reportActions.resetLists(['extract']);
}

handleNewService = () => {
  this.props.router.push('/');
};

selectedTracking = (event, index, item) => {
  let { usuario, report, reportActions, person } = this.props;
  this.props.reportActions.setValueFilter(item);
  let selectedPayment = report.filters.filterTracking.filter(obj => obj.indexPayment === item)[0];
  const params = {
    'parentStructureLevel': 3,
    'cycle': report.fields.cycle,
    'structureLevel': 5,
    'leaderCode': person.id_consult,
    'country': usuario.country,
    'businessModel': usuario.businessModel,
    'paymentId': selectedPayment.paymentId,
  }
  reportActions.getExtract(usuario, params);
}
handlers = {
  changeTextField: this.handleChangeTextField,
  keyUp: this.handleKeyUp,
  search: this.handleSearch,
  selectedTracking: this.selectedTracking,
  reset: this.handleReset
}

render() {
  let { person, report, consultant = {}, intl } = this.props;
  const digitalStore = consultant.digitalStore || {}
  return (
    <div>
      <ReportPageBase person={person} onNewService={this.handleNewService}
        commercialStatus={digitalStore.commercialStatus}
        intl={intl}
      >
        <div>
          <h4 className="paper-header">
            <FormattedMessage
              id="leader.extrato.valores"
              defaultMessage="Extrato de líderes"
              description="Extrato de líderes"
            />
          </h4>
          <Divider />
          <div className="leader-report-container">
            <LeaderFilter person={this.state.personCode} report={report} handlers={this.handlers}></LeaderFilter>
          </div>
        </div>
      </ReportPageBase>

      {!_.isEmpty(report.lists.extract) ?
        <div>
          <VolumePointsTable tableData={report.lists.extract} />
        </div>
        : ''
      }
    </div>
  );
}
}

LeaderReport.propTypes = {
  intl: intlShape,
  params: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  reportActions: PropTypes.object.isRequired,
  elegibilityActions: PropTypes.object,
  consultantActions: PropTypes.object,
  consultant: PropTypes.object,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    report: state.report,
    person: state.person,
    consultant: state.consultant,
    elegibility: state.elegibility,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
    elegibilityActions: bindActionCreators(elegibilityActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LeaderReport));
