/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import Divider from 'material-ui/Divider';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

class PaymentTracker extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  handleClass = (index, params) => {
    const { stepIndex } = this.props;
    if (stepIndex != null && stepIndex != undefined && stepIndex >= index) return params += ' active'
    else return params
  }

  render() {
    const { data } = this.props;
    const values = (!_.isEmpty(data)) ? Object.assign({}, ...data) : {};
    const errors = values.errors;
    return (
      <div className="payment-tracker">
        <div className="content-filter">
          <div className="display-header">
            <div className="payment-tracker-horizontal green">
              <div className={this.handleClass(0, 'payment-tracker-step calendar')}>
                <div className="payment-tracker-step-circle"><span>1</span></div>
                <div className="payment-tracker-step-title">
                  <FormattedMessage
                    id="rastreio.pagamento.header.valor"
                    defaultMessage="Valor Ciclo Calculado"
                    description="Valor Ciclo Calculado"
                  />
                </div>
                <div className="payment-tracker-step-bar-left"></div>
                <div className="payment-tracker-step-bar-right"></div>
              </div>
              <div className={this.handleClass(1, 'payment-tracker-step invoice')}>
                <div className="payment-tracker-step-circle"><span>2</span></div>
                <div className="payment-tracker-step-title">
                  <FormattedMessage
                    id="rastreio.pagamento.header.nota_fiscal"
                    defaultMessage="Conferindo a NF/Recibo"
                    description="Conferindo a NF/Recibo"
                  />
                </div>
                <div className="payment-tracker-step-bar-left"></div>
                <div className="payment-tracker-step-bar-right"></div>
              </div>
              <div className={this.handleClass(2, 'payment-tracker-step description')}>
                <div className="payment-tracker-step-circle"><span>3</span></div>
                <div className="payment-tracker-step-title">
                  <FormattedMessage
                    id="rastreio.pagamento.header.processo_conferencia"
                    defaultMessage="Processo de Conferência"
                    description="Processo de Conferência"
                  />
                </div>
                <div className="payment-tracker-step-bar-left"></div>
                <div className="payment-tracker-step-bar-right"></div>
              </div>
              <div className={this.handleClass(3, 'payment-tracker-step money')}>
                <div className="payment-tracker-step-circle"><span>4</span></div>
                <div className="payment-tracker-step-title">
                  <FormattedMessage
                    id="rastreio.pagamento.header.pagamento_efetuado"
                    defaultMessage="Pagamento Efetuado"
                    description="Pagamento Efetuado"
                  />
                </div>
                <div className="payment-tracker-step-bar-left"></div>
                <div className="payment-tracker-step-bar-right"></div>
              </div>
            </div>
          </div>
          {!_.isEmpty(data) ?
            <div>
              <div className="display-data">
                <div id="step1" className="col-xs-6 col-sm-3">
                  <ul className="list" style={{ marginBottom: "10px" }}>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor"
                          defaultMessage={`Valor: `}
                          description="Valor: "
                        /></b> R$ {values.field1}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.pedido"
                          defaultMessage={`Pedido: `}
                          description="Pedido: "
                        /></b> {values.field2}

                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.folha_serviço"
                          defaultMessage={`Folha de Serviço: `}
                          description="Folha de Serviço: "
                        />
                      </b> {values.field3}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.ciclo_competencia"
                          defaultMessage={`Ciclo/Competência: `}
                          description="Ciclo/Competência: "
                        />
                      </b> {values.field4}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.cnpj_tomador"
                          defaultMessage={`CNPJ do Tomador:`}
                          description="CNPJ do Tomador: "
                        /></b> {values.field5}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.cadastro_cpom"
                          defaultMessage={`Cadastro do CPOM: `}
                          description="Cadastro do CPOM: "
                        />
                      </b>{values.field7}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.cadastro_fdc"
                          defaultMessage={`Cadastro FDC: `}
                          description="Cadastro FDC: "
                        />
                      </b>{values.field8}
                    </li>
                  </ul>
                </div>
                <div id="step2" className="col-xs-6 col-sm-3">
                  <ul className="list">
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.nf_recebida"
                          defaultMessage={`NF/Recibo Recebida: `}
                          description="NNF/Recibo Recebida: "
                        /></b> {values.field9}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor_nf"
                          defaultMessage={`Valor NF/Recibo: `}
                          description="Valor NF/Recibo: "
                        />
                      </b>{values.field10}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor_multa"
                          defaultMessage={`Valor Multa/Juros: `}
                          description="Valor Multa/Juros: "
                        />
                      </b>{values.field11}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor_inss"
                          defaultMessage={`Valor INSS: `}
                          description="Valor INSS: "
                        />
                      </b>{values.field12}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor_iss"
                          defaultMessage={`Valor ISS: `}
                          description="Valor ISS: "
                        />
                      </b>{values.field219}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor_ir"
                          defaultMessage={`Valor IR: `}
                          description="Valor IR: "
                        /></b> {values.field13}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor_adiantamento"
                          defaultMessage={`Valor Adiantamento: `}
                          description="Valor Adiantamento: "
                        />
                      </b>{values.field15}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.dt_ag_adiantamento"
                          defaultMessage={`Data do Agendamento do Adiantamento: `}
                          description="Data do Agendamento do Adiantamento: "
                        />
                      </b>{values.field216}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.dt_pg_adiantamento"
                          defaultMessage={`Data do Pagamento do Adiantamento: `}
                          description="Data do Pagamento do Adiantamento: "
                        />
                      </b>{values.field217}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.status_adiantamento"
                          defaultMessage={`Status do Pagamento do Adiantamento: `}
                          description="Status do Pagamento do Adiantamento: "
                        />
                      </b>{values.field218}
                    </li>
                  </ul>
                </div>
                <div id="step3" className="col-xs-6 col-sm-3">
                  <ul className="list">
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.numero_nf"
                          defaultMessage={`Número da NF: `}
                          description="Número da NF: "
                        />
                      </b>{values.field220}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.data_agendamento"
                          defaultMessage={`Data de Agendamento de Pagamento: `}
                          description="Data de Agendamento de Pagamento: "
                        />
                      </b>{values.field16}
                    </li>
                  </ul>
                </div>
                <div id="step4" className="col-xs-6 col-sm-3">
                  <ul className="list">
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.data_pagamento"
                          defaultMessage={`Data de Pagamento: `}
                          description="Data de Pagamento: "
                        />
                      </b>{values.field17}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.dados_conta"
                          defaultMessage={`Banco/Ag/CC: `}
                          description="Banco/Ag/CC: "
                        />
                      </b>{values.field18}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.valor_residual_pg"
                          defaultMessage={`Valor Pago Residual: `}
                          description="Valor Pago Residual: "
                        />
                      </b>{values.field419}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.data_agendamento_residual"
                          defaultMessage={`Data de Agendamento do Pagamento Residual: `}
                          description="Data de Agendamento do Pagamento Residual: "
                        />
                      </b>{values.field421}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.data_residual"
                          defaultMessage={`Data do Pagamento Residual: `}
                          description="Data do Pagamento Residual: "
                        />
                      </b>{values.field422}
                    </li>
                    <li>
                      <b>
                        <FormattedMessage
                          id="rastreio.pagamento.data.status_residual"
                          defaultMessage={`Status do Pagamento Residual: `}
                          description="Status do Pagamento Residual: "
                        />
                      </b>{values.field420}
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-12 display-errors">
                </div>
              </div>
              {!_.isEmpty(errors) ?
                <div className="col-xs-12 col-sm-12 display-errors">
                  <h4 className="paper-header-errors">
                    <FormattedMessage
                      id="rastreio.pagamento.erro"
                      defaultMessage="Erros"
                      description="Erros"
                    />
                  </h4>
                  <Divider />
                  <div className="leader-report-container-errors">
                    <ul>
                      {errors.map(item =>
                        <li key={item.descr}><b>{item.descr}</b></li>
                      )}
                    </ul>
                  </div>
                </div>
                : ''}
            </div>
            : ''}

        </div>
      </div>
    );
  }
}

PaymentTracker.propTypes = {
  stepIndex: PropTypes.number,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
};

export default PaymentTracker;
