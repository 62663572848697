import React from 'react';
import { PropTypes } from 'prop-types';

const BeautyConsultant = (props) => {
  return (
    <div className="growth-plan-report-container">{/*  // eslint-disable-next-line */}
      <h4 className="growth-plan-report-title">Nível atual: {(props.person.thermometer && props.person.thermometer.currentLevelName) || ''}</h4>
      <div>
        <table className="growth-plan-report-table-container">
          <thead>
          <tr>
            <th>&nbsp;</th>
            {props.levels.length > 0 && props.levels.map(level => <th key={level.levelId}>{level.levelName}</th>)}
          </tr>
          </thead>
          <tbody className="growth-plan-report-table-hover">
          <tr>
            <td className="growth-plan-report-table-background">Requisito</td>
            {props.levels.length > 0 && props.levels.map(level =>
              <td key={`start_${level.levelSequence}`}>{props.startingPointsFormatter(level, 'levelPointsRangeStart')}</td>
            )}
          </tr>
          <tr>
            <td className="growth-plan-report-table-background">Restante</td>
            {props.levels.length > 0 && props.levels.map(level =>
              <td key={`remaining_${level.levelSequence}`}>{props.remainingPointsFormatter(level, 'levelPointsRemaining')}</td>
            )}
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

BeautyConsultant.propTypes = {
  person: PropTypes.object.isRequired,
  levels: PropTypes.array.isRequired,
  startingPointsFormatter: PropTypes.func.isRequired,
  remainingPointsFormatter: PropTypes.func.isRequired
};

export default BeautyConsultant;
