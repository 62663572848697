import * as types from '../actions/actionTypes';
import 'core-js/es7/array';

const initialState = {
  sucessDetail: {},
};

function processPayload(payload) { // eslint-disable-next-line
  const dataF = payload.data.filter(elem => elem.tipo != `ranking`);
  var data_cumprimento_missao = '';
  // eslint-disable-next-line array-callback-return
  const mapMissao = dataF.map(a => {
    data_cumprimento_missao = a.data_cumprimento_missao;
    // eslint-disable-next-line default-case
    switch (a.tipo) {

      case `medalha`:
        return a.medalhas.map(medalha => {
          return {
            data_cumprimento_missao: data_cumprimento_missao,
            titulo: medalha.titulo_missao,
            acao: medalha.acao_missao,
            descricao:medalha.descricao,
            passos:medalha.total,
            medalha:medalha.nome,
          }
        });
        case `missao`:
        return a.missoes.map(missao => {
          return {
            data_cumprimento_missao:data_cumprimento_missao,
            titulo: missao.titulo,
            acao:missao.acao,
            descricao:missao.descricao,
          }
        });
      }
});


return  {data:mapMissao.reduce((acc, val) => acc.concat(val), [])}
}

export default function sucessDetailReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.LOAD_MY_SUCESS_DETAIL}_FULFILLED`:
      return processPayload(payload);
    default:
      return state;
  }
}
