// eslint-disable-next-line
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {PropTypes} from "prop-types";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import {FormattedMessage} from 'react-intl';
class Medal extends React.Component { // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {
    let {medal} = this.props;
    const formatIcon = (icone) => {
      return (
        <img src={icone}  width='30%' height='30%' />
      )
    }

    return (
      <div className="elegibility-report-container">
        <div className="elegibility-report-container">
          <h4 className="elegibility-report-title">
            <FormattedMessage
              id="medal.historico.medalha"
              defaultMessage="Historico de Medalha"
              description="Historico de Medalha"
            />
          </h4>
          <div>
            <BootstrapTable
              containerClass="elegibility-report-page-table-container"
              headerContainerClass="elegibility-report-page-table-header"
              bodyContainerClass="elegibility-report-page-table-body"
              bordered={false}
              data={medal.data}
            >
              <TableHeaderColumn dataField="order" hidden={true} isKey={true}/>
              <TableHeaderColumn width="10%" dataField="url_icone" dataFormat={formatIcon}>
                <FormattedMessage
                  id="medal.medalha"
                  defaultMessage="Medalha"
                  description="Medalha"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="nome" >
                <FormattedMessage
                  id="medal.nome"
                  defaultMessage="Nome"
                  description="Nome"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="10%" dataField="categoria_titulo">
                <FormattedMessage
                  id="medal.categoria"
                  defaultMessage="Categoria"
                  description="Categoria"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="30%" dataField="descricao">
                <FormattedMessage
                  id="medal.descricao"
                  defaultMessage="Descricao"
                  description="Descricao"
                />
              </TableHeaderColumn>
              <TableHeaderColumn width="5%" dataField="total">
                <FormattedMessage
                  id="medal.quantidade"
                  defaultMessage="Quantidade"
                  description="Quantidade"
                />
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}

Medal.propTypes = {
 medal: PropTypes.object.isRequired,
};

export default Medal;
