import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import moment from 'moment';

class LeaderKitStatusHistoricDialog extends React.Component {
    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    getTitle = (title) => {
        return <div className="extratos-titulo-lista-pedidos">{title}</div>;
    };

    dateFormatter = (list) => {
        moment.locale('pt-br');
        return list.map(item => {
            return {...item, ZZDATAHORA_ULT_MOD: moment(item.ZZDATA_ULT_MOD).format('L') + " " + moment(item.ZZHORA_ULT_MOD.replace(':', ''), "hmm").format("HH:mm") }
        })
    };

    statusFormatter = (row) => {
        const { props: { intl } } = this.props;
        if (row === 'ATIVADO') {
            return intl.formatMessage({ id: 'table.column.ativo' });
        } else if (row === 'DESATIVADO') {
            return intl.formatMessage({ id: 'table.column.inativo' });
        }
    };


    render() {
        const { label, title, props } = this.props;

        let options = {
            noDataText: props.intl.formatMessage({ id: 'table.empty.data' })
        };

        const actions = ([
            <FlatButton
                key={1}
                label={
                    <FormattedMessage
                        id="botao.ok"
                        defaultMessage="OK"
                        description="Botão OK"
                    />
                }
                primary={true}
                keyboardFocused={true}
                onClick={this.handleClose}
            />
        ]);

        return (
            <div>
                <FlatButton label={label} onClick={this.handleOpen} className="flat-button-weight" />
                <Dialog
                    title={this.getTitle(title)}
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onClick={this.handleClose}
                >

                    <div>
                        {props.leaderKit.statusMaterialCycleHistoric &&
                          props.leaderKit.statusMaterialCycleHistoric.length > 0 &&
                            <BootstrapTable
                                containerClass="product-kit-report-page-table-container"
                                headerContainerClass="product-kit-report-page-table-header"
                                bodyContainerClass="product-kit-report-page-table-body"
                                data={this.dateFormatter(props.leaderKit.statusMaterialCycleHistoric)}
                                options={options}
                            >
                                <TableHeaderColumn width="25%" dataField="ZZCAMPO_MOD"
                                    isKey={true}
                                    dataFormat={this.statusFormatter}
                                >
                                    <FormattedMessage
                                        id="table.column.status"
                                        defaultMessage="Status"
                                        description="Coluna Status"
                                    />
                                </TableHeaderColumn>
                                <TableHeaderColumn width="25%" dataField="ZZDATAHORA_ULT_MOD">
                                    <FormattedMessage
                                        id="table.column.desde"
                                        defaultMessage="Desde"
                                        description="Coluna Desde"
                                    />
                                </TableHeaderColumn>
                                <TableHeaderColumn width="50%" dataField="ZZRESPONSAVEL_ALTERACAO">
                                    <FormattedMessage
                                        id="table.column.alterado.por"
                                        defaultMessage="Alterado Por"
                                        description="Coluna Alterado Por"
                                    />
                                </TableHeaderColumn>
                            </BootstrapTable>
                        }
                    </div>
                </Dialog>
            </div>
        );
    }
}

LeaderKitStatusHistoricDialog.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    label: PropTypes.any,
    props: PropTypes.any
};

export default LeaderKitStatusHistoricDialog;
