import * as types from '../actions/actionTypes';
//import _ from 'lodash/fp'

const initialState = {
  data: [],
 
};

function processPayload(payload) {
  return Object.assign({}, payload, {
    data: payload.data,    
  })
}

export default function sucessReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case `${types.LOAD_MY_SUCESS}_FULFILLED`:
      return processPayload(payload);
    default:
      return state;
  }
}
