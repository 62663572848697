import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { Tabs, Tab } from 'material-ui/Tabs';

class LeaderKitDetailDialog extends React.Component {
    state = {
        open: false,
        content: '',
        value: 1,
    };

    handleChange = (value) => {
        this.setState({
            value: value,
        });
    };

    handleOpen = () => {
        const { props } = this.props;
        props.actions.changeFlag({ modalOpen: true, order: this.props.row.numeroPedido });
        this.setState({ open: true });
    };

    handleClose = () => {
        const { props } = this.props;
        props.actions.resetFields(['modalOpen', 'order', 'detailsMaterialCycle'])
        this.setState({ open: false, value: 1 });
    };

    availableFormatter = (row) => {
        const { props } = this.props;
        if (row === 'X') {
            return <div style={{ color: 'green' }}>{props.intl.formatMessage({ id: 'table.column.disponivel' })}</div>;
        } else {
            return <div style={{ color: 'red' }}>{props.intl.formatMessage({ id: 'table.column.indisponivel' })}</div>;
        }
    };

    getTitle = (title) => {
        return <div className="extratos-titulo-lista-pedidos">{title}</div>;
    };

    render() {
        const { row, props } = this.props;
        const listDetailsMaterialCycle = props.leaderKit.detailsMaterialCycle;
        const listItem = props.leaderKit.detailsMaterialCycle.itens;

        const actions = ([
            <FlatButton
                label={
                    <FormattedMessage
                        id="botao.ok"
                        defaultMessage="OK"
                        description="Botão OK"
                    />
                }
                primary={true}
                keyboardFocused={true}
                onClick={this.handleClose}
                key={1}
            />
        ]);

        let options = {
            noDataText: props.intl.formatMessage({ id: 'table.empty.data' })
        };

        return (
            <div>
                <FlatButton label={row.numeroPedido} onClick={this.handleOpen} className="flat-button-weight" />
                <Dialog
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onClick={this.handleClose}
                    autoScrollBodyContent={true}
                >
                    <Tabs
                        initialSelectedIndex={1}
                        value={this.state.value}
                        onChange={this.handleChange}
                    >
                        <Tab label={props.intl.formatMessage({ id: 'title.dialog.leader.kit.detail' })} value={1}>
                            <ul className="list-leader-kit-details">
                                <li key={1}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.numero.pedido' })}:`}</label>
                                    {listDetailsMaterialCycle.numeroPedido || '-'}
                                </li>
                                <li key={2}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.descricao.kit' })}:`}</label>
                                    {listDetailsMaterialCycle.descricaoKIT || '-'}
                                </li>
                                <li key={3}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.ciclo.material' })}:`}</label>
                                    {listDetailsMaterialCycle.cicloReferente || '-'}
                                </li>
                                <li key={4}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.ciclo' })}:`}</label>
                                    {listDetailsMaterialCycle.cicloFaturado || '-'}
                                </li>
                                <li key={5}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.data.pedido' })}:`}</label>
                                    {listDetailsMaterialCycle.dataPedido || '-'}
                                </li>
                                <li key={6}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.situacao' })}:`}</label>
                                    {listDetailsMaterialCycle.cancelado ?
                                        props.intl.formatMessage({ id: 'table.column.cancelado' })
                                        : listDetailsMaterialCycle.descSubStatusPedido || '-'}
                                </li>
                                <li key={7}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.numero.nfe' })}:`}</label>
                                    {listDetailsMaterialCycle.numeroNFe || '-'}
                                </li>
                                <li key={8}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.data.faturamento' })}:`}</label>
                                    {listDetailsMaterialCycle.dataFaturamento || '-'}
                                </li>
                                <li key={9}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.endereco.entrega' })}:`}</label>
                                    {listDetailsMaterialCycle.enderecoEntrega || '-'}
                                </li>
                                <li key={10}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.previsao.entrega' })}:`}</label>
                                    {listDetailsMaterialCycle.previsaoEntrega || '-'}
                                </li>
                                <li key={11}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.nova.previsao' })}:`}</label>
                                    {listDetailsMaterialCycle.novaPrevisao || '-'}
                                </li>
                                {listDetailsMaterialCycle.novaPrevisaoProrrogada ?
                                    <li key={12}>
                                        <label>{`${props.intl.formatMessage({ id: 'label.dialog.nova.previsao.prorrogada' })}:`}</label>
                                        {listDetailsMaterialCycle.novaPrevisaoProrrogada || '-'}
                                    </li> : ''}
                                <li key={13}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.data.primeira.tentativa' })}:`}</label>
                                    {listDetailsMaterialCycle.dataPrimeiraTentativa || '-'}
                                </li>
                                <li key={14}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.data.segunda.tentativa' })}:`}</label>
                                    {listDetailsMaterialCycle.dataSegundaTentativa || '-'}
                                </li>
                                <li key={15}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.data.entrega' })}:`}</label>
                                    {listDetailsMaterialCycle.dataEntrega || '-'}
                                </li>
                                <li key={16}>
                                    <label>{`${props.intl.formatMessage({ id: 'label.dialog.transportadora' })}:`}</label>
                                    {listDetailsMaterialCycle.transportadora || '-'}
                                </li>
                            </ul>
                        </Tab>
                        <Tab label={props.intl.formatMessage({ id: 'title.dialog.leader.kit.detail.pedido' })} value={2}>
                            <BootstrapTable
                                containerClass="leader-kit-report-page-table-container"
                                headerContainerClass="product-kit-report-page-table-header"
                                bodyContainerClass="product-kit-report-page-table-body"
                                bodyStyle={{ overflow: 'overlay', height: '500px' }}
                                data={listItem}
                                options={options}
                            >
                                <TableHeaderColumn width="50%" dataField="descMaterial" isKey={true}>
                                    <FormattedMessage
                                        id="table.column.produtos"
                                        defaultMessage="Produtos"
                                        description="Coluna Produtos"
                                    />
                                </TableHeaderColumn>
                                <TableHeaderColumn width="20%" dataField="quantidade">
                                    <FormattedMessage
                                        id="table.column.quantidades"
                                        defaultMessage="Quantidades"
                                        description="Coluna Quantidades"
                                    />
                                </TableHeaderColumn>
                                <TableHeaderColumn width="20%" dataField="disponivel" dataFormat={this.availableFormatter}>
                                    <FormattedMessage
                                        id="table.column.disponibilidade"
                                        defaultMessage="Disponibilidade"
                                        description="Coluna Disponibilidade"
                                    />
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Tab>
                    </Tabs>
                </Dialog>
            </div>
        );
    }
}

LeaderKitDetailDialog.propTypes = {
    props: PropTypes.any,
    row: PropTypes.any
};

export default LeaderKitDetailDialog;