import * as types from './actionTypes';
import leaderKitApi from '../api/leaderKitApi';

export function changeFlag(payload) {
  return function (dispatch) {
    return dispatch({ type: types.CHANGE_FLAG, payload});
  };
}

export function getKitMaterialCycle(person, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_KIT_MATERIAL_CYCLE, payload: leaderKitApi.getKitMaterialCycle(person, usuario) });
  };
}

export function getStatusMaterialCycle(type, person, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_STATUS_MATERIAL_CYCLE, payload: leaderKitApi.getStatusMaterialCycle(type, person, usuario) });
  };
}

export function getDetailsMaterialCycle(nmRequestOrder, person, usuario) {
  return function (dispatch) {
    return dispatch({ type: types.LOAD_DETAILS_MATERIAL_CYCLE, payload: leaderKitApi.getDetailsMaterialCycle(nmRequestOrder, person, usuario) });
  };
}

export function resetFields(fields) {
  return function (dispatch) {
    return dispatch({ type: types.RESET_FIELDS || null, payload: fields });
  };
}