// eslint-disable no-mixed-operators
/* eslint-disable eqeqeq */
import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, FormattedDate, FormattedNumber, intlShape } from 'react-intl';
import Divider from 'material-ui/Divider';

const getFormattedDate = (person) => {
  return person.dt_birth
    ? <FormattedDate value={person.dt_birth} timeZone='utc'/>
    : '';
};

const InfoBox = (props) => {
  let { person, commercialStatus } = props;
  const validCommercialStatus = typeof commercialStatus === "number" && commercialStatus >= -1
    && commercialStatus <= 7
  return (
    <div>
      <h3 className="paper-header">{person && person.no_name}</h3>
      <Divider />
      <div className="report-page-base-paper-body">
        <div className="report-page-base-paper-body-custom">
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.codigo"
                defaultMessage="Código: "
                description="Código: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{person && person.id_consult}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage id="geral.cpf" defaultMessage="CPF" description="CPF" />:
            </span>
            <span className="report-page-base-paper-body-text-gray">{person && person.nm_document}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.nascimento"
                defaultMessage="Data de Nascimento: "
                description="Data de Nascimento: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{getFormattedDate(person)}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.tempo.casa"
                defaultMessage="Tempo de Casa: "
                description="Tempo de Casa: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{person && person.qt_ciclos
              ? `${person.qt_ciclos} Ciclos`
              : 'Não Disponivel'}</span>
          </div>
          <div className="report-page-base-papers">
            <span className="text-bold">
              <FormattedMessage
                id="geral.papeis"
                defaultMessage="Papéis: "
                description="Papéis: " />
            </span>
            <div className="report-page-base-paper-custom"> {/* eslint-disable-next-line no-mixed-operators */}
              {person && person.dc_roles && (person.dc_roles.length > 0 ) && person
                .dc_roles
                .map(role => <span key={role.nm_role}>
                  <span className="report-page-base-paper-body-text-gray">{`${role.no_role} - ${role.cn_begin_cycle ||
                    role.cda_begin_cycle ||
                    role.cne_begin_cycle ||
                    role.cno_begin_cycle ||
                    role.cnd_begin_cycle ||
                    '0'
                    }`}</span>
                </span>) /* eslint-disable-next-line no-mixed-operators */
                ||
                <FormattedMessage
                  id="geral.papeis.sem.papel"
                  defaultMessage="Sem papel"
                  description="Mensagem de consultora sem papel atribuído"
                />
              }
            </div>
          </div>
        </div>
        <div className="report-page-base-paper-body-padding">
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.modelo.comercial"
                defaultMessage="Modelo Comercial: "
                description="Modelo Comercial: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{person && person.no_commercial_model}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.situacao.financeira"
                defaultMessage="Situação Financeira: "
                description="Situação Financeira: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{person && person.financial_pending
              ? `${person.financial_pending}`
              : 'Não Disponivel'}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.esteve.inadimplente"
                defaultMessage="Já esteve inadimplente (6 meses): "
                description="Já esteve inadimplente (6 meses): " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{person && (person.pastSixMonth == 0)
              ? `Não`
              : 'Sim' || (!person.pastSixMonth)
                ? ''
                : ''}
            </span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.lucratividade"
                defaultMessage="Lucratividade: "
                description="Lucratividade: " />
            </span>
            <span className="report-page-base-paper-body-text-gray">{person && person.pc_profitability
              ? `${person.pc_profitability}%`
              : 'Não Disponivel'}</span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.ticket.medio"
                defaultMessage="Ticket Médio: "
                description="Ticket Médio: " />
            </span>
            <span className="report-page-base-paper-body-text-gray"><FormattedNumber
              value={person.medium_ticket || 0} // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="BRL" /></span>
          </div>
          <div>
            <span className="text-bold">
              <FormattedMessage
                id="geral.status.consultora"
                defaultMessage="Status: "
                description="Status" />
            </span>
            <span
              id="geral.status.consultora.value"
              className="report-page-base-paper-body-text-gray">
              {validCommercialStatus
                && props.intl
                && props.intl
                && props.intl.formatMessage ?
                props.intl.formatMessage({ id: `status.commercial.situation.${commercialStatus}` })
                : '-'
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  intl: intlShape,
  person: PropTypes.object.isRequired,
  commercialStatus: PropTypes.number,
  type: PropTypes.string,
};

export default InfoBox;
