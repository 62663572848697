import * as React from 'react';
import loadPageIntoElement from './loadPageIntoElement';

export const withAppLoader = ({
  elementId,
  appUrl,
  loadPage = loadPageIntoElement,
  LoadingComponent
}) => {
  return class AppLoader extends React.Component {
    componentDidMount() {
      this.loadApp();
    }

    componentDidCatch = async (error, info) => {
      // eslint-disable-next-line
      console.log('catch error:', error);
      // eslint-disable-next-line
      console.log('catch info:', info);
    };

    loadApp = async () => {
      try {
        await loadPage(elementId, appUrl);
      } catch (e) {
        throw new Error(e);
      }
    };

    render() {
      return (
        <div id={elementId}>
          {LoadingComponent ? <LoadingComponent /> : null}
        </div>
      );
    }
  };
};
