import * as types from './actionTypes';
import reportApi from '../api/reportApi';

export function setField(payload) {
  return function (dispatch) {
    return dispatch({ type: types.SET_FIELD, payload });
  };
}


export function setValueFilter(payload) {
  return function (dispatch) {
    return dispatch({ type: types.SET_SELECTED_FILTER, payload });
  };
}

export function getTracking(usuario, params) {
  return function (dispatch) {
    return dispatch({ type: types.GET_TRACKING, payload: reportApi.getTracking(usuario, params) });
  };
}

export function getExtract(usuario, params) {
  return function (dispatch) {
    return dispatch({ type: types.GET_EXTRACT, payload: reportApi.getExtract(usuario, params) });
  };
}

// export function clearAll() {
//   return function (dispatch) {
//     return dispatch({ type: types.CLEAR_ALL });
//   };
// }

export function resetFields(fields) {
  return function (dispatch) {
    return dispatch({ type: types.RESET_FIELDS || null, payload: fields });
  };
}

export function resetLists(listName) {
  return function (dispatch) {
    return dispatch({ type: types.RESET_LISTS || null, payload: listName });
  };
}

export function getPayments(usuario, params) {
  return function (dispatch) {
    return dispatch({ type: types.GET_PAYMENTS, payload: reportApi.getPayments(usuario, params) });
  }
}