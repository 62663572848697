import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash/fp';
import { FormattedMessage } from 'react-intl';
import { TextField, RaisedButton, SelectField, MenuItem } from 'material-ui';

const LeaderFilter = (props) => {
  const { person, report, handlers } = props;
  return (
    <div className="leader-report-extract">
      <div className="col-xs-6 col-sm-3">
        <TextField
          floatingLabelText={
            <FormattedMessage
              id="leader.extrato.atualizado"
              defaultMessage="Código da Pessoa: "
              description="Código da Pessoa: "
            />}
          floatingLabelFixed
          floatingLabelStyle={{ color: '#777777' }}
          value={person}
          underlineShow={false}
          disabled
        />
        <TextField
          hintText={
            <FormattedMessage
              id="leader.extrato.ciclo_placeholder"
              defaultMessage="Digite um ciclo"
              description="Digite um ciclo"
            />}
          floatingLabelText={
            <FormattedMessage
              id="leader.extrato.ciclo"
              defaultMessage="Ciclo: "
              description="Ciclo: "
            />}
          floatingLabelFixed
          floatingLabelStyle={{ color: '#777777' }}
          underlineFocusStyle={{ color: '#777777' }}
          name="cycle"
          value={report.fields.cycle}
          onChange={handlers.changeTextField}
          onKeyUp={handlers.keyUp}
          maxLength="6"
        />
        <div>
          <RaisedButton
            className="button-leader-filter"
            backgroundColor="#7080a0"
            labelColor="#fff"
            label={
              <FormattedMessage
                id="consultora.consulta.pagamento"
                defaultMessage="Consulta de Pagamento"
                description="Consulta de Pagamento"
              />
            }
            onClick={handlers.search}
          />
        </div>
      </div>
      <div className="col-md-4" style={{ paddingTop: "72px" }}>
        <SelectField
          floatingLabelText="Data de Processamento"
          disabled={_.isEmpty(report.filters.filterTracking)}
          floatingLabelStyle={{ color: '#777777' }}
          onChange={handlers.selectedTracking}
          value={report.filters.selectedFilterPayment}
          name="filterTracking"
        >
          {
            report.filters.filterTracking.map((obj) =>
              <MenuItem key={obj.indexPayment} value={obj.indexPayment} primaryText={`R$ ${obj.valuePayment}  ${obj.datePayment ? " - " + obj.datePayment : ""}`} />
            )
          }

        </SelectField>
      </div>
      <div className="col-xs-4 col-sm-2">
        <TextField
          floatingLabelText={
            <FormattedMessage
              id="leader.ciclo.escolhido"
              defaultMessage="Ciclo: "
              description="Ciclo: "
            />}
          floatingLabelFixed
          floatingLabelStyle={{ color: '#777777' }}
          value={report.fields.cycleChoosen || "-"}
          underlineShow={false}
          disabled
        />
        <TextField
          floatingLabelText={
            <FormattedMessage
              id="leader.periodo_solicitado"
              defaultMessage="Período: "
              description="Período: "
            />}
          floatingLabelFixed
          floatingLabelStyle={{ color: '#777777' }}
          value={report.selectedPayment && report.selectedPayment.length > 0 && report.selectedPayment[0].per_ciclo_ini && report.selectedPayment[0].per_ciclo_fim ? report.selectedPayment[0].per_ciclo_ini.substr(6, 8) + "/" + report.selectedPayment[0].per_ciclo_ini.substr(4, 2) + "/" + report.selectedPayment[0].per_ciclo_ini.substr(0, 4) + " - " + report.selectedPayment[0].per_ciclo_fim.substr(6, 8) + "/" + report.selectedPayment[0].per_ciclo_fim.substr(4, 2) + "/" + report.selectedPayment[0].per_ciclo_fim.substr(0, 4) : "-"}
          underlineShow={false}
          disabled
        />
      </div>
      <div className="col-xs-5 col-sm-3">
        <TextField
          floatingLabelText={
            <FormattedMessage
              id="leader.aderente_parceiro"
              defaultMessage="Aderente ao Parceiro: "
              description="Aderente ao Parceiro: "
            />}
          floatingLabelFixed
          floatingLabelStyle={{ color: '#777777' }}
          value={report.selectedPayment && report.selectedPayment.length > 0 ? report.selectedPayment[0].dicas_mei : "-"}
          underlineShow={false}
          disabled
        />
        <TextField
          floatingLabelText={
            <FormattedMessage
              id="leader.tipo_pessoa"
              defaultMessage="PF ou PJ: "
              description="PF ou PJ: "
            />}
          floatingLabelFixed
          floatingLabelStyle={{ color: '#777777' }}
          value={report.selectedPayment && report.selectedPayment.length > 0 ? report.selectedPayment[0].tipo_pessoa : "-"}
          underlineShow={false}
          disabled
        />
        <RaisedButton
          className="button-leader-filter"
          label={
            <FormattedMessage
              id="botao.limpar_consulta"
              defaultMessage="Limpar Consulta"
              description="Limpar Consulta"
            />
          }
          onClick={handlers.reset}
        />
      </div>
      <div className="row">

        <div className="col-md-4 col-md-offset-4 text-right">

        </div>
      </div>
    </div >
  );
};

LeaderFilter.propTypes = {
  person: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  report: PropTypes.object.isRequired,
  handlers: PropTypes.object.isRequired,
}

export default LeaderFilter
