import firebaseApp from 'firebase/app';
import 'firebase/remote-config';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_COMMERCIAL_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_COMMERCIAL_FIREBASE_DOMAIN}.firebaseapp.com'`,
  databaseURL: `https://${process.env.REACT_APP_COMMERCIAL_FIREBASE_DOMAIN}.firebaseio.com'`,
  projectId: `${process.env.REACT_APP_COMMERCIAL_FIREBASE_DOMAIN}`,
  storageBucket: `${process.env.REACT_APP_COMMERCIAL_FIREBASE_DOMAIN}.appspot.com'`,
  messagingSenderId: process.env.REACT_APP_COMMERCIAL_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_COMMERCIAL_FIREBASE_APP_ID
};

export class CommercialFirebaseService {
  constructor(dependencies) {
    if (!CommercialFirebaseService.instance) {
      this.initService(dependencies);

      CommercialFirebaseService.instance = this;
    }
    return CommercialFirebaseService.instance;
  }

  initService(
    dependencies = { firebase: firebaseApp, config: firebaseConfig }
  ) {
    const { config, firebase } = dependencies;

    let commercialFirebase = null;
    if(!firebase.app.length){
      commercialFirebase = firebase.initializeApp(config);
    }
    else{
      commercialFirebase = firebase.initializeApp(config, "commercial");
    }
    // Initialize, fetch and activate Remote Config data
    this.remoteConfig = commercialFirebase.remoteConfig();
    //TODO: Remover após testar (reduzindo cache de 12 horas do remoteconfig)
    this.remoteConfig.settings = {
      minimumFetchIntervalMillis: 3600,
    };
  }

  async getAllRemoteConfigData() {
    const activate = await this.remoteConfig.fetchAndActivate();
    const data = await this.remoteConfig.getAll(activate);
    const dataString = Object.values(data).map(value =>
      JSON.parse(value._value)
    );
    return dataString;
  }
}

export default new CommercialFirebaseService();
