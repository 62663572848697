import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate } from 'react-intl';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PageBase from '../common/PageBase';


import * as peopleActions from '../../actions/peopleActions';
import * as externalAppsActions from '../../actions/externalAppsActions';

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 0,
      searched: false
    };
  }

  componentDidMount() {
    this.props.peopleActions.clear();
  }

  getSearchInfo = () => {
    if (!this.state.searched)
      return (
        <FormattedMessage
          id="consultora.buscar.iniciar"
          defaultMessage="Digite o nome de uma consultora para encontrá-lo."
          description="Digite o nome de uma consultora para encontrá-lo."
        />
      );
    else if (this.props.people.searchResults && this.props.people.searchResults.length === 0)
      return (
        <div className="homepage-messenge-busca">
          <FormattedMessage
            id="consultora.buscar.sem.resultados.encontrados"
            defaultMessage="Não encontramos um consultora com esses dados."
            description="Não encontramos um consultora com esses dados."
          />
          <br />
          <FormattedMessage
            id="consultora.buscar.sem.resultados.verifique"
            defaultMessage="Verifique se os dados inseridos estão corretos e tente buscar novamente."
            description="Verifique se os dados inseridos estão corretos e tente buscar novamente."
          />
        </div>
      );
  };

  handleChangeTextField = (event) => {
    this.props.peopleActions.addField({
      path: event.target.name,
      value: event.target.value
    });
  };


  handleSearch = () => {
    this.setState({ searched: true });
    let { usuario } = this.props;
    this.props.peopleActions.searchConsultant(this.props.people.fields, usuario);
  };

  handleRowClick = (row) => {
    this.props.router.push(`/extratos/plano-de-crescimento/${row.person_code}`);
  };

  handleKeyUp = (event) => {
    if (event.key === 'Enter')
      this.handleSearch();
  };

  dateFormatter = (cell) => {
    return cell ? <FormattedDate value={cell}  timeZone='utc' /> : "N/A";
  };

  render() {
    let { people } = this.props;
    let { currPage } = this.state;

    let tableOtions = {
      sizePerPageList: [5, 10, 15, 20],
      sizePerPage: 5,
      page: currPage,
      noDataText: (
        <FormattedMessage
          id="table.empty"
          defaultMessage="Não há registros encontrados"
          description="Não há registros encontrados"
        />
      ),
      prePage: "Anterior",
      nextPage: "Próxima",
      firstPage: "Primeira",
      lastPage: "Última",
      onRowClick: this.handleRowClick
    };

    return (
      <PageBase
        className="pagebase-title-homepage"
        title="Busca de Consultora"
      >
        <div>
          <div className="homepage-content">
            <div>
              <TextField
                className="homepage-textfield"
                floatingLabelText={
                  <FormattedMessage
                    id="consultora.codigo"
                    defaultMessage="Código da Consultora"
                    description="Código da Consultora"
                  />
                }
                name="consult_id"
                value={people.fields.consult_id}
                onChange={this.handleChangeTextField}
                onKeyUp={this.handleKeyUp}
                autoFocus
              />
            </div>
            <div>
              <TextField
                className="homepage-textfield"
                floatingLabelText={
                  <FormattedMessage
                    id="consultora.cpf"
                    defaultMessage="CPF da Consultora"
                    description="CPF da Consultora"
                  />
                }
                name="consult_documentation"
                value={people.fields.consult_documentation}
                onChange={this.handleChangeTextField}
                onKeyUp={this.handleKeyUp}
              />
            </div>
            <div>
              <TextField
                className="homepage-textfield"
                floatingLabelText={
                  <FormattedMessage
                    id="consultora.nome"
                    defaultMessage="Nome da Consultora"
                    description="Nome da Consultora"
                  />
                }
                name="consult_name"
                value={people.fields.consult_name}
                onChange={this.handleChangeTextField}
                onKeyUp={this.handleKeyUp}
                style={{ display: 'none' }}
              />
            </div>
            <div>
              <RaisedButton
                backgroundColor="#7080a0"
                labelColor="#fff"
                label={
                  <FormattedMessage
                    id="consultora.buscar"
                    defaultMessage="Buscar Consultora"
                    description="Buscar Consultora"
                  />
                }
                onTouchTap={this.handleSearch}
              />
            </div>
          </div>
          <div className="homepage-table-container"> {/* eslint-disable-next-line no-mixed-operators */}
            {people.searchResults && people.searchResults.length > 0 &&
              <BootstrapTable
                tableContainerClass="homepage-table-container-class"
                headerContainerClass="homepage-table-header-container-class"
                bodyContainerClass="homepage-table-body-container-class"
                data={people.searchResults}
                striped
                hover
                search
                pagination
                options={tableOtions}
                searchPlaceholder="Buscar nos resultados"
              >
                <TableHeaderColumn
                  dataField='person_code'
                  isKey
                  width="25%"
                >
                  <FormattedMessage
                    id="consultora.codigo"
                    defaultMessage="Código da Consultora"
                    description="Código da Consultora"
                  />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='complete_name'
                  width="25%"
                >
                  <FormattedMessage
                    id="consultora.nome"
                    defaultMessage="Nome da Consultora"
                    description="Nome da Consultora"
                  />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='cpf_number'
                  width="25%"
                >
                  <FormattedMessage
                    id="consultora.cpf"
                    defaultMessage="CPF da Consultora"
                    description="CPF da Consultora"
                  />
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='birthday'
                  dataFormat={this.dateFormatter}
                  width="25%"
                >
                  <FormattedMessage
                    id="consultora.nascimento"
                    defaultMessage="Data de nascimento"
                    description="Data de Nascimento"
                  />
                </TableHeaderColumn>
              </BootstrapTable> // eslint-disable-next-line no-mixed-operators
              ||
              <div className="homepage-messenger-container">
                <div>
                  {this.getSearchInfo()}
                </div>
              </div>
            }
          </div>
        </div>
      </PageBase>
    );
  }
}

HomePage.propTypes = {
  usuario: PropTypes.object.isRequired,
  people: PropTypes.object.isRequired,
  peopleActions: PropTypes.object.isRequired,
  externalAppsActions: PropTypes.object.isRequired,
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    people: state.people,
    usuario: state.usuario
  };
}

function mapDispatchToProps(dispatch) {
  return {
    peopleActions: bindActionCreators(peopleActions, dispatch),
    externalAppsActions: bindActionCreators(externalAppsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
