import * as types from './actionTypes';
import indicatorsApi from '../api/indicatorsApi';

export function getToken() {
  return async function (dispatch) {
    const token = await indicatorsApi.getToken();
    return dispatch({
      type: types.GET_TOKEN,
      payload: token.access_token
    });
  };
}

export function resetIndicators() {
  return async function (dispatch) {
    return dispatch({
      type: types.RESET_INDICATORS,
      payload: []
    });
  };
}

export function getIndicators(indicators, person) {
  return async function (dispatch, getState) {
    const token = getState().indicators.token;
    const api = await indicatorsApi.getIndicators(indicators, person, token);
    const payload = await api.reduce((acc, cur) => {
      acc[cur.indexCode] = {
        achieved: cur.achieved,
        goal: cur.goal,
        indexCode: cur.indexCode,
        indexDescription: cur.indexDescription,
        indexName: cur.indexName,
        indexValue: cur.indexValue
      };
      return acc;
    }, {});
    return dispatch({
      type: types.GET_INDICATORS,
      payload
    });
  };
}

export const setField = payload => dispatch =>
  dispatch({
    type: types.SET_FIELD,
    payload
  });

export function getIndicatorsDetails(indicators, person, type) {
  return async function (dispatch, getState) {
    const token = getState().indicators.token;
    const data = await indicatorsApi.getIndicatorsDetails(
      indicators,
      person,
      type,
      token
    );
    return dispatch({
      type: types.GET_INDICATORS_DETAILS,
      payload: (!Array.isArray(data) && type === 'Ativas Total') ? data.ActiveResult : data
    });
  };
}

export function cleanIndicatorsDetails() {
  return async function (dispatch) {
    return dispatch({
      type: types.CLEAN_INDICATORS_DETAILS,
      payload: []
    });
  };
}
