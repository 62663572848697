import * as types from '../actions/actionTypes';

const initialState = {
  selectedCycleRange: null
};

const getCycleRanges = (payload) => {
  // let cyclesByYear = [];
  //
  // payload.map(item => {
  //   let operationalCycle = item.nm_ciclo_operacional;
  //   let year = operationalCycle.toString().substr(0, 4);
  //   let cycle = operationalCycle.toString().substr(4, 2);
  //   if (!cyclesByYear[year]) cyclesByYear[year] = [];
  //   cyclesByYear[year].push(cycle);
  // });
  //
  // let cycles = cyclesByYear.map(year => {
  //   let chunk = _.chunk(10, year);
  //   return chunk.map(c => {return {start: c.shift(), end: c.pop()}});
  // });

  return payload;
};

export default function growthPlanReducer(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case types.GROWTH_PLAN_SELECT_CYCLE_RANGE:
      return {...state, selectedCycleRange: payload};
    case `${types.GROWTH_PLAN_CYCLE_RANGES}_PENDING`:
      return {...state, cycleRanges: []};
    case `${types.GROWTH_PLAN_CYCLE_RANGES}_FULFILLED`:
      return {...state, cycleRanges: getCycleRanges(payload)};
    case `${types.GET_DIGITAL_STORE_DETAILS}_FULFILLED`:
      return { ...state, digitalStore: payload };
    default:
      return state;
  }
}
