import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ConsultantDetails from './widgets/ConsultantDetails'
import ReportPageBase from '../common/ReportPageBase';
import * as personActions from '../../actions/personActions';
import * as consultantActions from '../../actions/consultantActions';
import { injectIntl, intlShape } from 'react-intl';

export class ConsultantReport extends Component {

  componentDidMount() {
    let { usuario } = this.props;
    this.props.personActions.getPerson(this.props.params.code, usuario).then((result) => {
        this.props.consultantActions.getDigitalStoreDetails(usuario, result.value);
    })
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  render() {
    let { person, consultant, consultant: { digitalStore }, intl } = this.props;
    return (
      <div>
        <ReportPageBase
          person={person}
          commercialStatus={digitalStore.commercialStatus}
          onNewService={this.handleNewService}
          type={'consultant'}
          intl={intl}
        />
        <ConsultantDetails
          consultant={consultant}
          intl={intl}
        />
      </div>
    )
  }
}

ConsultantReport.propTypes = {
  intl: intlShape,
  params: PropTypes.object,
  person: PropTypes.object.isRequired,
  consultant: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  consultantActions: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    person: state.person,
    consultant: state.consultant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    personActions: bindActionCreators(personActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConsultantReport));
