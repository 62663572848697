import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { digitalSpaceFilter, digitalSpaceStatus } from '../../../../../utils/digitalSpaceStatus';

class TableChannel extends React.Component {
  digitalSpaceStatusFormatter = cell => {
    return digitalSpaceStatus[cell];
  };

  createCustomToolBar = props => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ width: '50%', marginLeft: '15px', height: '100%' }}>
          {props.components.searchPanel}
        </div>
        <div style={{ marginRight: '15px', height: '100%' }}>
          {props.components.exportCSVBtn}
        </div>
      </div>
    );
  };

  render() {
    const { type, indicatorsDetails } = this.props;

    const options = {
      toolBar: this.createCustomToolBar,
      exportCSVText: 'Exportar para Excel',
      noAutoBOM: false,
      noDataText: (
        <FormattedMessage
          id="table.empty"
          defaultMessage="Não há registros encontrados"
          description="Não há registros encontrados"
        />
      )
    };
    return (
      <div style={{ marginTop: '40px' }}>
        {indicatorsDetails && indicatorsDetails.length > 0 && (
          <BootstrapTable
            tableContainerClass="indicators-table-container-class"
            headerContainerClass="indicators-table-header-container-class"
            bodyContainerClass="indicators-table-body-container-class"
            data={indicatorsDetails}
            pagination
            striped
            hover
            search
            exportCSV
            csvFileName={`Relatório de ${type}.csv`}
            options={options}
            searchPlaceholder="Buscar por código ou nome"
          >
            <TableHeaderColumn
              dataField="cnCode"
              width="10%"
              dataSort={true}
              csvHeader={'Código da Consultora'}
              isKey
            >
              <FormattedMessage
                id="indicators.panel.details.cn.code"
                defaultMessage="Código da Consultora"
                description="Código da Consultora"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="cnName"
              width="30%"
              dataSort={true}
              csvHeader={'Nome da Consultora'}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.name"
                defaultMessage="Nome da Consultora"
                description="Nome da Consultora"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="networkPoints"
              width="15%"
              dataSort={true}
              csvHeader={'Pontos Rede Natura'}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.points.network"
                defaultMessage="Pontos Rede Natura"
                description="Pontos Rede Natura"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="networkValue"
              width="15%"
              dataSort={true}
              csvHeader={'Valor Rede Natura'}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.points.value"
                defaultMessage="Valor Rede Natura"
                description="Valor Rede Natura"
              />
            </TableHeaderColumn>
            <TableHeaderColumn
              width="15%"
              dataField="digitalSpaceStatus"
              csvHeader={'Espaço Digital'}
              csvFormat={this.digitalSpaceStatusFormatter}
              dataFormat={this.digitalSpaceStatusFormatter}
              formatExtraData={digitalSpaceStatus}
              filter={{
                type: 'SelectFilter',
                options: digitalSpaceFilter,
                placeholder: 'Todos'
              }}
              dataSort={true}
            >
              <FormattedMessage
                id="indicators.panel.details.cn.digital.space.status"
                defaultMessage="Espaço Digital"
                description="Espaço Digital"
              />
            </TableHeaderColumn>
          </BootstrapTable>
        )}
      </div>
    );
  }
}

TableChannel.propTypes = {
  indicatorsDetails: PropTypes.array,
  type: PropTypes.string
};

export default TableChannel;
