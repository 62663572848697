import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const PaymentHistory = (props) => {
  return (
    <div className="growth-plan-report-container">
      <h3 className="growth-plan-report-title-custom paper-header">
        <FormattedMessage
          id="extratos.rede.natura.historico.mensalidades"
          defaultMessage="Histórico de Mensalidades"
          description="Histórico de Mensalidades"
        />
      </h3>
      <div>
        <table className="growth-plan-report-table-container">
          <thead>
          <tr>
            <th>
              <FormattedMessage
                id="geral.ano.mes"
                defaultMessage="Ano / Mês"
                description="Ano / Mês"
              />
            </th>
            <th>
              <FormattedMessage
                id="geral.valor.bruto"
                defaultMessage="Valor Bruto"
                description="Valor Bruto"
              />
            </th>
            <th>
              <FormattedMessage
                id="geral.desconto.atribuido"
                defaultMessage="Desconto Atribuído"
                description="Desconto Atribuído"
              />
            </th>
            <th>
              <FormattedMessage
                id="geral.valor.final"
                defaultMessage="Valor Final"
                description="Valor Final"
              />
            </th>
          </tr>
          </thead>
          <tbody className="growth-plan-report-table-hover">
            {props.person.monthlyPayment && props.person.monthlyPayment.sd_payments_history &&
              props.person.monthlyPayment.sd_payments_history.map(payment =>
                <tr key={Math.random()}>
                  <td>{`${payment.nm_year} / ${payment.nm_month < 10 ? `0${payment.nm_month}` : payment.nm_month}`}</td>
                  <td>{props.currencyFormatter(payment.nm_gross_value)}</td>
                  <td>{props.numberFormatter(payment.nm_discount)}%</td>
                  <td>{props.currencyFormatter(payment.nm_final_value)}</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

PaymentHistory.propTypes = {
  person: PropTypes.object.isRequired,
  numberFormatter: PropTypes.func.isRequired,
  currencyFormatter: PropTypes.func.isRequired
};

export default PaymentHistory;
