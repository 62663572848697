import request  from 'superagent';

const customerServiceApi = process.env.REACT_APP_SERVICE_URL_API;

class growthPlanApi {
  static getCycleRanges = (currentCycle, user) => {
    return new Promise((resolve, reject) => {
      // request.get(`${customerServiceApi}/cycle-ranges`)
      request.get(`${customerServiceApi}/accumulationPeriod`)
        .set({
          'Accept': 'application/json',
          'access_token': user.accessToken,
          'client_id': user.isUserDefault ? process.env.REACT_APP_CLIENT_ID_DEFAULT : process.env.REACT_APP_CLIENT_ID
        })
        .query({
          'currentCycle': currentCycle
        })
        .end((err, res) => {
          if (err)
            reject(Object.assign({}, err.response));

          resolve(Object.assign([], res.body.data.reverse()));
        });
    });
  };
}

export default growthPlanApi;
