import React from 'react';
import { PropTypes } from 'prop-types';
import { Divider, Paper, RaisedButton } from 'material-ui';
import globalStyles from '../../styles';

const CardBase = props => {
  const { title, btnLabel, btnClose } = props;

  return (
    <Paper style={globalStyles.paper}>
      {title && (
        <section>
          <h3
            className="product-kit-report-page-title paper-header"
            style={globalStyles.cardBase}
          >
            <span>{title}</span>
            {btnLabel && btnClose && (
              <RaisedButton
                backgroundColor="#7080A0"
                labelColor="#FFFFFF"
                label={btnLabel}
                onClick={() => btnClose()}
              />
            )}
          </h3>
        </section>
      )}
      <Divider />
      {props.children}
      <div style={globalStyles.clear} />
    </Paper>
  );
};

CardBase.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  btnClose: PropTypes.any,
  btnLabel: PropTypes.any,
  children: PropTypes.element
};

export default CardBase;
