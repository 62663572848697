import * as types from './actionTypes';
import bonusApi from '../api/bonusApi';

export function resetIndicators() {
  return async function (dispatch) {
    return dispatch({
      type: types.RESET_INDICATORS,
      payload: []
    });
  };
}

export function getBonusAchievement(indicators, person, usuario) {
  return async function (dispatch) {
    const bonusAchievement = await bonusApi.getBonusAchievement(
      indicators,
      person,
      usuario
    );
    return dispatch({
      type: types.GET_BONUS_ACHIEVEMENT,
      payload: (bonusAchievement && bonusAchievement.length > 0) ? bonusAchievement[0] : null
    });
  };
}

export const setField = payload => dispatch =>
  dispatch({
    type: types.SET_FIELD,
    payload
  });
