import React from 'react';
import { PropTypes } from 'prop-types';
import { Dialog, RaisedButton } from 'material-ui';
import RemunerationTable from './Tables/RemunerationTable';
import CircularProgress from 'material-ui/CircularProgress';

const styles = {
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    margin: '20px'
  },
  currentValue: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  customContentStyle: {
    width: '80%',
    maxWidth: 'none'
  }
};

class RemunerationPanel extends React.Component {
  state = {
    openModal: false
  };

  loadingData = data => {
    switch (data) {
      case null:
        return 'R$ -';
      case undefined:
        return <CircularProgress size={20}/>;
      default:
        return `R$ ${parseFloat(data.toFixed(2))}`;
    }
  };

  handleModal = async () => {
    const { openModal } = this.state;
    const { handlers } = this.props;
    handlers.cleanSimulationValues();
    this.setState({
      openModal: !openModal
    });
  };

  render() {
    const {
      handlers,
      simulationCurrentValue,
      simulationValue,
      tableDataRemuneration
    } = this.props;
    const { openModal } = this.state;

    return (
      <div>
        <div style={styles.grid}>
          <div style={styles.item}>
            <span style={styles.currentValue}>
              {this.loadingData(simulationCurrentValue)}
            </span>
          </div>
          <div>
            <RaisedButton
              backgroundColor="#7080A0"
              labelColor="#FFFFFF"
              label=" Simular Remuneração"
              onClick={this.handleModal}
            />
          </div>
        </div>
        <Dialog
          // title={'Simulador'}
          modal={false}
          open={openModal}
          contentStyle={styles.customContentStyle}
          onRequestClose={this.handleModal}
        >
          <RemunerationTable
            handlers={handlers}
            simulationValue={simulationValue}
            tableDataRemuneration={tableDataRemuneration}
          />
        </Dialog>
      </div>
    );
  }
}

RemunerationPanel.propTypes = {
  handlers: PropTypes.any,
  simulationCurrentValue: PropTypes.any,
  simulationValue: PropTypes.any,
  tableDataRemuneration: PropTypes.any
};

export default RemunerationPanel;
