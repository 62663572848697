import React from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {FormattedMessage, injectIntl, intlShape } from 'react-intl';
import FontIcon from 'material-ui/FontIcon';
import {green500, red500} from 'material-ui/styles/colors';
import ReportPageBase from '../common/ReportPageBase';
import ElegibilityCriteria from '../reports/widgets/ElegibilityCriteria';
import ElegibilityTraining from '../reports/widgets/ElegibilityTraining';
import * as growthPlanActions from '../../actions/growthPlanActions';
import * as personActions from '../../actions/personActions';
import * as elegibilityActions from '../../actions/elegibilityActions';
import * as consultantActions from '../../actions/consultantActions';

class ElegibilityReport extends React.Component {
  constructor(props) {
    super(props);

    this.state =  {
      lider: true
    };

    if (!this.props.params.code || !parseInt(this.props.params.code))
    this.props.router.push('/');
  }

  componentDidMount() {
    let {usuario} = this.props;
    this.props.personActions.getPerson(this.props.params.code, usuario)
      .then(_ => {
        let result = _.value.dc_roles;
        this.props.consultantActions.getDigitalStoreDetails(usuario, _.value);
        if (result && result.some(_ => _.nm_role === 19)) {
          this.setState({lider: true});
        } else {
          this.setState({lider: false});
          // this.props.elegibilityActions.getElegibility(this.props.params.code, usuario);
        }
      });
  }

  handleNewService = () => {
    this.props.router.push('/');
  };

  statusFormatter = (cell) => {
    // eslint-disable-next-line default-case
    switch (cell) {
      case true:
        return (
          <FontIcon className="pe-7s-check" color={green500}/>
        );
      case false:
        return (
          <FontIcon className="pe-7s-close-circle" color={red500}/>
        );
    }
  };

  render() {
    let { person, elegibility, consultant = {}, intl } = this.props;
    let { lider } = this.state;
    const digitalStore = consultant.digitalStore || {}
    return (
      <div>
        <ReportPageBase
          person={person}
          onNewService={this.handleNewService}
          commercialStatus={digitalStore.commercialStatus}
          intl={intl}
        >
          { lider ?
            <div className="homepage-messenger-container">
              <div>
                <FormattedMessage
                  id="elegibility.consultora.lider"
                  defaultMessage="A consultora já é líder."
                  description="A consultora já é líder."
                />
              </div>
            </div>
              :
            <div>
              <ElegibilityCriteria
                criteria={elegibility.criteria}
                statusFormatter={this.statusFormatter}
                {...this.props}
              />
              <div className="elegibility-plan-report-tr"/>
              <ElegibilityTraining
                training={elegibility.training}
                statusFormatter={this.statusFormatter}
                {...this.props}
              />
            </div>
          }

        </ReportPageBase>
      </div>
    );
  }
}

ElegibilityReport.propTypes = {
  intl: intlShape,
  elegibility: PropTypes.object.isRequired,
  elegibilityActions: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  personActions: PropTypes.object.isRequired,
  usuario: PropTypes.object.isRequired,
  growthPlan: PropTypes.object.isRequired,
  growthPlanActions: PropTypes.object.isRequired,
  params: PropTypes.object,
  consultant: PropTypes.object,
  consultantActions: PropTypes.object.isRequired,
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    usuario: state.usuario,
    growthPlan: state.growthPlan,
    person: state.person,
    elegibility: state.elegibility,
    consultant: state.consultant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    growthPlanActions: bindActionCreators(growthPlanActions, dispatch),
    personActions: bindActionCreators(personActions, dispatch),
    elegibilityActions: bindActionCreators(elegibilityActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ElegibilityReport));
